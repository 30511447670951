export const getUser=()=>{
    let user_data =getUserData();
    if(user_data === null){
        return null;
    }
    return  user_data.user;
}

export const getUserData=()=>{
    let user_data =JSON.parse(sessionStorage.getItem("user_data"));
    if(user_data === null){
        return null;
    }
    return  user_data;
}