import axios from "axios";
import { allCompaniesURL, addCompanyURL, deleteCompanyURL, subscriptionTypesURL, updateCompanyURL,
   getCompanyURL,subscriptionValidationURL,blockCompanyURL } from "../constants/ApiEndPoints";

export const getAllCompanies = async (payload) => {
  // console.log('service: ', payload);
  try {
    const response = await axios.post(`${allCompaniesURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getSubscriptionTypes = async (payload) => {
  // console.log('service: ', payload);
  try {
    const response = await axios.get(`${subscriptionTypesURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const subscriptionValidationUpload = async (payload) => {
  try {
    const response = await axios.post(`${subscriptionValidationURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}
export const addCompany = async (payload) => {
  try {
    const response = await axios.post(`${addCompanyURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const deleteCompany = async (payload) => {
  try {
    const response = await axios.delete(`${deleteCompanyURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getCompany = async (payload) => {
  try {
    const response = await axios.post(`${getCompanyURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}
export const blockCompany = async (payload) => {
  try {
    const response = await axios.post(`${blockCompanyURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updateCompany = async (payload) => {
  try {
    const response = await axios.put(`${updateCompanyURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

