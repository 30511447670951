import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ApexCharts, { ApexOptions } from 'apexcharts'
import Form from "react-bootstrap/Form";

import { generateOrderId, getOrderChart } from "../../services/OrderServices";
import { getUser } from "../../shared/UserUtil";
import { UserMode, UserType } from "../../shared/Constants";
import { toastError } from "../../shared/Toast";

const Dashboard = () => {
    const currentUser = getUser();
    const chartRef = useRef(null);
    const chartPriceRef = useRef(null);
    const [chartData, setChartData] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [customDateRange, setCustomDateRange] = useState(false);
    const [noData, setNoData] = useState(false);

    const fetchChartData = () => {

        let payload = { vendor_id: currentUser.user_id, duration: 30, company_id: currentUser.company_id };
        if (currentUser.user_type === UserType.Admin)
            payload = { duration: 30, company_id: currentUser.company_id, start_date: startDate, end_date: endDate };

        generateChart(payload);
        //     return () => {
        //         if (chart) {
        //             chart.destroy()
        //         }
        //     }
        // return () => {
        //     if (chartPrice) {
        //         chartPrice.destroy()
        //     }
        // }

    }

    const generateChart = async (payload) => {
        let chartDataResponse = await getOrderChart(payload);
        console.log(chartDataResponse);
        setChartData(chartDataResponse.data);
        setNoData(chartDataResponse.data.length === 0);
        if (chartDataResponse.data) {
            const chartPrice = refreshPriceChart(chartDataResponse.data);
            const chart = refreshChart(chartDataResponse.data)
        }
    }

    useEffect(() => {
        if (currentUser.user_type === UserType.Admin || currentUser.mode === UserMode.Seller) {
            fetchChartData();
        }
    }, [chartPriceRef]);

    const refreshChart = (data) => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, data))
        if (chart) {
            chart.render();
        }
        return chart
    }


    const refreshPriceChart = (data) => {
        if (!chartPriceRef.current) {
            return
        }
        const height = parseInt(getCSS(chartPriceRef.current, 'height'));
        const chart = new ApexCharts(chartPriceRef.current, getPriceChartOptions(height, data));
        if (chart) {
            chart.render();
        }
        return chart;
    }

    function getPriceChartOptions(height, data) {
        const labelColor = getCSSVariableValue('--kt-gray-500')
        const borderColor = getCSSVariableValue('--kt-gray-200')
        const baseColor = getCSSVariableValue('--kt-primary')
        const secondaryColor = getCSSVariableValue('--kt-gray-300')
        var orders = [];
        var prices = [];
        var i = 1;
        if (data) {
            data.forEach((element, index) => {
                for (var j = i; j < element.month; j++) {
                    orders.push(0);
                    prices.push(0);
                }
                if (i === element.month) {
                    orders.push(element.total_orders);
                    prices.push(element.total_price);
                }
                else
                    orders.push(0);
                i++;
            });
            if (data.length < 12) {
                for (var j = data.length; j < 12; j++) {
                    orders.push(0);
                    prices.push(0);
                }
            }

        }
        console.log(orders)
        return {
            series: [
                // {
                //     name: 'Orders Count',
                //     data: orders,
                // },
                {
                    name: 'Total Price',
                    data: prices,
                },
            ],
            chart: {
                fontFamily: 'inherit',
                type: 'bar',
                height: height,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    borderRadius: 5,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val) {
                        return 'Rs. ' + val + ' thousands'
                    },
                },
            },
            colors: [baseColor, secondaryColor],
            grid: {
                borderColor: borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        }
    }

    function getChartOptions(height, data) {
        const labelColor = getCSSVariableValue('--kt-gray-500')
        const borderColor = getCSSVariableValue('--kt-gray-200')
        const baseColor = getCSSVariableValue('--kt-primary')
        const secondaryColor = getCSSVariableValue('--kt-gray-300')
        var orders = [];
        var prices = [];
        var i = 1;
        if (data) {
            data.forEach((element, index) => {
                for (var j = i; j < element.month; j++) {
                    orders.push(0);
                    prices.push(0);
                }
                if (i === element.month) {
                    orders.push(element.total_orders);
                    prices.push(element.total_price);
                }
                else
                    orders.push(0);
                i++;
            });
            if (data.length < 12) {
                for (var j = data.length; j < 12; j++) {
                    orders.push(0);
                    prices.push(0);
                }
            }

        }
        return {
            series: [
                {
                    name: 'Total Orders',
                    data: orders,
                }
                //,
                // {
                //     name: 'Approved Orders',
                //     data: [76, 85, 101, 98, 87, 105],
                // },
            ],
            chart: {
                fontFamily: 'inherit',
                type: 'bar',
                height: height,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    borderRadius: 5,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val) {
                        return '' + val + ' '
                    },
                },
            },
            colors: [baseColor, secondaryColor],
            grid: {
                borderColor: borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        }
    }
    const handleChangePeriod = async (e) => {
        console.log(e)
        setCustomDateRange(false);
        setNoData(false);
        if (e.target.value != '-1') {

            let payload = { vendor_id: currentUser.user_id, company_id: currentUser.company_id, duration: e.target.value };
            if (currentUser.user_type === UserType.Admin)
                payload = { company_id: currentUser.company_id, duration: e.target.value };

            generateChart(payload);
        } else {
            setCustomDateRange(true);
        }
    }

    const handleFilter = async (e) => {
        if (!startDate && !endDate) {
            toastError("Please select either start or end date or both");
            return false;
        }

        console.log(startDate, endDate)
        if (startDate && endDate) {
            let startDateList = startDate.split("-");
            let start = new Date(startDateList[0], startDateList[1], startDateList[2]);
            let endDateList = endDate.split("-");
            let end = new Date(endDateList[0], endDateList[1], endDateList[2]);
            if (start > end) {
                toastError("Start date should be less than end date.")
                return false;
            }
        }
        {
            let payload = { vendor_id: currentUser.user_id, company_id: currentUser.company_id, start_date: startDate, end_date: endDate };
            if (currentUser.user_type === UserType.Admin)
                payload = { company_id: currentUser.company_id, start_date: startDate, end_date: endDate };
            generateChart(payload);
        }
    }

    const handleStartDateChange = (e) => {
        console.log(e);
        setStartDate(e.target.value)
    }

    const handleEndDateChange = (e) => {
        console.log(e)
        setEndDate(e.target.value);
    }

    const handleClearFilter =(e)=>{
        setStartDate('');
        setEndDate('');
    }

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">
                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dashboard</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Reports</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-fluid"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Products */}
                                            <div className="w-100">
                                                {(currentUser.user_type === UserType.Admin || currentUser.mode === UserMode.Seller) ?
                                                    <div className="col-md-12 row">
                                                        <div
                                                            className="card col-md-12"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <div className="row mb-6">
                                                                    <div className="col-lg-3 fv-row">
                                                                        <label className="col fw-bold">Duration</label>
                                                                        <Form.Select onChange={(e) => handleChangePeriod(e)}>
                                                                            <option value="30">Last 30 days</option>
                                                                            <option value="60">Last 60 days</option>
                                                                            <option value="90">Last 90 days</option>
                                                                            <option value="-1">Custom</option>
                                                                        </Form.Select>
                                                                    </div>{customDateRange ? <>
                                                                        <div className="col-lg-3 fv-row date_section"> <label className="col fw-bold">Start Date</label><Form.Control type="date" name='startDate' placeholder="Start Date" onChange={(e) => handleStartDateChange(e)} value={startDate} />{startDate?<i className="fa fa-times clear_button" onClick={()=>setStartDate('')}/>:null}</div>
                                                                        <div className="col-lg-3 fv-row date_section"> <label className="col fw-bold">End Date</label><Form.Control type="date" name='endDate' placeholder="End Date" onChange={(e) => handleEndDateChange(e)} value={endDate} />{endDate?<i className="fa fa-times clear_button" onClick={()=>setEndDate('')}/>:null}</div>
                                                                        <div className="col-lg-3 fv-row"><label className="col fw-bold"> </label><button className="btn btn-primary" onClick={(e) => handleFilter(e)}><i className="fa fa-filter"></i> Filter</button> {(startDate || endDate)? 
                                                                        <button className="btn btn-secondary" onClick={(e) => handleClearFilter(e)}><i className="fa fa-times"></i> Clear</button>:null
                                                                        }</div>
                                                                    </> : null}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {noData ? <div>No Data found!</div> : <>
                                                            <div className={`card col-md-6`}>

                                                                {/* begin::Header */}
                                                                <div className='card-header border-0 pt-5'>
                                                                    {/* begin::Title */}
                                                                    <h3 className='card-title align-items-start flex-column'>
                                                                        <span className='card-label fw-bold fs-3 mb-1'>Orders Count</span>

                                                                        <span className='text-muted fw-semibold fs-7'>Orders Count Report</span>
                                                                    </h3>
                                                                    <div className='card-toolbar' data-kt-buttons='true'>
                                                                        {/* <a className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'>
                                                                        Year
                                                                    </a>

                                                                    <a className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'>
                                                                        Month
                                                                    </a> */}

                                                                        {/* <a className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                                                                        Week
                                                                    </a> */}
                                                                    </div>
                                                                </div>
                                                                <div className='card-body'>
                                                                    <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
                                                                </div>

                                                            </div>

                                                            <div className={`card col-md-6`}>
                                                                {/* begin::Header */}
                                                                <div className='card-header border-0 pt-5'>
                                                                    {/* begin::Title */}
                                                                    <h3 className='card-title align-items-start flex-column'>
                                                                        <span className='card-label fw-bold fs-3 mb-1'>Pricing</span>

                                                                        <span className='text-muted fw-semibold fs-7'>Orders Pricing Report</span>
                                                                    </h3>
                                                                    <div className='card-toolbar' data-kt-buttons='true'>
                                                                        {/* <a className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'>
                                                                        Year
                                                                    </a>

                                                                    <a className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'>
                                                                        Month
                                                                    </a> */}

                                                                        {/* <a className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                                                                        Week
                                                                    </a> */}
                                                                    </div>
                                                                </div>
                                                                <div className='card-body'>
                                                                    <div ref={chartPriceRef} id='kt_charts_widget_2_chart' style={{ height: '350px' }} />
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

export default Dashboard;


function getCSS(el, styleProp) {
    const defaultView = (el.ownerDocument || document).defaultView

    if (!defaultView) {
        return ''
    }

    // sanitize property name to css notation
    // (hyphen separated words eg. font-Size)
    styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase()

    return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp)
}

function getCSSVariableValue(variableName) {
    let hex = getComputedStyle(document.documentElement).getPropertyValue(variableName)
    if (hex && hex.length > 0) {
        hex = hex.trim()
    }

    return hex
}
