import axios from "axios";
import { getAllCategoriesURL, updateCategoryURL, saveCategoryURL, deleteCategoryURL } from "../constants/ApiEndPoints";

export const getAllCategories = async () => {
    try {
      const response = await axios.get(`${getAllCategoriesURL}`);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const updateCategory = async (category) => {
    try {
      const response = await axios.put(`${updateCategoryURL}`, category);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const saveCategory = async (category) => {
    try {
      const response = await axios.post(`${saveCategoryURL}`,category);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const deleteCategory = async (category) => {
    try {
      const response = await axios.put(`${deleteCategoryURL}`, category);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }