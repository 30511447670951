import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";

// import "../../assets/js/scripts.bundle.js";
// import landingLogo from "../../assets/media/svg/illustrations/landing.svg";
// import logo from "../../assets/media/logos/landing.svg";
// import fujifilm from "../../assets/media/svg/brand-logos/fujifilm.svg";
// import vodafone from "../../assets/media/svg/brand-logos/vodafone.svg";
// import kpmg from "../../assets/media/svg/brand-logos/kpmg.svg";
// import nasa from "../../assets/media/svg/brand-logos/nasa.svg";
// import aspnetzero from "../../assets/media/svg/brand-logos/aspnetzero.svg";
// import aon from "../../assets/media/svg/brand-logos/aon.svg";
// import hp from "../../assets/media/svg/brand-logos/hp-3.svg";
// import truman from "../../assets/media/svg/brand-logos/truman.svg";

// import sketchy1 from "../../assets/media/illustrations/sketchy-1/2.png";
// import sketchy2 from "../../assets/media/illustrations/sketchy-1/8.png";
// import sketchy3 from "../../assets/media/illustrations/sketchy-1/12.png";

import Script from "react-load-script";

import logo from "../../assets/media/auth/cmda-logo-3.png";

import Carousel from "react-bootstrap/Carousel";

// import SVG from 'react-inlinesvg';
const Landing = () => {
  const images = require.context("../../assets/media", true);
  return (
    <div
      data-kt-name="metronic"
      id="kt_body"
      data-bs-spy="scroll"
      data-bs-target="#kt_landing_menu"
      className="bg-white position-relative app-blank"
    >
      {/*begin::Theme mode setup on page load*/}
      {/*end::Theme mode setup on page load*/}
      {/*begin::Root*/}
      <div className="d-flex flex-column flex-root" id="kt_app_root">
        {/*begin::Header Section*/}
        <div className="mb-0" id="home">
          {/*begin::Wrapper*/}
          <div
            className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
            style={{
              backgroundImage:
                "url(assets/media/svg/illustrations/landing.svg)",
            }}
          >
            {/*begin::Header*/}
            <div
              className="landing-header"
              data-kt-sticky="true"
              data-kt-sticky-name="landing-header"
              data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            >
              {/*begin::Container*/}
              <div className="container">
                {/*begin::Wrapper*/}
                <div className="d-flex align-items-center justify-content-between">
                  {/*begin::Logo*/}
                  <div className="d-flex align-items-center flex-equal">
                    {/*begin::Mobile menu toggle*/}
                    <button
                      className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                      id="kt_landing_menu_toggle"
                    >
                      {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                      <span className="svg-icon svg-icon-2hx">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="currentColor"
                          />
                          <path
                            opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </button>
                    {/*end::Mobile menu toggle*/}
                    {/*begin::Logo image*/}
                    <a href="#">
                      <img
                        alt="Logo"
                        src={logo}
                        className="logo-default h-95px h-lg-90px"
                      />
                      <img
                        alt="Logo"
                        src={logo}
                        className="logo-sticky h-20px h-lg-25px"
                      />
                    </a>
                    {/*end::Logo image*/}
                  </div>
                  {/*end::Logo*/}
                  {/*begin::Menu wrapper*/}
                  <div className="d-lg-block" id="kt_header_nav_wrapper">
                    <div
                      className="d-lg-block p-5 p-lg-0"
                      data-kt-drawer="true"
                      data-kt-drawer-name="landing-menu"
                      data-kt-drawer-activate="{default: true, lg: false}"
                      data-kt-drawer-overlay="true"
                      data-kt-drawer-width="200px"
                      data-kt-drawer-direction="start"
                      data-kt-drawer-toggle="#kt_landing_menu_toggle"
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                    >
                      {/*begin::Menu*/}
                      <div
                        className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                        id="kt_landing_menu"
                      >
                        {/*begin::Menu item*/}
                        <div className="menu-item">
                          {/*begin::Menu link*/}
                          <a
                            className="menu-link nav-link active py-3 px-4 px-xxl-6"
                            href="#kt_body"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            Home
                          </a>
                          {/*end::Menu link*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item">
                          {/*begin::Menu link*/}
                          <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#how-it-works"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            How it Works
                          </a>
                          {/*end::Menu link*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item">
                          {/*begin::Menu link*/}
                          <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#achievements"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            Achievements
                          </a>
                          {/*end::Menu link*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item">
                          {/*begin::Menu link*/}
                          <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#team"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            Team
                          </a>
                          {/*end::Menu link*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item">
                          {/*begin::Menu link*/}
                          {/* <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#portfolio"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            Portfolio
                          </a> */}
                          {/*end::Menu link*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item">
                          {/*begin::Menu link*/}
                          {/* <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#pricing"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            Pricing
                          </a> */}
                          {/*end::Menu link*/}
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu*/}
                    </div>
                  </div>
                  {/*end::Menu wrapper*/}
                  {/*begin::Toolbar*/}
                  <div className="flex-equal text-end ms-1">
                    <Link to={'/login'}className="btn btn-success" > 
                      Sign In</Link>
                   
                  </div>
                  {/*end::Toolbar*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Container*/}
            </div>
            {/*end::Header*/}
            {/*begin::Landing hero*/}
            <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
              {/*begin::Heading*/}
              <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                {/*begin::Title*/}
                <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15">
                 A Profound Name CMDA in The Market
                  <br />
                  with
                  <span
                    style={{
                      background:
                        "linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    <span id="kt_landing_hero_text">&nbsp;The Best B2B Digital Commerce Platform</span>
                  </span>
                </h1>
                {/*end::Title*/}
                {/*begin::Action*/}
                <a
                  href="https://www.cmdapune.org"
                  className="btn btn-primary"
                  target="_blank"
                >
                  VIEW CMDA
                </a>
                {/*end::Action*/}
              </div>
              {/*end::Heading*/}
              {/*begin::Clients*/}
              {/* <div className="d-flex flex-center flex-wrap position-relative px-5">
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Fujifilm"
                >
                  <img
                    src="assets/media/svg/brand-logos/fujifilm.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Vodafone"
                >
                  <img
                    src="assets/media/svg/brand-logos/vodafone.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="KPMG International"
                >
                  <img
                    src="assets/media/svg/brand-logos/kpmg.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Nasa"
                >
                  <img
                    src="assets/media/svg/brand-logos/nasa.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Aspnetzero"
                >
                  <img
                    src="assets/media/svg/brand-logos/aspnetzero.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="AON - Empower Results"
                >
                  <img
                    src="assets/media/svg/brand-logos/aon.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Hewlett-Packard"
                >
                  <img
                    src="assets/media/svg/brand-logos/hp-3.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Truman"
                >
                  <img
                    src="assets/media/svg/brand-logos/truman.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
              </div> */}
              {/*end::Clients*/}
            </div>
            {/*end::Landing hero*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Curve bottom*/}
          <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"
              />
            </svg>
          </div>
          {/*end::Curve bottom*/}
        </div>
        {/*end::Header Section*/}
        {/*begin::How It Works Section*/}
        <div className="mb-n10 mb-lg-n20 z-index-2">
          {/*begin::Container*/}
          <div className="container">
            {/*begin::Heading*/}
            <div className="text-center mb-17">
              {/*begin::Title*/}
              <h3
                className="fs-2hx text-dark mb-5"
                id="how-it-works"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                How it Works
              </h3>
              {/*end::Title*/}
              {/*begin::Text*/}
              <div className="fs-5 text-muted fw-bold">
                A Digital Commerce B2B platform for Buyers & Partners which ultimately eases the process of buying / selling 
                <br />
                of various computer hardware products / related services.
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Heading*/}
            {/*begin::Row*/}
            <div className="row w-100 gy-10 mb-md-20">
              {/*begin::Col*/}
              <div className="col-md-4 px-5">
                {/*begin::Story*/}
                <div className="text-center mb-10 mb-md-0">
                  {/*begin::Illustration*/}
                  <img
                    src="assets/media/illustrations/sketchy-1/2.png"
                    className="mh-125px mb-9"
                    alt=""
                  />
                  {/*end::Illustration*/}
                  {/*begin::Heading*/}
                  <div className="d-flex flex-center mb-5">
                    {/*begin::Badge*/}
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                      1
                    </span>
                    {/*end::Badge*/}
                    {/*begin::Title*/}
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">
                      Upload Products
                    </div>
                    {/*end::Title*/}
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Description*/}
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                    Registered Partners can upload their respective products / services. 
                    <br />
                    Products can be uploaded via manual form entry or via bulk csv upload.
                    <br />
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Story*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-md-4 px-5">
                {/*begin::Story*/}
                <div className="text-center mb-10 mb-md-0">
                  {/*begin::Illustration*/}
                  <img
                    src="assets/media/illustrations/sketchy-1/8.png"
                    className="mh-125px mb-9"
                    alt=""
                  />
                  {/*end::Illustration*/}
                  {/*begin::Heading*/}
                  <div className="d-flex flex-center mb-5">
                    {/*begin::Badge*/}
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                      2
                    </span>
                    {/*end::Badge*/}
                    {/*begin::Title*/}
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">
                      Place Orders
                    </div>
                    {/*end::Title*/}
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Description*/}
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                  Registered Customers can view / search / add products and place orders from the portal.
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Story*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-md-4 px-5">
                {/*begin::Story*/}
                <div className="text-center mb-10 mb-md-0">
                  {/*begin::Illustration*/}
                  <img
                    src="assets/media/illustrations/sketchy-1/12.png"
                    className="mh-125px mb-9"
                    alt=""
                  />
                  {/*end::Illustration*/}
                  {/*begin::Heading*/}
                  <div className="d-flex flex-center mb-5">
                    {/*begin::Badge*/}
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                      3
                    </span>
                    {/*end::Badge*/}
                    {/*begin::Title*/}
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">
                      Track Orders
                    </div>
                    {/*end::Title*/}
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Description*/}
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                  Order Status can be tracked by customers once the order has been placed.
                    <br />
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Story*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Product slider*/}
            <div className="tns tns-default">
              {/*begin::Slider*/}
              <Carousel variant="dark">
              <Carousel.Item interval={4000}><div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                  <img
                    src="assets/media/product-demos/demo1.png"
                    className="card-rounded shadow mw-100"
                    alt=""
                  />
                </div></Carousel.Item>
                <Carousel.Item interval={4000}><div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                  <img
                    src="assets/media/product-demos/demo1.png"
                    className="card-rounded shadow mw-100"
                    alt=""
                  />
                </div></Carousel.Item>
                <Carousel.Item interval={4000}><div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                  <img
                    src="assets/media/product-demos/demo1.png"
                    className="card-rounded shadow mw-100"
                    alt=""
                  />
                </div></Carousel.Item>
              </Carousel>
              
              {/*end::Slider*/}
              {/*begin::Slider button*/}
           
              {/*end::Slider button*/}
              {/*begin::Slider button*/}
             
              {/*end::Slider button*/}
            </div>
            {/*end::Product slider*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::How It Works Section*/}
        {/*begin::Statistics Section*/}
        <div className="mt-sm-n10">
          {/*begin::Curve top*/}
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
          {/*end::Curve top*/}
          {/*begin::Wrapper*/}
          <div className="pb-15 pt-18 landing-dark-bg">
            {/*begin::Container*/}
            <div className="container">
              {/*begin::Heading*/}
              <div
                className="text-center mt-15 mb-18"
                id="achievements"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                {/*begin::Title*/}
                <h3 className="fs-2hx text-white fw-bold mb-5">
                  Working To Make IT Succeed
                </h3>
                {/*end::Title*/}
                {/*begin::Description*/}
                <div className="fs-5 text-gray-700 fw-bold">
                Our vision is to achieve the common goal of building value for our members to shape their professional & personal life 
                and take our share of social responsibility towards society through more disciplined acts and consistently be counted 
                amongst Indias most Valuable IT trade association.
                </div>
                {/*end::Description*/}
              </div>
              {/*end::Heading*/}
              {/*begin::Statistics*/}
              <div className="d-flex flex-center">
                {/*begin::Items*/}
                <div className="d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                  {/*begin::Item*/}
                  <div
                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{
                      backgroundImage:
                        'url("assets/media/svg/misc/octagon.svg")',
                    }}
                  >
                    {/*begin::Symbol*/}
                    {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x={2}
                          y={2}
                          width={9}
                          height={9}
                          rx={2}
                          fill="currentColor"
                        />
                        <rect
                          opacity="0.3"
                          x={13}
                          y={2}
                          width={9}
                          height={9}
                          rx={2}
                          fill="currentColor"
                        />
                        <rect
                          opacity="0.3"
                          x={13}
                          y={13}
                          width={9}
                          height={9}
                          rx={2}
                          fill="currentColor"
                        />
                        <rect
                          opacity="0.3"
                          x={2}
                          y={13}
                          width={9}
                          height={9}
                          rx={2}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    {/*end::Symbol*/}
                    {/*begin::Info*/}
                    <div className="mb-0">
                      {/*begin::Value*/}
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value={300}
                          data-kt-countup-suffix="+"
                        >
                          0
                        </div>
                      </div>
                      {/*end::Value*/}
                      {/*begin::Label*/}
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                       Committee Members
                      </span>
                      {/*end::Label*/}
                    </div>
                    {/*end::Info*/}
                  </div>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <div
                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{
                      backgroundImage:
                        'url("assets/media/svg/misc/octagon.svg")',
                    }}
                  >
                    {/*begin::Symbol*/}
                    {/*begin::Svg Icon | path: icons/duotune/graphs/gra008.svg*/}
                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    {/*end::Symbol*/}
                    {/*begin::Info*/}
                    <div className="mb-0">
                      {/*begin::Value*/}
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value={10}
                          data-kt-countup-suffix="M+"
                        >
                          0
                        </div>
                      </div>
                      {/*end::Value*/}
                      {/*begin::Label*/}
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                      Statistic Reports
                      </span>
                      {/*end::Label*/}
                    </div>
                    {/*end::Info*/}
                  </div>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <div
                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{
                      backgroundImage:
                        'url("assets/media/svg/misc/octagon.svg")',
                    }}
                  >
                    {/*begin::Symbol*/}
                    {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg*/}
                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    {/*end::Symbol*/}
                    {/*begin::Info*/}
                    <div className="mb-0">
                      {/*begin::Value*/}
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value={15}
                          data-kt-countup-suffix="K+"
                        >
                          0
                        </div>
                      </div>
                      {/*end::Value*/}
                      {/*begin::Label*/}
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                      Orders Placed
                      </span>
                      {/*end::Label*/}
                    </div>
                    {/*end::Info*/}
                  </div>
                  {/*end::Item*/}
                </div>
                {/*end::Items*/}
              </div>
              {/*end::Statistics*/}
              {/*begin::Testimonial*/}
              {/* <div className="fs-2 fw-semibold text-muted text-center mb-3">
                <span className="fs-1 lh-1 text-gray-700">“</span>Lorem Ipsum Lorem Ipsum 
                Lorem Ipsum Lorem Ipsum Lorem Ipsum 
                <br />
                <span className="text-gray-700 me-1">Lorem Ipsum </span>,
                Lorem Ipsum Lorem Ipsum 
                <span className="fs-1 lh-1 text-gray-700">“</span>
              </div> */}
              {/*end::Testimonial*/}
              {/*begin::Author*/}
              {/* <div className="fs-2 fw-semibold text-muted text-center">
                <a
                  href="#"
                  className="link-primary fs-4 fw-bold"
                >
                  Lorem Ipsum,
                </a>
                <span className="fs-4 fw-bold text-gray-600">
                  &nbsp; Lorem Ipsum
                </span>
              </div> */}
              {/*end::Author*/}
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Curve bottom*/}
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"
              />
            </svg>
          </div>
          {/*end::Curve bottom*/}
        </div>
        {/*end::Statistics Section*/}
        {/*begin::Team Section*/}
        <div className="py-10 py-lg-20">
          {/*begin::Container*/}
          <div className="container">
            {/*begin::Heading*/}
            <div className="text-center mb-12">
              {/*begin::Title*/}
              <h3
                className="fs-2hx text-dark mb-5"
                id="team"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                Our Great Team
              </h3>
              {/*end::Title*/}
              {/*begin::Sub-title*/}
              <div className="fs-5 text-muted fw-bold">
              Our Team with more than 300+ members, the association is doing considerable business in the computer hardware and 
              consumables sector in Pune region. It is the first association in India to get ISO 9001:2015 certifications.
              <br />
              The members of this association believe in collaborative approach towards helping each other and growing together.
              </div>
              {/*end::Sub-title=*/}
            </div>
            {/*end::Heading*/}
            {/*begin::Slider*/}
            <Carousel variant="dark">
              <Carousel.Item interval={4000}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>
                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-1.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Mujaffar Inamdar 
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      President
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-2.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Chintamani Kuber
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Vice President
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-3.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Ravikumar Shetty
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Secretary
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>
                </div>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>
                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-4.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Prashant Sathe
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Treasurer
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-5.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Sumit Vora
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Jt. Secretary
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-6.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Amul Shah
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Jt. Treasurer
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>
                </div>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-7.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Ciba Pathak
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-8.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Mahesh Patil
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-9.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Narendra Chawla
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                </div>
              </Carousel.Item>
              
              <Carousel.Item interval={4000}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-10.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Raviraj Padwal
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-11.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Mahendra Deshpande
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-12.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Rajendra Patil
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                </div>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-13.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Sachin Salunke
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-14.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Jayant Shete
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Advisory Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-15.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Rahul Hajare
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Advisory Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                </div>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>

                <div className="text-center">
                  {/*begin::Photo*/}
                  <div
                    className="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-16.jpg")',
                    }}
                  />
                  {/*end::Photo*/}
                  {/*begin::Person*/}
                  <div className="mb-0">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary fs-3"
                    >
                      Mr.Mangesh Bhalerao
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="text-muted fs-6 fw-semibold mt-1">
                      Advisory Committee Member
                    </div>
                    {/*begin::Position*/}
                  </div>
                  {/*end::Person*/}
                </div>

                </div>
              </Carousel.Item>

            </Carousel>
            
            {/*end::Slider*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Team Section*/}
        {/*begin::Projects Section*/}
       
        {/* <div className="mb-lg-n15 position-relative z-index-2">
          <div className="container">
            <div
              className="card"
              style={{
                filter: "drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))",
              }}
            >
              <div className="card-body p-lg-20">
                <div className="text-center mb-5 mb-lg-10">
                  <h3
                    className="fs-2hx text-dark mb-5"
                    id="portfolio"
                    data-kt-scroll-offset="{default: 100, lg: 250}"
                  >
                    Our Projects
                  </h3>
                </div>
                <div className="d-flex flex-center mb-5 mb-lg-15">
                  <ul className="nav border-transparent flex-center fs-5 fw-bold">
                    <li className="nav-item">
                      <a
                        className="nav-link text-gray-500 text-active-primary px-3 px-lg-6 active"
                        href="#"
                        data-bs-toggle="tab"
                        data-bs-target="#kt_landing_projects_latest"
                      >
                        Lorem Ipsum 
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-gray-500 text-active-primary px-3 px-lg-6"
                        href="#"
                        data-bs-toggle="tab"
                        data-bs-target="#kt_landing_projects_web_design"
                      >
                        Lorem Ipsum 
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-gray-500 text-active-primary px-3 px-lg-6"
                        href="#"
                        data-bs-toggle="tab"
                        data-bs-target="#kt_landing_projects_mobile_apps"
                      >
                        Lorem Ipsum 
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-gray-500 text-active-primary px-3 px-lg-6"
                        href="#"
                        data-bs-toggle="tab"
                        data-bs-target="#kt_landing_projects_development"
                      >
                        Lorem Ipsum 
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="kt_landing_projects_latest"
                  >
                    <div className="row g-10">
                      <div className="col-lg-6">
                        <a
                          className="d-block card-rounded overlay h-lg-100"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x600/img-23.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-23.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <div className="row g-10 mb-10">
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-22.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-22.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-21.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-21.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                        </div>
                        <a
                          className="d-block card-rounded overlay"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x400/img-20.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-20.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="kt_landing_projects_web_design"
                  >
                    <div className="row g-10">
                      <div className="col-lg-6">
                        <a
                          className="d-block card-rounded overlay h-lg-100"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x600/img-11.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-11.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <div className="row g-10 mb-10">
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-12.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-12.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-21.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-21.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                        </div>
                        <a
                          className="d-block card-rounded overlay"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x400/img-20.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-20.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="kt_landing_projects_mobile_apps"
                  >
                    <div className="row g-10">
                      <div className="col-lg-6">
                        <div className="row g-10 mb-10">
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-16.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-16.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-12.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-12.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                        </div>
                        <a
                          className="d-block card-rounded overlay"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x400/img-15.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-15.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <a
                          className="d-block card-rounded overlay h-lg-100"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x600/img-23.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-23.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="kt_landing_projects_development"
                  >
                    <div className="row g-10">
                      <div className="col-lg-6">
                        <a
                          className="d-block card-rounded overlay h-lg-100"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x600/img-15.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-15.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <div className="row g-10 mb-10">
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-22.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-22.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a
                              className="d-block card-rounded overlay"
                              data-fslightbox="lightbox-projects"
                              href="assets/media/stock/600x600/img-21.jpg"
                            >
                              <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                style={{
                                  backgroundImage:
                                    'url("assets/media/stock/600x600/img-21.jpg")',
                                }}
                              />
                              <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-3x text-white" />
                              </div>
                            </a>
                          </div>
                        </div>
                        <a
                          className="d-block card-rounded overlay"
                          data-fslightbox="lightbox-projects"
                          href="assets/media/stock/600x400/img-14.jpg"
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                            style={{
                              backgroundImage:
                                'url("assets/media/stock/600x600/img-14.jpg")',
                            }}
                          />
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="bi bi-eye-fill fs-3x text-white" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/*end::Projects Section*/}
        {/*begin::Pricing Section*/}
        <div className="mt-sm-n20">
          {/*begin::Curve top*/}
          {/* <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="currentColor"
              />
            </svg>
          </div> */}
          {/*end::Curve top*/}
          
          {/*begin::Wrapper*/}
            {/* <div className="py-20 landing-dark-bg">
            <div className="container">
              <div className="d-flex flex-column container pt-lg-20">
                <div className="mb-13 text-center">
                  <h1
                    className="fs-2hx fw-bold text-white mb-5"
                    id="pricing"
                    data-kt-scroll-offset="{default: 100, lg: 150}"
                  >
                    Clear Pricing Makes it Easy
                  </h1>
                  <div className="text-gray-600 fw-semibold fs-5">
                    Save thousands to millions of bucks by using single tool for
                    different
                    <br />
                    amazing and outstanding cool and great useful admin
                  </div>
                </div>
                <div className="text-center" id="kt_pricing">
                <a href="#" className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active" data-kt-plan="month">Monthly</a>
                <a href="#" className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3" data-kt-plan="annual">Annual</a>
              </div>
                  <div className="row g-10">
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-dark mb-5 fw-boldest">
                              Startup
                            </h1>
                            <div className="text-gray-400 fw-semibold mb-5">
                              Best Settings for Startups
                            </div>
                            <div className="text-center">
                              <span className="mb-2 text-primary">$</span>
                              <span
                                className="fs-3x fw-bold text-primary"
                                data-kt-plan-price-month={99}
                                data-kt-plan-price-annual={999}
                              >
                                99
                              </span>
                              <span
                                className="fs-7 fw-semibold opacity-50"
                                data-kt-plan-price-month="Mon"
                                data-kt-plan-price-annual="Ann"
                              >
                                / Mon
                              </span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">
                                Up to 10 Active Users
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">
                                Up to 30 Project Integrations
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800">
                                Keen Analytics Platform
                              </span>
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y="15.3137"
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-45 7 15.3137)"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x="8.41422"
                                    y={7}
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 8.41422 7)"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800">
                                Targets Timelines &amp; Files
                              </span>
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y="15.3137"
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-45 7 15.3137)"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x="8.41422"
                                    y={7}
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 8.41422 7)"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack">
                              <span className="fw-semibold fs-6 text-gray-800">
                                Unlimited Projects
                              </span>
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y="15.3137"
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-45 7 15.3137)"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x="8.41422"
                                    y={7}
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 8.41422 7)"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <a href="#" className="btn btn-primary">
                            Select
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-white mb-5 fw-boldest">
                              Business
                            </h1>
                            <div className="text-white opacity-75 fw-semibold mb-5">
                              Best Settings for Business
                            </div>
                            <div className="text-center">
                              <span className="mb-2 text-white">$</span>
                              <span
                                className="fs-3x fw-bold text-white"
                                data-kt-plan-price-month={199}
                                data-kt-plan-price-annual={1999}
                              >
                                199
                              </span>
                              <span
                                className="fs-7 fw-semibold text-white opacity-75"
                                data-kt-plan-price-month="Mon"
                                data-kt-plan-price-annual="Ann"
                              >
                                / Mon
                              </span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">
                                Up to 10 Active Users
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">
                                Up to 30 Project Integrations
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">
                                Keen Analytics Platform
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">
                                Targets Timelines &amp; Files
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack">
                              <span className="fw-semibold fs-6 text-white opacity-75">
                                Unlimited Projects
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-white">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <rect
                                    x={7}
                                    y="15.3137"
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-45 7 15.3137)"
                                    fill="currentColor"
                                  />
                                  <rect
                                    x="8.41422"
                                    y={7}
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 8.41422 7)"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <a
                            href="#"
                            className="btn btn-color-primary btn-active-light-primary btn-light"
                          >
                            Select
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="d-flex h-100 align-items-center">
                        <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                          <div className="mb-7 text-center">
                            <h1 className="text-dark mb-5 fw-boldest">
                              Enterprise
                            </h1>
                            <div className="text-gray-400 fw-semibold mb-5">
                              Best Settings for Enterprise
                            </div>
                            <div className="text-center">
                              <span className="mb-2 text-primary">$</span>
                              <span
                                className="fs-3x fw-bold text-primary"
                                data-kt-plan-price-month={999}
                                data-kt-plan-price-annual={9999}
                              >
                                999
                              </span>
                              <span
                                className="fs-7 fw-semibold opacity-50"
                                data-kt-plan-price-month="Mon"
                                data-kt-plan-price-annual="Ann"
                              >
                                / Mon
                              </span>
                            </div>
                          </div>
                          <div className="w-100 mb-10">
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">
                                Up to 10 Active Users
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">
                                Up to 30 Project Integrations
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">
                                Keen Analytics Platform
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                              <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">
                                Targets Timelines &amp; Files
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex flex-stack">
                              <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">
                                Unlimited Projects
                              </span>
                              <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <a href="#" className="btn btn-primary">
                            Select
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div> */}
          {/* </div> */}
          {/*end::Wrapper*/}

          {/*begin::Curve bottom*/}
          {/* <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"
              />
            </svg>
          </div> */}
          {/*end::Curve bottom*/}
        </div>
        {/*end::Pricing Section*/}
        {/*begin::Testimonials Section*/}
        <div className="mt-20 mb-n20 position-relative z-index-2">
          {/*begin::Container*/}
          <div className="container">
            {/*begin::Heading*/}
            <div className="text-center mb-17">
              {/*begin::Title*/}
              <h3
                className="fs-2hx text-dark mb-5"
                id="clients"
                data-kt-scroll-offset="{default: 125, lg: 150}"
              >
                What Our Clients Say
              </h3>
              {/*end::Title*/}
              {/*begin::Description*/}
              <div className="fs-5 text-muted fw-bold">
                One Stop B2B Digital Commerce Portal
              </div>
              {/*end::Description*/}
            </div>
            {/*end::Heading*/}
            {/*begin::Row*/}
            <Carousel variant="dark">
              <Carousel.Item interval={4000}>
              <div className="row g-lg-10 mb-10 mb-lg-20">
              {/*begin::Col*/}
              <div className="col-lg-4">
                {/*begin::Testimonial*/}
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  {/*begin::Wrapper*/}
                  <div className="mb-7">
                    {/*begin::Rating*/}
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                    </div>
                    {/*end::Rating*/}
                    {/*begin::Title*/}
                    <div className="fs-2 fw-bold text-dark mb-3">
                    cmdamahatrade.com is a very good portal for B2B. 
                    </div>
                    {/*end::Title*/}
                    {/*begin::Feedback*/}
                    <div className="text-gray-500 fw-semibold fs-4">
                    cmdamahatrade.com is a very good portal for B2B. Trade among our members. Search for products faster and place orders and at a 
                    very great discount compared to other sellers. I'd definitely recommend people to use this portal for our business growth.                     
                    </div>
                    {/*end::Feedback*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Author*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-circle symbol-50px me-5">
                      <img
                        src="assets/media/avatars/user-2.jpg"
                        className
                        alt=""
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Name*/}
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Mr. Santosh
                      </a>
                      <span className="text-muted d-block fw-bold">
                      {/* Lorem Ipsum */}
                      </span>
                    </div>
                    {/*end::Name*/}
                  </div>
                  {/*end::Author*/}
                </div>
                {/*end::Testimonial*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-4">
                {/*begin::Testimonial*/}
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  {/*begin::Wrapper*/}
                  <div className="mb-7">
                    {/*begin::Rating*/}
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                    </div>
                    {/*end::Rating*/}
                    {/*begin::Title*/}
                    <div className="fs-2 fw-bold text-dark mb-3">
                    A great initiative taken by CMDA
                    
                    </div>
                    {/*end::Title*/}
                    {/*begin::Feedback*/}
                    <div className="text-gray-500 fw-semibold fs-4">
                    A great initiative taken by cmda and created a platform for buyers and sellers created for our member's. We are always facing 
                    problems with prices and stock availability and specific product specifications. In one search you will get a product and availability. 
                    It's free access for cmda members. I suggested joining CMDA. 

                    </div>
                    {/*end::Feedback*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Author*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-circle symbol-50px me-5">
                      <img
                        src="assets/media/avatars/user-2.jpg"
                        className
                        alt=""
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Name*/}
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                      Mr. Mangesh
                      </a>
                      <span className="text-muted d-block fw-bold">
                      {/* Lorem Ipsum */}
                      </span>
                    </div>
                    {/*end::Name*/}
                  </div>
                  {/*end::Author*/}
                </div>
                {/*end::Testimonial*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-4">
                {/*begin::Testimonial*/}
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  {/*begin::Wrapper*/}
                  <div className="mb-7">
                    {/*begin::Rating*/}
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                    </div>
                    {/*end::Rating*/}
                    {/*begin::Title*/}
                    <div className="fs-2 fw-bold text-dark mb-3">
                    It's really very useful for dealers to sell their products easily.
                    </div>
                    {/*end::Title*/}
                    {/*begin::Feedback*/}
                    <div className="text-gray-500 fw-semibold fs-4">
                    Hi Everyone.. I have been using this portal from the first day. It's really very useful for dealers to sell their products easily. 
                    It is easy to upload products and services to update the stock and models with pricing. I am really to say i cluld manage stock easily 
                    with this platform. I am really happy to say that I am getting good business from this cmdamahatrade portal.

                    </div>
                    {/*end::Feedback*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Author*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-circle symbol-50px me-5">
                      <img
                        src="assets/media/avatars/user-2.jpg"
                        className
                        alt=""
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Name*/}
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                      Mr. Sumit
                      </a>
                      <span className="text-muted d-block fw-bold">
                      {/* Lorem Ipsum */}
                      </span>
                    </div>
                    {/*end::Name*/}
                  </div>
                  {/*end::Author*/}
                </div>
                {/*end::Testimonial*/}
              </div>
              {/*end::Col*/}
            </div>
              </Carousel.Item>
              <Carousel.Item interval={4000}>
              <div className="row g-lg-10 mb-10 mb-lg-20">
              {/*begin::Col*/}
              <div className="col-lg-4">
                {/*begin::Testimonial*/}
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  {/*begin::Wrapper*/}
                  <div className="mb-7">
                    {/*begin::Rating*/}
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                    </div>
                    {/*end::Rating*/}
                    {/*begin::Title*/}
                    <div className="fs-2 fw-bold text-dark mb-3">
                    A fantastic solution provided by CMDA
                    </div>
                    {/*end::Title*/}
                    {/*begin::Feedback*/}
                    <div className="text-gray-500 fw-semibold fs-4">
                    A fantastic solution provided by CMDA...We really appreciate that this kind of solution is very helpful for our community to grow their 
                    business smoothly. I like searching on this portal and it very quickly tells you which product is in stock at price. Consequently, 
                    we will place an order.
                    </div>
                    {/*end::Feedback*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Author*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-circle symbol-50px me-5">
                      <img
                        src="assets/media/avatars/user-2.jpg"
                        className
                        alt=""
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Name*/}
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                      Mr. Prashant
                      </a>
                      <span className="text-muted d-block fw-bold">
                      {/* Lorem Ipsum */}
                      </span>
                    </div>
                    {/*end::Name*/}
                  </div>
                  {/*end::Author*/}
                </div>
                {/*end::Testimonial*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-4">
                {/*begin::Testimonial*/}
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  {/*begin::Wrapper*/}
                  <div className="mb-7">
                    {/*begin::Rating*/}
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                    </div>
                    {/*end::Rating*/}
                    {/*begin::Title*/}
                    <div className="fs-2 fw-bold text-dark mb-3">
                    Easily connected with 350+ members
                    </div>
                    {/*end::Title*/}
                    {/*begin::Feedback*/}
                    <div className="text-gray-500 fw-semibold fs-4">
                    Hi members. I am an IT service provider. I received an email from CMDA regarding the Cmamahatrade portal. Once I registered, I got login 
                    and password through i logged in and uploaded my services, like AMC, Laptop Repair, Printer Repair, Data Recovery Services,  Motherboard 
                    Repair. Once I update on the portal, I will be connected through 350+ members. I instantly started to get orders from the portal. It is 
                    really thanks to CMDA team-created solution for the Service category.
                    </div>
                    {/*end::Feedback*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Author*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-circle symbol-50px me-5">
                      <img
                        src="assets/media/avatars/user-2.jpg"
                        className
                        alt=""
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Name*/}
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                      Mr. Vinal
                      </a>
                      <span className="text-muted d-block fw-bold">
                      {/* Lorem Ipsum */}
                      </span>
                    </div>
                    {/*end::Name*/}
                  </div>
                  {/*end::Author*/}
                </div>
                {/*end::Testimonial*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-4">
                {/*begin::Testimonial*/}
                <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                  {/*begin::Wrapper*/}
                  <div className="mb-7">
                    {/*begin::Rating*/}
                    <div className="rating mb-6">
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                      <div className="rating-label me-2 checked">
                        <i className="bi bi-star-fill fs-5" />
                      </div>
                    </div>
                    {/*end::Rating*/}
                    {/*begin::Title*/}
                    <div className="fs-2 fw-bold text-dark mb-3">
                    Highly Recommended! It's very easy & helpful to buy products on cmdamahatrade.com
                    </div>
                    {/*end::Title*/}
                    {/*begin::Feedback*/}
                    <div className="text-gray-500 fw-semibold fs-4">
                    Highly Recommended! I am a reseller. I am using this portal. It's really helpful for me to buy products from this cmdamahatrade portal. 
                    In one search I will get product information and stock availability. I am very happy to say that i am getting more benefits when I 
                    buy products. I really recommend you to join CMDA and make these facilities free for members.
                    </div>
                    {/*end::Feedback*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Author*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-circle symbol-50px me-5">
                      <img
                        src="assets/media/avatars/user-2.jpg"
                        className
                        alt=""
                      />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Name*/}
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Santosh
                      </a>
                      <span className="text-muted d-block fw-bold">
                      {/* Lorem Ipsum */}
                      </span>
                    </div>
                    {/*end::Name*/}
                  </div>
                  {/*end::Author*/}
                </div>
                {/*end::Testimonial*/}
              </div>
              {/*end::Col*/}
            </div>
              </Carousel.Item>
            </Carousel>
          
            {/*end::Row*/}
            {/*begin::Highlight*/}
            <div
              className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
              style={{
                background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%)",
              }}
            >
              {/*begin::Content*/}
              <div className="my-2 me-5">
                {/*begin::Title*/}
                <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">
                 Join Over Today
                  <span className="fw-normal">&nbsp;and Stay Connected With Over 300+ Partners</span>
                </div>
                {/*end::Title*/}
                {/*begin::Description*/}
                {/* <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">
                  Join Over and Stay Connected With Over 300+ Vendors
                </div> */}
                {/*end::Description*/}
              </div>
              {/*end::Content*/}
              {/*begin::Link*/}
              {/* <a
                href="https://1.envato.market/EA4JP"
                className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2"
              >
                Purchase on Themeforest
              </a> */}
              {/*end::Link*/}
            </div>
            {/*end::Highlight*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Testimonials Section*/}
        {/*begin::Footer Section*/}
        <div className="mb-0">
          {/*begin::Curve top*/}
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
          {/*end::Curve top*/}
          {/*begin::Wrapper*/}
          <div className="landing-dark-bg pt-20">
            {/*begin::Container*/}
            <div className="container">
              {/*begin::Row*/}
              <div className="row py-10 py-lg-20">
                {/*begin::Col*/}
                <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                  {/*begin::Block*/}
                  <div className="rounded landing-dark-border p-9 mb-10">
                    {/*begin::Title*/}
                    <h2 className="text-white">
                    Would you like to join our team?
                    </h2>
                    {/*end::Title*/}
                    {/*begin::Text*/}
                    <span className="fw-normal fs-4 text-gray-700">
                      Email:  &nbsp;
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        admin@cmdapune.org
                      </a>
                    </span>
                    {/*end::Text*/}

                  <br/>

                    {/*begin::Text*/}
                    <span className="fw-normal fs-4 text-gray-700">
                      Contact No: &nbsp;
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        +912024212730 | +912024213730
                      </a>
                    </span>
                    {/*end::Text*/}

                  <br/>

                     {/*begin::Text*/}
                     <span className="fw-normal fs-4 text-gray-700">
                      Office Address: &nbsp;
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        Computers and Media Dealers Association
                        Office No.23, S.No. 93,
                        Laxminagar Commercial Complex,
                        Opposite Gajanan Maharaj Math, Laxminagar.
                        Pune 411009.
                      </a>
                    </span>
                    {/*end::Text*/}

                  </div>
                  {/*end::Block*/}
                  {/*begin::Block*/}
                  <div className="rounded landing-dark-border p-9">
                    {/*begin::Title*/}
                    <h2 className="text-white">Project Leader: Mr.Mahesh Patil</h2>
                    {/*end::Title*/}

                    <div
                    className="octagon mx-auto mb-5 d-flex w-150px h-150px bgi-no-repeat bgi-size-contain bgi-position-center"
                    style={{
                      backgroundImage: 'url("assets/media/avatars/member-8.jpg")',
                    }}
                  />

                    {/*begin::Text*/}
                    <span className="fw-normal fs-4 text-gray-700">
                   Email:
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        &nbsp; mahesh.patil@cmdapune.org
                      </a>
                    </span>
                    {/*end::Text*/}
                  </div>
                  {/*end::Block*/}










                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-lg-6 ps-lg-16">
                  <div className="d-flex justify-content-center">
                    
                    <div className="d-flex fw-semibold flex-column me-20">
                      <h4 className="fw-bold text-gray-400 mb-6">
                      {/* Lorem Ipsum  */}
                      </h4>
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        {/* Lorem Ipsum  */}
                      </a>
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        {/* Lorem Ipsum  */}
                      </a>
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        {/* Lorem Ipsum  */}
                      </a>
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        {/* Lorem Ipsum  */}
                      </a>
                      <a
                        href="#"
                        className="text-white opacity-50 text-hover-primary fs-5"
                      >
                        {/* Lorem Ipsum  */}
                      </a>
                    </div>
                    
                    <div className="d-flex fw-semibold flex-column ms-lg-20">
                      {/*begin::Subtitle*/}
                      <h4 className="fw-bold text-gray-400 mb-6">
                      Stay Connected
                      </h4>
                      {/*end::Subtitle*/}
                      {/*begin::Link*/}
                      <a
                        href="https://www.facebook.com/punecmda"
                        className="mb-6"
                      >
                        <img
                          src="assets/media/svg/brand-logos/facebook-4.svg"
                          className="h-20px me-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                        Facebook 
                        </span>
                      </a>
                      {/*end::Link*/}
                      {/*begin::Link*/}
                      {/* <a
                        href="#"
                        className="mb-6"
                      >
                        <img
                          src="assets/media/svg/brand-logos/github.svg"
                          className="h-20px me-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          Github
                        </span>
                      </a> */}
                      {/*end::Link*/}
                      {/*begin::Link*/}
                      <a href="https://www.linkedin.com/company/cmdapune/" className="mb-6">
                        <img
                          src="assets/media/svg/brand-logos/linkedin-1.svg"
                          className="h-20px me-2" style={{'padding-right': '10px'}}
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                        Linkedin
                        </span>
                      </a>
                      {/*end::Link*/}
                      {/*begin::Link*/}
                      <a
                        href="https://www.instagram.com/cmdapune2023/"
                        className="mb-6"
                      >
                        <img
                          src="assets/media/svg/brand-logos/instagram-2-1.svg"
                          className="h-20px me-2" style={{'padding-left': '4px'}}
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                        Instagram 
                        </span>
                      </a>
                      {/*end::Link*/}
                    </div>


                    {/*end::Links*/}
                  </div>
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
              
            </div>
            {/*end::Container*/}
            {/*begin::Separator*/}
            <div className="landing-dark-separator" />
            {/*end::Separator*/}
            {/*begin::Container*/}
            <div className="container">

              {/*begin::Wrapper*/}
              <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                {/*begin::Copyright*/}
                <div className="d-flex align-items-center order-2 order-md-1">
                  {/*begin::Logo*/}
                  {/* <a href="#">
                    <img
                      alt="Logo"
                      src="assets/media/logos/landing.svg"
                      className="h-15px h-md-20px"
                    />
                  </a> */}
                  {/*end::Logo image*/}
                  {/*begin::Logo image*/}
                  <span
                    className="mx-5 fs-6 fw-semibold text-gray-600 pt-1"
                    href="#"
                  >
                    Copyright © 2023 CMDA PUNE
                  </span>

                  <span
                    className="mx-5 fs-6 fw-semibold text-gray-600 pt-1"
                    href="#"
                  >
                    Developed & Maintained by SF-INFOTECH
                  </span>

                  {/*end::Logo image*/}
                </div>
                {/*end::Copyright*/}
                {/*begin::Menu*/}
                {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                  <li className="menu-item">
                    <a
                      href="#"
                      target="_blank"
                      className="menu-link px-2"
                    >
                      About
                    </a>
                  </li>
                  <li className="menu-item mx-5">
                    <a
                      href="#"
                      target="_blank"
                      className="menu-link px-2"
                    >
                      Support
                    </a>
                  </li>
                </ul> */}
                {/*end::Menu*/}
              </div>
              {/*end::Wrapper*/}

            </div>
            {/*end::Container*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Footer Section*/}
        {/*begin::Scrolltop*/}
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
          <span className="svg-icon">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="currentColor"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Scrolltop*/}
      </div>
      {/*end::Root*/}
      {/*begin::Engage drawers*/}
      {/*begin::Demos drawer*/}
      <div
        id="kt_engage_demos"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="explore"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_engage_demos_toggle"
        data-kt-drawer-close="#kt_engage_demos_close"
      >
        {/*begin::Card*/}
        <div className="card shadow-none rounded-0 w-100">
          {/*begin::Header*/}
          <div className="card-header" id="kt_engage_demos_header">
            {/* <h3 className="card-title fw-bold text-gray-700">Demos</h3> */}
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6"
                id="kt_engage_demos_close"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </button>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body" id="kt_engage_demos_body">
            {/*begin::Content*/}
            <div
              id="kt_explore_scroll"
              className="scroll-y me-n5 pe-5"
              data-kt-scroll="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#kt_engage_demos_body"
              data-kt-scroll-dependencies="#kt_engage_demos_header"
              data-kt-scroll-offset="5px"
            >
              {/*begin::Wrapper*/}
              <div className="mb-0">
                {/*begin::Heading*/}
                <div className="mb-7">
                  <div className="d-flex flex-stack">
                    <h3 className="mb-0">Lorem Ipsum</h3>
                    <a
                      href="#"
                      className="fw-semibold"
                      target="_blank"
                    >
                      License FAQs
                    </a>
                  </div>
                </div>
                {/*end::Heading*/}
                {/*begin::License*/}
                <div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
                  <div className="d-flex flex-stack">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-1">
                        <div className="fs-6 fw-semibold text-gray-800 fw-semibold mb-0 me-1">
                          Regular License
                        </div>
                        <i
                          className="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
                          data-bs-toggle="popover"
                          data-bs-custom-class="popover-inverse"
                          data-bs-trigger="hover"
                          data-bs-placement="top"
                          data-bs-content="Use, by you or one client in a single end product which end users are not charged for"
                        />
                      </div>
                      <div className="fs-7 text-muted">
                        For single end product used by you or one client
                      </div>
                    </div>
                    <div className="text-nowrap">
                      <span className="text-muted fs-7 fw-semibold me-n1">
                        $
                      </span>
                      <span className="text-dark fs-1 fw-bold">39</span>
                    </div>
                  </div>
                </div>
                {/*end::License*/}
                {/*begin::License*/}
                <div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
                  <div className="d-flex flex-stack">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-1">
                        <div className="fs-6 fw-semibold text-gray-800 fw-semibold mb-0 me-1">
                          Extended License
                        </div>
                        <i
                          className="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
                          data-bs-toggle="popover"
                          data-bs-custom-class="popover-inverse"
                          data-bs-trigger="hover"
                          data-bs-placement="top"
                          data-bs-content="Use, by you or one client, in a single end product which end users can be charged for."
                        />
                      </div>
                      <div className="fs-7 text-muted">
                        For single SaaS app with paying users
                      </div>
                    </div>
                    <div className="text-nowrap">
                      <span className="text-muted fs-7 fw-semibold me-n1">
                        $
                      </span>
                      <span className="text-dark fs-1 fw-bold">969</span>
                    </div>
                  </div>
                </div>
                {/*end::License*/}
                {/*begin::License*/}
                <div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
                  <div className="d-flex flex-stack">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-1">
                        <div className="fs-6 fw-semibold text-gray-800 fw-semibold mb-0 me-1">
                          Custom License
                        </div>
                      </div>
                      <div className="fs-7 text-muted">
                        Reach us for custom license offers.
                      </div>
                    </div>
                    <div className="text-nowrap">
                      <a
                        href="https://devs.keenthemes.com"
                        className="btn btn-sm btn-success"
                        target="_blank"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
                {/*end::License*/}
                {/*begin::Purchase*/}
                <a
                  href="https://1.envato.market/EA4JP"
                  className="btn btn-primary fw-bold mb-15 w-100"
                >
                  Buy Now
                </a>
                {/*end::Purchase*/}
                {/*begin::Demos*/}
                <div className="mb-0">
                  <h3 className="fw-bold text-center mb-6">Metronic Demos</h3>
                  {/*begin::Row*/}
                  <div className="row g-5">
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-success rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo1.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo1"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 1
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo2.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo2"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 2
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo3.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo3"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 3
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo4.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo4"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 4
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo5.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo5"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 5
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo6.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo6"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 6
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo7.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo7"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 7
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo8.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo8"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 8
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo9.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo9"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 9
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo10.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo10"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 10
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo11.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo11"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 11
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo12.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo12"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 12
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo13.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo13"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 13
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo14.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo14"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 14
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo15.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo15"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 15
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo16.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo16"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 16
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo17.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo17"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 17
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo18.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo18"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 18
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo19.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo19"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 19
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo20.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo20"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 20
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo21.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo21"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 21
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo22.png"
                            alt="demo"
                            className="w-100"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="https://preview.keenthemes.com/metronic8/demo22"
                            className="btn btn-sm btn-success shadow"
                          >
                            Demo 22
                          </a>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo23.png"
                            alt="demo"
                            className="w-100 opacity-25"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <div className="badge badge-white px-6 py-4 fw-semibold fs-base shadow">
                            Coming soon
                          </div>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-6">
                      {/*begin::Demo*/}
                      <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                        <div className="overlay-wrapper">
                          <img
                            src="assets/media/demos/demo24.png"
                            alt="demo"
                            className="w-100 opacity-25"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <div className="badge badge-white px-6 py-4 fw-semibold fs-base shadow">
                            Coming soon
                          </div>
                        </div>
                      </div>
                      {/*end::Demo*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Demos*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Card*/}
      </div>
      {/*end::Demos drawer*/}
      {/*begin::Help drawer*/}
      <div
        id="kt_help"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="help"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'350px', 'md': '525px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_help_toggle"
        data-kt-drawer-close="#kt_help_close"
      >
        {/*begin::Card*/}
        <div className="card shadow-none rounded-0 w-100">
          {/*begin::Header*/}
          <div className="card-header" id="kt_help_header">
            <h5 className="card-title fw-semibold text-gray-600">
              Learn &amp; Get Inspired
            </h5>
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-sm btn-icon explore-btn-dismiss me-n5"
                id="kt_help_close"
              >
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </button>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body" id="kt_help_body">
            {/*begin::Content*/}
            <div
              id="kt_help_scroll"
              className="hover-scroll-overlay-y"
              data-kt-scroll="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#kt_help_body"
              data-kt-scroll-dependencies="#kt_help_header"
              data-kt-scroll-offset="5px"
            >
              {/*begin::Support*/}
              <div className="rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10">
                {/*begin::Heading*/}
                <h2 className="fw-bold mb-5">
                  Support at
                  <a href="https://devs.keenthemes.com" className>
                    devs.keenthemes.com
                  </a>
                </h2>
                {/*end::Heading*/}
                {/*begin::Description*/}
                <div className="fs-5 fw-semibold mb-5">
                  <span className="text-gray-500">
                    Join our developers community to find answer to your
                    question and help others.
                  </span>
                  <a
                    className="explore-link d-none"
                    href="https://keenthemes.com/licensing"
                  >
                    FAQs
                  </a>
                </div>
                {/*end::Description*/}
                {/*begin::Link*/}
                <a
                  href="https://devs.keenthemes.com"
                  className="btn btn-lg explore-btn-primary w-100"
                >
                  Get Support
                </a>
                {/*end::Link*/}
              </div>
              {/*end::Support*/}
              {/*begin::Link*/}
              <div className="d-flex align-items-center mb-7">
                {/*begin::Icon*/}
                <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning">
                  {/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                  <span className="svg-icon svg-icon-warning svg-icon-2x svg-icon-lg-3x">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                        fill="currentColor"
                      />
                      <path
                        d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Icon*/}
                {/*begin::Info*/}
                <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column me-2 me-lg-5">
                    {/*begin::Title*/}
                    <a
                      href="#"
                      className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                    >
                      Documentation &amp; Videos
                    </a>
                    {/*end::Title*/}
                    {/*begin::Description*/}
                    <div className="text-muted fw-semibold fs-7 fs-lg-6">
                      From guides and video tutorials, to live demos and code
                      examples to get started.
                    </div>
                    {/*end::Description*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="currentColor"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Link*/}
              {/*begin::Link*/}
              <div className="d-flex align-items-center mb-7">
                {/*begin::Icon*/}
                <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary">
                  {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
                  <span className="svg-icon svg-icon-primary svg-icon-2x svg-icon-lg-3x">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z"
                        fill="currentColor"
                      />
                      <path
                        d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Icon*/}
                {/*begin::Info*/}
                <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column me-2 me-lg-5">
                    {/*begin::Title*/}
                    <a
                      href="#"
                      className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                    >
                      Plugins &amp; Components
                    </a>
                    {/*end::Title*/}
                    {/*begin::Description*/}
                    <div className="text-muted fw-semibold fs-7 fs-lg-6">
                      Check out our 300+ in-house components and customized
                      3rd-party plugins.
                    </div>
                    {/*end::Description*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="currentColor"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Link*/}
              {/*begin::Link*/}
              <div className="d-flex align-items-center mb-7">
                {/*begin::Icon*/}
                <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-info">
                  {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                  <span className="svg-icon svg-icon-info svg-icon-2x svg-icon-lg-3x">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                        fill="currentColor"
                      />
                      <path
                        d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Icon*/}
                {/*begin::Info*/}
                <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column me-2 me-lg-5">
                    {/*begin::Title*/}
                    <a
                      href="#"
                      className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                    >
                      Layout Builder
                    </a>
                    {/*end::Title*/}
                    {/*begin::Description*/}
                    <div className="text-muted fw-semibold fs-7 fs-lg-6">
                      Build your layout, preview it and export the HTML for
                      server side integration.
                    </div>
                    {/*end::Description*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="currentColor"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Link*/}
              {/*begin::Link*/}
              <div className="d-flex align-items-center mb-7">
                {/*begin::Icon*/}
                <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-success">
                  {/*begin::Svg Icon | path: icons/duotune/files/fil021.svg*/}
                  <span className="svg-icon svg-icon-success svg-icon-2x svg-icon-lg-3x">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                        fill="currentColor"
                      />
                      <path
                        d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Icon*/}
                {/*begin::Info*/}
                <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column me-2 me-lg-5">
                    {/*begin::Title*/}
                    <a
                      href="https://devs.keenthemes.com/metronic"
                      className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                    >
                      Metronic Downloads
                    </a>
                    {/*end::Title*/}
                    {/*begin::Description*/}
                    <div className="text-muted fw-semibold fs-7 fs-lg-6">
                      Download your prefered framework and demo with one click.
                    </div>
                    {/*end::Description*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="currentColor"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Link*/}
              {/*begin::Link*/}
              <div className="d-flex align-items-center mb-7">
                {/*begin::Icon*/}
                <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-danger">
                  {/*begin::Svg Icon | path: icons/duotune/electronics/elc009.svg*/}
                  <span className="svg-icon svg-icon-danger svg-icon-2x svg-icon-lg-3x">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 9V8C13 7.4 13.4 7 14 7H15C16.7 7 18 5.7 18 4V3C18 2.4 17.6 2 17 2C16.4 2 16 2.4 16 3V4C16 4.6 15.6 5 15 5H14C12.3 5 11 6.3 11 8V9H13Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M21 22H3C2.4 22 2 21.6 2 21V10C2 9.4 2.4 9 3 9H21C21.6 9 22 9.4 22 10V21C22 21.6 21.6 22 21 22ZM5 12C4.4 12 4 12.4 4 13C4 13.6 4.4 14 5 14C5.6 14 6 13.6 6 13C6 12.4 5.6 12 5 12ZM8 12C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14C8.6 14 9 13.6 9 13C9 12.4 8.6 12 8 12ZM11 12C10.4 12 10 12.4 10 13C10 13.6 10.4 14 11 14C11.6 14 12 13.6 12 13C12 12.4 11.6 12 11 12ZM14 12C13.4 12 13 12.4 13 13C13 13.6 13.4 14 14 14C14.6 14 15 13.6 15 13C15 12.4 14.6 12 14 12ZM9 15C8.4 15 8 15.4 8 16C8 16.6 8.4 17 9 17C9.6 17 10 16.6 10 16C10 15.4 9.6 15 9 15ZM12 15C11.4 15 11 15.4 11 16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16C13 15.4 12.6 15 12 15ZM15 15C14.4 15 14 15.4 14 16C14 16.6 14.4 17 15 17C15.6 17 16 16.6 16 16C16 15.4 15.6 15 15 15ZM19 18C18.4 18 18 18.4 18 19C18 19.6 18.4 20 19 20C19.6 20 20 19.6 20 19C20 18.4 19.6 18 19 18ZM7 19C7 18.4 6.6 18 6 18H5C4.4 18 4 18.4 4 19C4 19.6 4.4 20 5 20H6C6.6 20 7 19.6 7 19ZM7 16C7 15.4 6.6 15 6 15H5C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17H6C6.6 17 7 16.6 7 16ZM17 14H19C19.6 14 20 13.6 20 13C20 12.4 19.6 12 19 12H17C16.4 12 16 12.4 16 13C16 13.6 16.4 14 17 14ZM18 17H19C19.6 17 20 16.6 20 16C20 15.4 19.6 15 19 15H18C17.4 15 17 15.4 17 16C17 16.6 17.4 17 18 17ZM17 19C17 18.4 16.6 18 16 18H9C8.4 18 8 18.4 8 19C8 19.6 8.4 20 9 20H16C16.6 20 17 19.6 17 19Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Icon*/}
                {/*begin::Info*/}
                <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column me-2 me-lg-5">
                    {/*begin::Title*/}
                    <a
                      href="#"
                      className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                    >
                      What's New
                    </a>
                    {/*end::Title*/}
                    {/*begin::Description*/}
                    <div className="text-muted fw-semibold fs-7 fs-lg-6">
                      Latest features and improvements added with our users
                      feedback in mind.
                    </div>
                    {/*end::Description*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                  <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x={18}
                        y={13}
                        width={13}
                        height={2}
                        rx={1}
                        transform="rotate(-180 18 13)"
                        fill="currentColor"
                      />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Link*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Card*/}
      </div>
      {/*end::Help drawer*/}
      {/*end::Engage drawers*/}
      {/*begin::Engage toolbar*/}

      {/*end::Engage toolbar*/}
      {/*begin::Scrolltop*/}
      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
        <span className="svg-icon">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.5"
              x={13}
              y={6}
              width={13}
              height={2}
              rx={1}
              transform="rotate(90 13 6)"
              fill="currentColor"
            />
            <path
              d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
              fill="currentColor"
            />
          </svg>
        </span>
        {/*end::Svg Icon*/}
      </div>
      {/*end::Scrolltop*/}
      {/*begin::Javascript*/}
      {/*begin::Global Javascript Bundle(used by all pages)*/}
      {/*end::Global Javascript Bundle*/}
      {/*begin::Vendors Javascript(used by this page)*/}
      {/*end::Vendors Javascript*/}
      {/*begin::Custom Javascript(used by this page)*/}
      {/*end::Custom Javascript*/}
      {/*end::Javascript*/}
    </div>
  );
};
export default Landing;
