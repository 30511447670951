import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

import { getEnquiries } from "../../services/EnquiryServices";
import ModalBox from "../../shared/Modal";
import { getUser } from '../../shared/UserUtil';
import { UserMode, UserType, CommentType } from "../../shared/Constants";
import { PerPageSize } from "../../constants/CommonConstants";
import { formatDateString } from "../../shared/DateUtil";

const EnquiryPage = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [enquiries, setEnquiries] = useState();
    const [currentUser, setCurrentUser] = useState(getUser());
    const [activePage, setActivePage] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        fetchEnquires();
    }, []);

    useEffect(() => {
    }, [activePage]);

    const fetchByPage = async (pageNumber) => {
        let payload = { user_id: currentUser.user_id, page: pageNumber, company_id: currentUser.company_id, comment_type: CommentType.Enquiry };
        if (currentUser.user_type <= UserType.Admin) {
            delete payload.user_id;
        }
        let resp = await getEnquiries(payload);
        if (resp && resp.data) {
            if (resp.status === 200) {
                setEnquiries(resp.data);
                setTotal(resp.total);
            } else {
                console.log('500!');
            }
        }
    }

    const fetchEnquires = async () => {
        fetchByPage(activePage);
    }

    const handlePageChange = async (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        fetchByPage(pageNumber)
    };


    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">

                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Enquiry</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Enquiry</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-xxl"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Products */}
                                            <div className="w-100">
                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Manage Enquiry</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table id="tbl_tickets" className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">#</th>
                                                                                                {currentUser.user_type<=UserType.Admin?<><th className="p-0 pb-3 min-w-100px text-start">User Name</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">User Email</th></>:null}
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">Comments</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">Created At</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                enquiries?.map((e, i) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr key={i}>
                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{i + 1}</span>
                                                                                                                </td>

                                                                                                                {currentUser.user_type<=UserType.Admin?<> <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{e.user_fname + ' ' + e.user_lname}</span>
                                                                                                                </td>

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{e.user_email}</span>
                                                                                                                </td></>:null}

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{e.comments}</span>
                                                                                                                </td>

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{formatDateString(e.created_at)}</span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                    {enquiries?.length==0?<div className="no_data">No Enquiries found!</div>:null}
                                                                                </div>
                                                                                {parseFloat(total / PerPageSize) > 1 ?
                                                                                    <Pagination
                                                                                        activePage={activePage}
                                                                                        itemsCountPerPage={15}
                                                                                        totalItemsCount={total ? total : 0}
                                                                                        pageRangeDisplayed={15}
                                                                                        onChange={handlePageChange}
                                                                                        itemClass="page-item"
                                                                                        linkClass="page-link"
                                                                                    />
                                                                                    : null}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnquiryPage;