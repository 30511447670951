import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Select } from 'react-bootstrap'
import { addCompany, getAllCompanies, getSubscriptionTypes, blockCompany } from "../../services/CompanyService";
import ModalBox from "../../shared/Modal";
import { formatDateString } from "../../shared/DateUtil";
import { toastSuccess, toastError } from '../../shared/Toast';
import { getUser } from "../../shared/UserUtil";

const AddCompany = () => {
    const [showModal, setShowModal] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);

    useEffect(() => {
        fetchAllCompanies();
        fetchSubscriptionTypes();
    }, []);

    const fetchAllCompanies = async () => {
        let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));
        let payload = { user_id: current_user_data.user.user_id };
        const resp_companies = await getAllCompanies(payload);
        setCompanyList(resp_companies.data);
    }

    const fetchSubscriptionTypes = async () => {
        let currentUser = getUser();
        let payload = { user_id: currentUser.user_id };
        const response = await getSubscriptionTypes(payload);
        setSubscriptionTypes(response.data);
    }

    const addNewCompany = async (values) => {
        console.log(values);
        let userResp = await addCompany({
            company_name: values.company_name,
            user_first_name: values.user_first_name,
            user_last_name: values.user_last_name,
            user_address: values.user_address,
            user_contact_1: values.user_contact_1,
            user_contact_2: values.user_contact_2,
            user_email: values.user_email,
            user_password: values.user_password,
            subscription_type_id: values.subscription_type
        });

        if (userResp.status === 200) {
            setShowModal(false);
            toastSuccess("Company created successfully!");
            fetchAllCompanies();
            return true;
        } else {
            toastError(userResp.msg);
            return false;
        }
    }

    const validateInput = (values) => {
        const errors = {}
        if (!values.company_name) {
            errors.company_name = 'Company Name cannot be empty!'
        }

        if (!values.user_first_name) {
            errors.user_first_name = 'First Name cannot be empty!'
        }

        if (!values.user_last_name) {
            errors.user_last_name = 'Last Name cannot be empty!'
        }

        if (!values.user_contact_1) {
            errors.user_contact_1 = 'Contact Number #1 cannot be empty!'
        }

        if (!values.user_email) {
            errors.user_email = 'Email cannot be empty!'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.user_email)) {
            errors.user_email = 'Invalid email address'
        }

        if (!values.user_password) {
            errors.user_password = 'Password cannot be empty!'
        } else if (values.user_password.length < 5) {
            errors.user_password = 'Password must be greater than 5 characters.'
        }

        return errors;
    }

    const handleBlockCompany = async (company_id, e) => {

        let is_block = e.target.value == "" ? "" : e.target.value == "true" ? true : false
        console.log(is_block);
        let currentUser = getUser();
        let userResp = await blockCompany({
            company_id: company_id,
            user_id: currentUser.user_id,
            is_block: e.target.value == "" ? "" : is_block
        })
        if (userResp.status === 200) {
            toastSuccess("Company blocked successfully!");
            fetchAllCompanies();
            return true;
        }
        else {
            toastError(userResp.msg);
            return false;
        }

    }

    console.log(companyList)
    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">
                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Companies</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Companies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-fluid"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Products */}
                                            <div className="w-100">
                                                <div className="card-header align-items-center mb-2">
                                                    <div
                                                        className="card-toolbar flex-row-fluid justify-content-end gap-5"
                                                        onClick={() => setShowModal(true)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal"
                                                    >
                                                        <div className="btn btn-primary">+ Add Company</div>
                                                    </div>
                                                </div>

                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Manage Companies</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table
                                                                                        id="tbl_tickets"
                                                                                        className="table table-row-dashed table-row-gray-300 gy-7"
                                                                                    >
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                {/* <th className="p-0 pb-3 min-w-20px text-start">
                                                                                                    PRODUCT #
                                                                                                </th> */}
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Company Name
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Admin Email
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Admin First Name
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Admin Last Name
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Admin Contact No#1
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Created At
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Block company
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">

                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {companyList?.map((company, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={i}>
                                                                                                            {/* <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        // {i}
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td> */}
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        company.company_name
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        company.admin_email
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        company.admin_first_name
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        company.admin_last_name
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        company.admin_contact_1
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        formatDateString(company.created_at)
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 px-3">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        company.is_blocked === 1 ? <select class="form-select form-select-sm" onChange={(e) => { handleBlockCompany(company.company_id, e) }}>


                                                                                                                            <option value="true">Block Company</option>
                                                                                                                            <option value="false">Unblock Company</option>

                                                                                                                        </select> :
                                                                                                                            company.is_blocked === 0 ? <select class="form-select form-select-sm" onChange={(e) => { handleBlockCompany(company.company_id, e) }}>

                                                                                                                                <option value="" selected>select </option>
                                                                                                                                <option value="false">Unblock Company</option>
                                                                                                                                <option value="true">Block Company</option>

                                                                                                                            </select> :
                                                                                                                                <select class="form-select form-select-sm" onChange={(e) => { handleBlockCompany(company.company_id, e) }}>
                                                                                                                                    <option value="" selected>select </option>

                                                                                                                                    <option value="true">Block Company</option>
                                                                                                                                    <option value="false">Unblock Company</option>

                                                                                                                                </select>
                                                                                                                    }
                                                                                                                    {/* <select class="form-select form-select-sm" aria-label="Default select example" onChange={(e) => { handleBlockCompany(company.company_id, e) }}>
                                                                                                                        <option value="" selected>select </option>

                                                                                                                        <option value="true">Block Company</option>
                                                                                                                        <option value="false">Unblock Company</option>

                                                                                                                    </select> */}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <Link to={'/dashboard/add-user/' + company.company_id} state={company} className="menu-item">
                                                                                                                    <span className="">View Users</span>
                                                                                                                </Link>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {showModal ? (
                                            <ModalBox
                                                showModal={showModal}
                                                hideModal={() => setShowModal(false)}
                                                title="Add Company" className="add_company"
                                            >
                                                <Formik
                                                    initialValues={{
                                                        company_name: "",
                                                        user_first_name: "",
                                                        user_last_name: "",
                                                        user_contact_1: "",
                                                        user_contact_2: "",
                                                        user_address: "",
                                                        user_email: "",
                                                        user_password: "",
                                                        subscription_type: 1,
                                                    }}
                                                    validate={validateInput}
                                                    // validationSchema={addTaskSchema}
                                                    enableReinitialize
                                                    onSubmit={(values, { resetForm }) => {
                                                        setTimeout(() => {
                                                            if (!addNewCompany(values)) {
                                                                resetForm();
                                                            }
                                                        }, 500);
                                                        return false;
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                    }) => (
                                                        <div
                                                            className="d-flex flex-column flex-root"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <form onSubmit={handleSubmit}>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Subscription Type</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            {
                                                                                subscriptionTypes?.map((element, index) => {
                                                                                    return <div className={"col-lg-4 sub_type " + element.name.toLowerCase()}><label className="label" htmlFor={"stype" + index}>
                                                                                        <input className={"me-2 "} key={"stype_" + index}
                                                                                            type="radio" name={"subscriptionType"} id={"stype" + index} defaultChecked={values.subscription_type === element.subscription_type_id}
                                                                                            onChange={() => { values.subscription_type = element.subscription_type_id }} />{element.name}</label></div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {/* User Name */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Company Name</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="company_name"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.company_name}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.company_name && touched.company_name ? <div className="error">{errors.company_name}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-12 col-form-label fw-semibold fs-6 section_header">
                                                                            <span>Admin Details</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>First Name</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_first_name"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_first_name}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_first_name && touched.user_first_name ? <div className="error">{errors.user_first_name}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Last Name</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_last_name"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_last_name}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_last_name && touched.user_last_name ? <div className="error">{errors.user_last_name}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    {/* Contact No 1 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Address</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_address"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_address}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Contact No 1 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Primary No</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_contact_1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_contact_1}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_contact_1 && touched.user_contact_1 ? <div className="error">{errors.user_contact_1}</div> : null}
                                                                        </div>
                                                                    </div>

                                                                    {/* Contact No 2 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>ALTERNATE NO</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_contact_2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_contact_2}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />

                                                                        </div>
                                                                    </div>

                                                                    {/* Username */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Email</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_email"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_email}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_email && touched.user_email ? <div className="error">{errors.user_email}</div> : null}
                                                                        </div>
                                                                    </div>

                                                                    {/* Password */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Password</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="password"
                                                                                placeholder=""
                                                                                name="user_password"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_password}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_email && touched.user_password ? <div className="error">{errors.user_password}</div> : null}
                                                                        </div>
                                                                    </div>

                                                                    <div class="text-center">
                                                                        <button
                                                                            type="reset"
                                                                            id="kt_modal_new_target_cancel"
                                                                            class="btn btn-light me-3"
                                                                            onClick={() => setShowModal(false)}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            id="kt_modal_new_target_submit"
                                                                            class="btn btn-primary">
                                                                            <span class="indicator-label">
                                                                                Submit
                                                                            </span>
                                                                            <span class="indicator-progress">
                                                                                Please wait...
                                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Formik>

                                            </ModalBox>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCompany;