import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

import { getMyOrders, getSellerOrders, updateOrder as updateOrderService } from "../../services/OrderServices";
import { getAllVendorProducts } from "../../services/ProductServices";
import { getAllVendorsGroup } from "../../services/VendorServices";
import { getAllUsersGroup, getUserProfile } from "../../services/UserServices";
import ModalBox from "../../shared/Modal";
import { getUser } from '../../shared/UserUtil';
import { OrderReviewStatus, RecordType, UserMode, UserType } from "../../shared/Constants";
import { getProductStageClass, getProductStageName } from "../../shared/ProductUtil";

const OrderDetails = (params) => {
    const [showModal, setShowModal] = useState(false);
    const [orderProducts, setOrderProducts] = useState();
    const [vendors, setVendors] = useState();
    const [currentUser, setCurrentUser] = useState(getUser());
    const [activePage, setActivePage] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [currentOrder, setCurrentOrder] = useState(null)
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrderDetails();
        let tmp_user = getUser();
        console.log(tmp_user)
    }, []);

    useEffect(() => {
    }, [activePage]);
   
    const fetchOrderDetails = async () => {
        let ticket = location.state.data;
        console.log(ticket);
        let tmp_user = getUser();
        console.log("check temp user",tmp_user)
        let payload = "";
        setCurrentUser(tmp_user);

        let resp = null;
        if (tmp_user.mode === 1) { // Admin, SuperAdmin, Buyer
            payload = { group_by_order_id: 0, order_id: ticket.order_id, page: activePage };
            console.log("check1");
            // console.log(payload)
            resp = await getMyOrders(payload);
        } else { // Seller / Vendor
            payload = { group_by_order_id: 0, order_id: ticket.order_id, vendor_id: tmp_user.user_id, page: activePage };
            console.log("check2");
            resp = await getSellerOrders(payload);
        }


        if (resp && resp.data) {
            if (resp.status === 200) {
                setOrderProducts(resp.data);
                setTotalProducts(resp.total);
            } else {
                console.log('500!');
            }
        }
    }

    const handlePageChange = async (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        let tmp_user = getUser();
        let ticket = location.state.data;
        let payload = "";
        setCurrentUser(tmp_user);

        let resp = null;
        if (tmp_user.mode === 1) { // Admin, SuperAdmin, Buyer
            payload = { group_by_order_id: 0, order_id: ticket.order_id, page: activePage };
            resp = await getMyOrders(payload);
        } else { // Seller / Vendor
            payload = { group_by_order_id: 0, order_id: ticket.order_id, vendor_id: tmp_user.user_id, page: activePage };
            // resp = await getSellerOrders(payload);
        }
        if (resp && resp.data) {
            if (resp.status === 200) {
                setOrderProducts(resp.data);
                setTotalProducts(resp.total);
            } else {
                console.log('500!');
            }
        }
    };

    const showVendors = async (item) => {
        setCurrentOrder(item);
        let vendorResponse = await getUserProfile({ user_id: item.order_vendor_id });
        console.log(vendorResponse);
        setVendors(vendorResponse.data);
        setShowModal(true);
    }

    const getOrderStatus = (order) => {
        return (currentUser.user_type <= UserType.Admin)
         || (currentUser.user_type === UserType.User && currentUser.mode === UserMode.Buyer 
        && ((order?.order_vendor_approval === OrderReviewStatus.SellerApproved || order?.order_vendor_approval === OrderReviewStatus.Completed) 
        && order?.order_admin_approval === OrderReviewStatus.AdminAccepted))
    }

    return (
        <>
            <div className="app-main flex-column flex-row-fluid mt-8" id="kt_app_main">
                <div className="d-flex flex-column flex-column-fluid">
                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Order Details</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard" className="text-muted text-hover-primary">Orders</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Order Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-xxl"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Products */}
                                            <div className="w-100">
                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Order Details - #{location.state.data.order_id}</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table id="tbl_tickets" className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">#</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">TITLE/TYPE</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">BRAND</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">MODEL</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">QTY</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">PRICE</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">NEW/USED</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">DESCRIPTION</th>
                                                                                                {/* <th className="p-0 pb-3 min-w-100px text-start">Category</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">Subcategory</th> */}
                                                                                                {/* {
                                                                                                ((location?.state?.data?.order_admin_approval) === 2) ? 
                                                                                                    <th className="p-0 pb-3 min-w-120px text-start">VENDORS</th>
                                                                                                :
                                                                                                    null
                                                                                                } */}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                orderProducts?.map((o, i) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr key={i}>
                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{i + 1}</span>
                                                                                                                </td>

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.record_type === RecordType.Product? o.product_type:o.title}</span>
                                                                                                                </td>

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.product_brand}</span>
                                                                                                                </td>

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.product_model}</span>
                                                                                                                </td>

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.order_product_qty}</span>
                                                                                                                </td>
                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.order_product_price}</span>
                                                                                                                </td>
                                                                                                                <td className="text-start p-0">
                                                                                                                <span className={`badge py-3 px-4 fs-7 ${getProductStageClass(o.product_isnew)} `}>{getProductStageName(o.product_isnew)} </span>
                                                                                                                </td>
                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.product_description}</span>
                                                                                                                </td>
                                                                                                                {/* <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.category_name}</span>
                                                                                                                </td> 
                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.subcategory_name}</span>
                                                                                                                </td>                                                                                                                  */}
                                                                                                                {
                                                                                                                    getOrderStatus(o) ?
                                                                                                                        <td className="text-start p-0" >
                                                                                                                            <button className='btn btn-primary' onClick={() => { showVendors(o) }}>Vendors</button>
                                                                                                                        </td>
                                                                                                                        :
                                                                                                                        null
                                                                                                                }
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>


                                                                                <Pagination
                                                                                    activePage={activePage}
                                                                                    itemsCountPerPage={15}
                                                                                    totalItemsCount={totalProducts ? totalProducts : 0}
                                                                                    pageRangeDisplayed={15}
                                                                                    onChange={handlePageChange}
                                                                                    itemClass="page-item"
                                                                                    linkClass="page-link"
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <ModalBox
                                            className="order_detail"
                                            showModal={showModal}
                                            hideModal={() => setShowModal(false)}
                                            title={"Vendors"}>
                                            {
                                                <>
                                                    <div className="table-responsive">
                                                        <table id="tbl_tickets" className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                                            <thead>
                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    {/* 
                                                                    <th className="p-0 pb-3 min-w-100px text-start">TYPE</th>
                                                                    <th className="p-0 pb-3 min-w-100px text-start">BRAND</th>
                                                                    <th className="p-0 pb-3 min-w-100px text-start">MODEL</th>
                                                                    <th className="p-0 pb-3 min-w-100px text-start">NEW/USED</th>
                                                                    <th className="p-0 pb-3 min-w-100px text-start">QTY</th>
                                                                    <th className="p-0 pb-3 min-w-100px text-start">PRICE</th> */}

                                                                    {
                                                                        getOrderStatus(currentOrder) ?
                                                                            <>
                                                                                <th className="p-0 pb-3 min-w-100px text-start">#</th>
                                                                                <th className="p-0 pb-3 min-w-100px text-start">VENDOR NAME</th>
                                                                                <th className="p-0 pb-3 min-w-100px text-start">ADDRESS</th>
                                                                                <th className="p-0 pb-3 min-w-100px text-start">PRIMARY NO</th>
                                                                                <th className="p-0 pb-3 min-w-100px text-start">ALTERNATE NO</th>
                                                                                <th className="p-0 pb-3 min-w-100px text-start">EMAIL</th>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    vendors?.map((v, i) => {
                                                                        return (
                                                                            <>
                                                                                <tr key={i}>
                                                                                    <td className="text-start p-0" >
                                                                                        <span className="text-gray-600 fs-7">{i + 1}</span>
                                                                                    </td>
                                                                                    {/* 
                                                                                    <td className="text-start p-0" >
                                                                                        <span className="text-gray-600 fs-7">{v.product_details.product_type}</span>
                                                                                    </td>

                                                                                    <td className="text-start p-0" >
                                                                                        <span className="text-gray-600 fs-7">{v.product_details.product_brand}</span>
                                                                                    </td>

                                                                                    <td className="text-start p-0" >
                                                                                        <span className="text-gray-600 fs-7">{v.product_details.product_model}</span>
                                                                                    </td>

                                                                                    <td className="text-start p-0">
                                                                                        <span className={`badge py-3 px-4 fs-7 ${v.product_details.product_isnew === 1 ? 'badge-light-primary' : null} ${v.product_details.product_isnew === 2 ? 'badge-light-success' : null} `}>{v.product_details.product_isnew === 1 ? 'New' : null} {v.product_details.product_isnew === 2 ? 'Used' : null} </span>
                                                                                    </td>

                                                                                    <td className="text-start p-0" >
                                                                                        <span className="text-gray-600 fs-7">{v.product_details.product_qty}</span>
                                                                                    </td>

                                                                                    <td className="text-start p-0" >
                                                                                        <span className="text-gray-600 fs-7">{v.product_details.product_price}</span>
                                                                                    </td> */}
                                                                                    {getOrderStatus(currentOrder) ?

                                                                                        <>
                                                                                            <td className="text-start p-0" >
                                                                                                <span className="text-gray-600 fs-7">{v.user_fname + ' ' + v.user_lname}</span>
                                                                                            </td>

                                                                                            <td className="text-start p-0" >
                                                                                                <span className="text-gray-600 fs-7">{v.user_address}</span>
                                                                                            </td>

                                                                                            <td className="text-start p-0" >
                                                                                                <span className="text-gray-600 fs-7">{v.user_contact_1}</span>
                                                                                            </td>

                                                                                            <td className="text-start p-0" >
                                                                                                <span className="text-gray-600 fs-7">{v.user_contact_2}</span>
                                                                                            </td>

                                                                                            <td className="text-start p-0" >
                                                                                                <span className="text-gray-600 fs-7">{v.user_email}</span>
                                                                                            </td>
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }

                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            }
                                        </ModalBox>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails;