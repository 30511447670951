import axios from "axios";
import { getAllCustomersURL, addCustomerURL } from "../constants/ApiEndPoints";

export const getAllCustomers = async (payload) => {
  // console.log('service: ', payload);
  try {
    const response = await axios.post(`${getAllCustomersURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const addCustomer = async (payload) => {
  try {
    const response = await axios.post(`${addCustomerURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

