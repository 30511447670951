export const MessageTypes = {
    Success: 1,
    Error: 2,
    Warning: 3,
    Info: 4
};

export const OrderReviewStatus = {
    Pending: 1,
    AdminAccepted: 2,
    AdminRejected: 3,
    SellerApproved: 4,
    SellerRejected: 5,
    Completed: 6,
    ReOrder: 7
};

export const UserMode = {
    Buyer: 1,
    Seller: 2
}

export const UserType = {
    SuperAdmin: 0,
    MasterAdmin: 1,
    Admin: 2,
    User: 3,
    SpecialUser: 4
}

export const ComponentPages = {
    DashboardOrder: 1,
    ViewProduct: 2,
    User: 3,
    Company: 4,
    ManageProduct: 5,
    Enquiries: 6, 
    Settings: 7,
    Category: 8
}

export const CommentType = {
    Enquiry: 1,
    Complaint: 2
}

export const RecordType = {
    Product: 1,
    Service: 2
}

export const CategoryType={
    Product: 1,
    Service: 2
}


