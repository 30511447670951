import axios from "axios";
import { saveEnquiryURL, getEnquiryURL } from "../constants/ApiEndPoints";


export const saveEnquiry = async (payload) => {
    try {
      const response = await axios.post(`${saveEnquiryURL}`, payload);
      if (response.status === 200) {
        const data = response.data;
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const getEnquiries = async (payload) => {
    try {
      const response = await axios.post(`${getEnquiryURL}`, payload);
      if (response.status === 200) {
        const data = response.data;
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }