export const getProductStageClass = (product_isnew) => {
    switch (product_isnew) {
        case 1:
            return 'badge-light-primary';
        case 2:
            return 'badge-light-success';
        case 3:
            return 'badge-light-warning';
        default:
            return '';
    }
}

export const getProductStageName = (product_isnew) => {
    switch (product_isnew) {
        case 1:
            return 'New';
        case 2:
            return 'Used';
        case 3:
            return 'Refurbished';
        default:
            return '';
    }
}

export const getProductStageByName = (product_isnew) => {
    if(!product_isnew){
        return "";
    }
    switch (product_isnew.toLowerCase()) {
        case 'n':
        case 'new':
            return 'New';
        case 'u':
        case 'used':
            return 'Used';
        case 'r':
        case 'refurbished':
            return 'Refurbished';
        default:
            return '';
    }
}

export const getProductStageClassByName = (product_isnew) => {
    console.log("test"+product_isnew+"test")
    switch (product_isnew) {
        case 'n':
        case 'new':
            return 'badge-light-primary';
        case 'u':
        case 'used':
            return 'badge-light-success';
        case 'r':
        case 'refurbished':
            return 'badge-light-warning';
        default:
            return '';
    }
}
