export const formatDate = (newDate, format) => {
  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const d = newDate
  const year = d.getFullYear()
  const date = d.getDate()
  const monthIndex = d.getMonth()
  const monthName = months[d.getMonth()]
  const dayName = days[d.getDay()] // Thu
  const formatted = `${dayName}, ${date} ${monthName} ${year}`
  if (format === "default") {
    return `${monthIndex+1}-${date}-${year}`;
  }
  return formatted.toString()
}


export const formatDateString = (newDate) => {
  if (newDate) {
    var dates = newDate.split('T');
    return dates[0] + ' ' + dates[1].substring(0, 5);
  } else {
    return "";
  }
}