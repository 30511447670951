import Modal from 'react-bootstrap/Modal';

const ModalBox = (props) => {

	return (
		<>
			<Modal show={props.showModal} animation={true} className={props.className}>
				<Modal.Header closeButton={props.closeButton?props.closeButton:true} onClick={props.hideModal}>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.children}
				</Modal.Body>
				{/* <Modal.Footer>
					<Button variant="secondary" >
						Close
					</Button>
					<Button variant="primary" >
						Save Changes
					</Button>
				</Modal.Footer> */}
			</Modal>
		</>
	)
}

export default ModalBox;