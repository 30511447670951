import React from 'react';
import BackgroundImg1 from "../../assets/media/auth/bg1.jpg";
import BackgroundImg2 from "../../assets/media/auth/404-error.png";
import BackgroundImg3 from "../../assets/media/auth/bg1.jpg";

const PageNotFound = () => {

    return (
        <>
            <div className="d-flex flex-column flex-root" id="kt_app_root"  style={{
              background: `url(${BackgroundImg1})`,
            }} >
                {/* // <style>body {backgroundImage: url('assets/media/auth/bg1.jpg'); } [data-theme="dark"] body {background - image: url('assets/media/auth/bg1-dark.jpg'); }</style> */}
                <div class="d-flex flex-column flex-center flex-column-fluid">
                    <div class="d-flex flex-column flex-center text-center p-10">
                        <div class="card card-flush w-lg-650px py-5">
                            <div class="card-body py-15 py-lg-20">
                                <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
                                <div class="fw-semibold fs-6 text-gray-500 mb-7">We can't find that page.</div>
                                <div class="mb-3">
                                    <img src={require("../../assets/media/auth/404-error.png")} class="mw-100 mh-300px theme-light-show" alt="" />
                                    <img src={require("../../assets/media/auth/404-error-dark.png")} class="mw-100 mh-300px theme-dark-show" alt="" />
                                </div>
                                <div class="mb-0">
                                    <a href="/login" class="btn btn-sm btn-primary">Return Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;