import React, { useEffect, useState, useCallback, useRef } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { encrypt } from 'n-krypta';
import Carousel from 'react-bootstrap/Carousel';

import Loader from '../../shared/Loader';
import { loginSchema } from "../../validations/validations";

import { loginWithPassword, verify2ndPassword } from "../../services/LoginServices";
import { toastError } from '../../shared/Toast';
import logo from "../../assets/media/auth/cmda-logo-3.png";
import AuthScreensImg from "../../assets/media/misc/auth-screens.png";

import bgImage from "../../../src/assets/media/misc/auth-bg.png";
import ModalBox from "../../shared/Modal";
import { getAllActiveAds } from "../../services/AdsService";
// import {
//     GoogleReCaptchaProvider,
//     GoogleReCaptcha
// } from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha'
import { APIBaseURL } from "../../constants/ApiEndPoints";

const salt = '$2a$10$CwTycUXWue0Thq9StjUM0u';
//console.log(APIBaseURL, salt);

const Login = () => {
  // const getCode = () => {
  //     return ("" + Math.random() + "").substring(2, 6);
  // }
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  //const [code, setCode] = useState(getCode());
  const [show2ndPassword, setShow2ndPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [token, setToken] = useState();
  const formikRef = useRef(null);
  const captchaRef = useRef(null);
  // const [adsList, setAdsList] = useState([]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
    //captchaRef.current.reset();
    // fetAllAds();
  }, []);

  // const fetAllAds = async () => {
  //   const adsList = await getAllActiveAds({});
  //   console.log(adsList);
  //   //setParentCategoryList(categoryList.data.filter(f => f.parent_category_id === null));
  //   setAdsList(adsList.data);
  // };

  const validate2ndStepInput = (values) => {
    const errors = {};

    if (!values.second_password) {
      errors.second_password = "Second password cannot be empty!";
    }
    return errors;
  };

  const verify2ndStep = async (values) => {
    values.second_password = encrypt(values.second_password, salt);
    let loginResp = await verify2ndPassword({
      user_username: email,
      second_password: values.second_password,
    });
    console.log(loginResp);
    if (loginResp && loginResp.status === 200) {
      navigate("/dashboard/orders", { state: loginResp.user });
    } else {
      toastError("Invalid Password");
      return false;
    }
  };

  const loginUsingPassword = async (values) => {
    if (values) {
      //const salt = await bcrypt.genSalt(10);
      values.password = encrypt(values.password, salt);
      let loginResp = await loginWithPassword({
        user_username: values.email,
        user_password: values.password,
      });
      console.log("login check",loginResp);
      if (loginResp && loginResp.status === 200) {
        if (loginResp.user.user_type === 3) {
          navigate('/user/switch-mode', { state: loginResp });
          //navigate('/dashboard/view-products', { state: loginResp });
        } else if (loginResp.user.user_type === 0) {
          setEmail(values.email);
          setShow2ndPassword(true);
        } else {
          navigate('/dashboard/orders', { state: loginResp.user });
        }
        //toastSuccess('Login Successful')
      } else {
        toastError(loginResp.msg);
        return false;
      }
    }
  };

  const validateInput = (values) => {
    const errors = {};
    // if (values.code != code) {
    //     errors.code = 'Code should be matched'
    // }

    if (!captchaRef.current.getValue()) {
      errors.token = "Captcha needs to verify";
    }
    console.log("validateinput", captchaRef.current.getValue());
    return errors;
  };

  const showPassword = (e) => {
    setIsShowPassword(true);
  };

  const hidePassword = (e) => {
    setIsShowPassword(false);
  };

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column flex-root" id="kt_app_root">
          <div
            className="d-flex flex-column flex-lg-row flex-column-fluid "
            style={{ height: "100vh" }}
          >
            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
              <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                <div className="w-lg-500px p-10">

                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                  </div>

                  <div>
                    <Formik
                      innerRef={formikRef}
                      initialValues={{
                        email: "",
                        password: "",
                        code: "",
                        token: "",
                      }}
                      validationSchema={loginSchema}
                      enableReinitialize
                      validate={validateInput}
                      onSubmit={(values, { setSubmitting, resetForm }) => {

                        setTimeout(() => {
                          if (loginUsingPassword(values) === false) {
                            setSubmitting(false);
                            values.password = "";
                            values.code = "";
                            //setCode(getCode());
                          } else {
                            setSubmitting(false);
                            resetForm();
                            //setCode(getCode());
                          }
                        }, 500);
                        return false;
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="fv-row mb-8">
                            <input
                              type="text"
                              placeholder="Username"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              autoComplete="off"
                              className="form-control bg-transparent"
                            />
                            {errors.email && touched.email && errors.email ? (
                              <div className="error-red">
                                {errors.email &&
                                  touched.email &&
                                  errors.email}
                              </div>
                            ) : null}
                          </div>

                          <div className="fv-row mb-3 login_password_section">
                            <div className="input-group mb-3">
                              <input
                                type={
                                  isShowPassword === false
                                    ? "password"
                                    : "text"
                                }
                                placeholder="Password"
                                name="password"
                                autoComplete="off"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                className="form-control bg-transparent"
                              />
                              {!isShowPassword ? (
                                <span className="show-password"><i
                                className="fa fa-eye show_password"
                                onClick={(e) => showPassword(e)}
                                title="Show password"
                              /></span>
                              ) : null}
                              {isShowPassword ? (
                                <span className="hide-password"><i
                                className="fa fa-eye-slash show_password"
                                onClick={(e) => hidePassword(e)}
                                title="Hide password"
                              /></span>
                              ) : null}
                            </div>
                            {errors.password &&
                              touched.password &&
                              errors.password ? (
                              <div className="error-red">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="pt-4">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                              name="token"
                              ref={captchaRef}
                              onChange={onChange}
                              style={{ width: "200" }}
                            />
                            {errors.token && touched.token && errors.token ? (
                              <div className="error-red">
                                {errors.token &&
                                  touched.token &&
                                  errors.token}
                              </div>
                            ) : null}
                          </div>
                          <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                            <div></div>
                          </div>
                          <div className="d-grid mb-10">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              <span className="indicator-label">Sign In</span>
                              <span className="indicator-progress">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                    {/* //Commented 
                    <div className="flex flex-center">
                      <Carousel>
                        {adsList?.map((element, index) => {
                          return (
                            <Carousel.Item interval={1000}>
                              <Carousel.Caption>
                                <h3>Discount: {element.discount}%</h3>
                                <h3>Price: {element.price}</h3>
                              </Carousel.Caption>
                              <img
                                className="d-block ads_image_big"
                                src={
                                  APIBaseURL +
                                  "/web/images?image=" +
                                  element.filepath
                                }
                                alt={element.price}
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div> */}
                  </div>

                  {show2ndPassword ? (
                    <ModalBox
                      showModal={show2ndPassword}
                      title={"2nd Verification Step"}
                      hideModal={() => {
                        setShow2ndPassword(false);
                      }}
                    >
                      <Formik
                        initialValues={{
                          second_password: "",
                        }}
                        validate={validate2ndStepInput}
                        enableReinitialize
                        onSubmit={(values, { resetForm }) => {
                          setTimeout(() => {
                            console.log("values : ", values);
                            if (verify2ndStep(values) === true) {
                              resetForm();
                            }
                          }, 500);
                          return false;
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <div
                            className="d-flex flex-column flex-root"
                            id="kt_app_root_update_product"
                          >
                            <div className="w-md">
                              <form onSubmit={handleSubmit}>
                                <div className="row mb-6">
                                  <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                    <span>Second Password</span>
                                  </label>
                                  <div className="col-lg-8 fv-row password_section">
                                    <input
                                      type={
                                        !isShowPassword ? "password" : "text"
                                      }
                                      placeholder=""
                                      name="second_password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.second_password}
                                      autoComplete="off"
                                      className="form-control bg-transparent"
                                    />
                                    {!isShowPassword ? (
                                      <i
                                        className="fa fa-eye show_password"
                                        onClick={(e) => showPassword(e)}
                                        title="Show password"
                                      />
                                    ) : null}
                                    {isShowPassword ? (
                                      <i
                                        className="fa fa-eye-slash show_password"
                                        onClick={(e) => hidePassword(e)}
                                        title="Hide password"
                                      />
                                    ) : null}
                                    {errors.second_password &&
                                      touched.second_password ? (
                                      <div className="error">
                                        {errors.second_password}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="text-center">
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    id="kt_modal_new_target_submit"
                                    class="btn btn-primary"
                                  >
                                    <span class="indicator-label">
                                      Submit
                                    </span>
                                    <span class="indicator-progress">
                                      Please wait...
                                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </ModalBox>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                <a href="#">
                  <img alt="Logo" src={logo} style={{ 'height': '150px' }} />
                </a>
                {/* <img
                  className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10"
                  src="assets/media/misc/auth-screens.png"
                  alt=""
                /> */}
                <img
                  className="d-none d-lg-block mx-auto w-200px w-md-50 w-xl-300px mb-7 mb-lg-11"
                  src={AuthScreensImg}
                  alt=""
                />
                <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
                  CMDA MAHA TRADE
                </h1>
                <div className="d-none d-lg-block text-white fs-base text-center px-4">
                  CMDA is the premier association of IT dealers and resellers in
                  Pune, India,
                  <br /> It strives to achieve common goals and build values for
                  its members. <br />
                  While working for the betterment of IT trading community, CMDA
                  has also contributed towards creating IT awareness within
                  consumers.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;