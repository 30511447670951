import axios from "axios";
import { getAllAdsURL, updateAdURL, saveAdURL, deleteAdURL, getAllActiveAdsURL } from "../constants/ApiEndPoints";

export const getAllAds = async () => {
    try {
      const response = await axios.get(`${getAllAdsURL}`);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const getAllActiveAds = async () => {
    try {
      const response = await axios.get(`${getAllActiveAdsURL}`);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const updateAd = async (ads) => {
    try {
      const response = await axios.put(`${updateAdURL}`, ads);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const saveAd = async (ads) => {
    try {
      const response = await axios.post(`${saveAdURL}`,ads);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const deleteAd = async (ads) => {
    try {
      const response = await axios.put(`${deleteAdURL}`, ads);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }