import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    email: Yup.string()
        // .email("Email is invalid")
        .required("Invalid Username"),
    password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Invalid Password")
})

export const addTaskSchema = Yup.object().shape({
    task_type: Yup.string()
        .required("Invalid task type"),
    task_particulars: Yup.string()
        .min(6, "Particulars must be at least 6 characters")
        .required("Invalid Task Particulars"),

})