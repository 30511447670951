import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loader from '../../shared/Loader';
import { getUserData } from "../../shared/UserUtil";
import ModalBox from "../../shared/Modal";
import ChangePassword from "./ChangePassword";
import { toastSuccess, toastError } from '../../shared/Toast';
import $ from 'jquery';
const buyerbanner = require('../../assets/images/switch/buyer-banner.png');
const buyerlogo = require('../../assets/images/switch/buyer-logo.png');
const sellerbanner = require('../../assets/images/switch/seller-banner.png');
const sellerlogo = require('../../assets/images/switch/seller-logo.png');
const inputpath = require('../../assets/images/switch/text-path.png');
const buyerpocketCut = require('../../assets/images/switch/buyer-pocketcut.png');
const sellerpocketCut = require('../../assets/images/switch/seller-packetcut.png');
const buyerarrowdown = require('../../assets/images/switch/buyer-downarrow.png');
const sellerarrowdown = require('../../assets/images/switch/seller-downarrow.png');
const arrowbox = require('../../assets/images/switch/white-arrowbox.png');
const bubbletop = require('../../assets/images/switch/bubble1.png');
const bubblebottom = require('../../assets/images/switch/bubble2.png');


const SwitchMode = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(getUserData());
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        setIsLoading(false);
        setCurrentUser(getUserData());
        if (currentUser === null) {
            navigate('/login');
        } else {
            console.log(currentUser);
            setShowChangePasswordModal(!currentUser.user.is_password_changed);
        }
    }, []);

    const handleSwitchMode = (mode) => {
        let userData = getUserData();
        userData.user.mode = mode;
        sessionStorage.setItem("user_data", JSON.stringify(userData));

        if (userData.user.mode === 1) {
            $('#kt_app_sidebar').removeClass('seller').addClass('buyer');
            navigate('/dashboard/view-products');
        }
        else {
            $('#kt_app_sidebar').removeClass('buyer').addClass('seller');
            navigate('/dashboard/add-product');
        }
        // window.location.reload()
    }

    const handleChangePasswordSuccess = () => {
        let userData = getUserData();
        userData.user.is_password_changed = true;
        sessionStorage.setItem("user_data", JSON.stringify(userData));
        setShowChangePasswordModal(false);
        toastSuccess("Password Updated Successfully");
    }

    const handleChangePasswordFailure = (message) => {
        //setShowChangePasswordModal(false);
        toastError(message);
    }

    return (
        <>
            {
                isLoading ? <Loader /> :

                    <div
                        className="app-main flex-column flex-row-fluid mt-8"
                        id="kt_app_main"
                    >
                        <div className="d-flex flex-column flex-column-fluid">

                            {/* Breadcrumb Start*/}
                            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Switch Mode</h1>
                                        {/* <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                            <li className="breadcrumb-item text-muted">
                                                <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                            </li>
                                            <li className="breadcrumb-item text-muted">Switch Mode</li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            {/* Breadcrumb End */}

                            <div id="kt_app_content" className="app-content flex-column-fluid ">
                                <div id="kt_app_content_container" className="app-container container-xxl">
                                    {/* <div className="card"> */}
                                    {/* <div className="card-body"> */}
                                    <div className="switch-outerCard ">
                                        <div className="switch-innerCard card">
                                            <div className="row">
                                                <div className="buyer-switch col-md-6" onClick={() => handleSwitchMode(1)}>
                                                    <div className="col-md-5  d-flex justify-content-center section-division">
                                                        <div className="position-relative">
                                                            <img src={buyerbanner} className="buyer-bannerImg" />
                                                            <img src={buyerlogo} className="buyer-logoImg" />
                                                            <div className="buyer-inputpath">
                                                                <div className="position-relative d-flex justify-content-center">
                                                                    <img src={inputpath} />
                                                                    <img src={buyerpocketCut} className="buyer-packet" />
                                                                    <p className="buyer-Title">Buyer</p>
                                                                    <img src={arrowbox} className="buyer-arrowbox" />
                                                                    <img src={buyerarrowdown} className="buyer-arrowdown1 arrow-down" />
                                                                    <img src={buyerarrowdown} className="buyer-arrowdown2 arrow-down" />
                                                                </div>
                                                            </div>
                                                            <img src={bubbletop} className="buyer-bubbletop bubbleImg" />
                                                            <img src={bubblebottom} className="buyer-bubblebottom bubbleImg" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="seller-switch mt-3 mt-md-0 col-md-6" onClick={() => handleSwitchMode(2)}>
                                                    <div className="col-md-5  d-flex justify-content-center section-division">
                                                        <div className="position-relative">
                                                            <img src={sellerbanner} className="seller-bannerImg" />
                                                            <img src={sellerlogo} className="seller-logoImg" />
                                                            <div className="seller-inputpath">
                                                            <div className="position-relative d-flex justify-content-center">
                                                                    <img src={inputpath} />
                                                                    <img src={sellerpocketCut} className="buyer-packet" />
                                                                    <p className="buyer-Title">Seller</p>
                                                                    <img src={arrowbox} className="buyer-arrowbox" />
                                                                    <img src={sellerarrowdown} className="buyer-arrowdown1 arrow-down" />
                                                                    <img src={sellerarrowdown} className="buyer-arrowdown2 arrow-down" />
                                                                </div>
                                                           
                                                            </div>
                                                            <img src={bubbletop} className="seller-bubbletop bubbleImg" />
                                                            <img src={bubblebottom} className="seller-bubblebottom bubbleImg" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <button className="btn btn-success btn_large me-5" onClick={() => handleSwitchMode(1)} >Logging as Buyer</button> */}
                                            {/* <button className="btn btn-warning btn_large" onClick={() => handleSwitchMode(2)}>Logging as Seller</button> */}
                                        </div>
                                    </div>
                                    {/* </div>
                                    </div> */}
                                    {/* hideModal={() => setShowChangePasswordModal(false)} */}
                                    <ModalBox closeButton={false}
                                        showModal={showChangePasswordModal}
                                        title="Change Password">
                                        <ChangePassword user_id={currentUser.user ? currentUser.user.user_id : ''} handleFailure={(msg) => handleChangePasswordFailure(msg)} handleSuccess={() => handleChangePasswordSuccess()} hideModal={() => setShowChangePasswordModal(false)}></ChangePassword>
                                    </ModalBox>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
export default SwitchMode;