import React from "react";
import ModalBox from "../../shared/Modal";

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {

    return (
        <ModalBox
            showModal={open}
            title="Session Timeout"
        >
            <div className="row">
                <div className="row mb-6">
                    <label className="col-lg-12 col-form-label fw-semibold fs-6">The current session is about to expire in{" "}
                        <span>{countdown}</span> seconds.</label>
                </div>
                <div className="text-center">
                    <button
                        class="btn btn-danger me-3"
                        onClick={onLogout}
                        variant="contained"
                    >
                        Logout
                    </button>
                    <button
                        class="btn btn-success me-3"
                        onClick={onContinue}
                    >
                        Continue Session
                    </button>
                </div>
            </div>
        </ModalBox>
    );
}


export default SessionTimeoutDialog;