import React, { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import $ from 'jquery';

import { addUser as insertUser, getAllUsers, deleteUser as deleteUserById, uploadUsers, downloadUsers, updateBlocked, updateUser } from "../../services/UserServices";
import ModalBox from "../../shared/Modal";
import { toastSuccess, toastError } from '../../shared/Toast';
import CSVParse from "../../components/CSVParse";
import { formatDateString } from "../../shared/DateUtil";
import ChangePassword from "./ChangePassword";
import ChangeCompany from "./ChangeCompany";
import { getUser } from "../../shared/UserUtil";
import { Something_Went_Wrong } from "../../shared/MessageUtil";
import { UserType } from "../../shared/Constants";

const AddUser = () => {
    let { companyId } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [showModifyModal, setShowModifyModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [uploadedUsers, setUploadedUsers] = useState([]);
    const [childRefreshFunction, setChildRefreshFunction] = useState(null);
    let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const location = useLocation();
    const [currentUser, setCurrentUser] = useState(getUser());
    const [showBlockModal, setShowBlockModal] = useState(false);
    const [userBlockedDialogTitle, setUserBlockedDialogTitle] = useState('');
    const [dialogTitle, setDialogTitle] = useState("Add User");

    useEffect(() => {
        fetchAllUsers();
        console.log(location.state)
    }, []);

    const addNewUser = async (values) => {
        let userResp = {};
        if (values.user_id > 0) {
            userResp = await updateUser({
                user_first_name: values.user_first_name,
                user_last_name: values.user_last_name,
                user_address: values.user_address,
                user_type: values.user_type,
                user_contact_1: values.user_contact_1,
                user_contact_2: values.user_contact_2,
                user_email: values.user_email,
                user_id: values.user_id,
                // user_gst:values.user_gst
            });
        } else {
            userResp = await insertUser({
                user_first_name: values.user_first_name,
                user_last_name: values.user_last_name,
                user_address: values.user_address,
                user_type: 3,
                user_contact_1: values.user_contact_1,
                user_contact_2: values.user_contact_2,
                user_email: values.user_email,
                user_password: values.user_password,
                company_id: companyId ? companyId : current_user_data.user.company_id
            });
        }

        if (userResp.status === 200) {
            setShowModal(false);
            toastSuccess(`User ${values.user_id > 0 ? 'Updated' : 'Created'} Successfully!`);
            fetchAllUsers();
            return true;
        } else {
            toastError(userResp.msg || userResp.message);
            return false;
        }
    }

    const deleteUser = async (params) => {
        let resp = await deleteUserById({ user_id: params.user_id, is_deleted: 1, admin_id: currentUser.user_id });
        if (resp.status === 200) {
            fetchAllUsers();
            toastSuccess("User deleted successfully!");
        } else {
            toastError(Something_Went_Wrong + '' + resp.msg);
        }
    }

    const editUser = async (user) => {
        setSelectedUser(user);
        setShowModal(true);
        setDialogTitle("Edit User");
    }

    const editCompany = async (user) => {
        setSelectedUser(user);
        setShowModifyModal(true);
        setDialogTitle("Edit Company");

    }

    const changePassword = async (params) => {
        console.log(params)
        setSelectedUser(params);
        setShowChangePasswordModal(true);
    }

    const fetchAllUsers = async () => {
        const resp_users = await getAllUsers({ company_id: companyId ? companyId : current_user_data.user.company_id });
        setUsers(resp_users.data);
    }

    const getUserTypeName = (user) => {
        switch (user.user_type) {
            case UserType.SuperAdmin:
                return { badge_color: 'badge-light-primary', name: 'Super Admin' };
            case UserType.Admin:
                return { badge_color: 'badge-light-success', name: 'Admin' };
            default:
                return { badge_color: 'badge-light-warning', name: 'User' };
        }
    }

    const handleUploadedUsers = async (data) => {
        let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));
        let uploadedArr = data.slice(0, -1);

        // console.log('handleUploadedUsers : ', uploadedArr);
        setUploadedUsers(uploadedArr);

        let user_list = [];
        let failed_users = [];
        await uploadedArr.forEach(e => {
            if (!e.Email || !e["First Name"] || !e["Last Name"] || !e["Address"] || !e["Contact Number1"] || !e["Contact Number2"]) {
                failed_users.push(e);
            } else {
                user_list.push({
                    created_by: current_user_data.user.user_id,
                    email: e.Email,
                    first_name: e["First Name"],
                    last_name: e["Last Name"],
                    address: e["Address"],
                    contact_no1: e["Contact Number1"],
                    contact_no2: e["Contact Number2"]
                });
            }
        });

        // console.log('users : ', user_list);
        toastSuccess("CSV Parsed Successfully!");
    }

    const submitUsers = async () => {
        if (uploadedUsers.length) {
            let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));
            let uploadedArr = uploadedUsers;
            let user_list = [];
            let failed_users = [];
            await uploadedArr.forEach(e => {
                if (!e.Email || !e["First Name"] || !e["Last Name"] || !e["Address"] || !e["Contact Number1"]) {
                    // if (!e.Email || !e["First Name"] || !e["Last Name"] || !e["Address"] || !e["Contact Number1"] || !e["Contact Number2"]) {
                    failed_users.push(e);
                } else {
                    user_list.push({
                        created_by: current_user_data.user.user_id,
                        email: e.Email,
                        first_name: e["First Name"],
                        last_name: e["Last Name"],
                        address: e["Address"],
                        contact_no1: e["Contact Number1"],
                        contact_no2: e["Contact Number2"]
                    });
                }
            });
            if (failed_users.length > 0) {
                toastError("User data has invalid data, cannot be uploaded. Please correct the data and try again.");
            } else {
                let response = await uploadUsers({ users: user_list, company_id: companyId ? companyId : current_user_data.user.company_id });
                console.log(response);
                if (response.status === 200) {
                    toastSuccess('Users Uploaded Successfully!');
                    setShowUploadModal(false);
                    setUploadedUsers([]);
                    fetchAllUsers();
                } else {
                    toastError(response.message);
                }
            }
        }
    }

    const handleDownload = async () => {
        await downloadUsers({ company_id: companyId ? companyId : current_user_data.user.company_id });
    }

    const validateInput = (values) => {
        const errors = {}

        // if (!values.user_type) {
        //     errors.user_type = 'Select User Type';
        // }

        if (!values.user_first_name) {
            errors.user_first_name = 'FirstName cannot be empty!'
        }

        if (!values.user_last_name) {
            errors.user_last_name = 'LastName cannot be empty!'
        }

        if (!values.user_contact_1) {
            errors.user_contact_1 = 'Contact Number #1 cannot be empty!'
        }

        if (!values.user_email) {
            errors.user_email = 'Email cannot be empty!'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.user_email)) {
            errors.user_email = 'Invalid email address'
        }

        // if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/i.test(values.user_gst)) {
        //     errors.user_gst = 'Invalid GST'
        // }
        if (values.user_id >= 0) {

        } else {
            if (!values.user_password) {
                errors.user_password = 'Password cannot be empty!'
            } else if (values.user_password.length < 5) {
                errors.user_password = 'Password must be greater than 5 characters.'
            }
        }

        return errors;
    }

    const handleChangeModifySuccess = (msg) => {
        if (msg.action === 'shiftUser' || msg.action === 'shiftUserAndRenameCompany') {
            setUsers(users.filter(user => user.user_id !== selectedUser.user_id))
        } else if (msg.action === 'renameUserCompany') {
            setUsers(users.map(user => ({ ...user, company_name: msg.company_name })))
        }
        console.log(msg)
        setShowModifyModal(false);
        toastSuccess("User Company Updated Successfully");
        // window.location.reload(false); //Needs to be changed later
    }

    const handleChangePasswordSuccess = () => {
        setShowChangePasswordModal(false);
        toastSuccess("Password Updated Successfully");
    }

    const handleChangePasswordFailure = (msg) => {
        //setShowModal(false);
        toastError(msg);
    }

    const showBlockedDialog = (event, user) => {
        setShowBlockModal(true);
        setSelectedUser(user);
        setUserBlockedDialogTitle(user.is_blocked ? 'Unblock' : 'Block')
    }

    const cancelBlocked = (event) => {
        $('#block_switch_' + selectedUser.user_id).prop('checked', !selectedUser.is_blocked);
        setSelectedUser(null);
        setShowBlockModal(false);
    }

    const handleBlocked = async (event) => {
        console.log(event)
        var is_user_blocked = !selectedUser.is_blocked;
        let response = await updateBlocked({ user_id: selectedUser.user_id, is_blocked: is_user_blocked ? 1 : 0 });
        if (response.status === 200) {
            toastSuccess(`The user ${is_user_blocked ? 'blocked' : 'unblocked'} successfully`);
            setShowBlockModal(false);
            setSelectedUser(null);
        }
        else {
            toastError("Something went wrong, Please contact Admin.");
            var user = selectedUser;
            user.is_blocked = !user.is_blocked;
            setSelectedUser(selectedUser)
        }
        //setShowBlockModal(false);
        //setSelectedUser(null);
    }

    const handleAddUser = () => {
        setShowModal(true);
        setDialogTitle("Add User");
        setSelectedUser(null);
    }
    console.log(users)

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">
                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Users</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        {companyId ? <Link to="/dashboard/add-company" className="text-muted text-hover-primary">Manage Companies</Link> : <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>}
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Users{location.state ? ' - ' + location.state.company_name : ''}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-fluid"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >

                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Users */}

                                            <div className="w-100">
                                                <div className="card-header align-items-center mb-2">
                                                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                                        <div className="row col-md-12 justify-content-end">
                                                            <div className="col-md-3">
                                                                <div className="btn btn-primary" onClick={() => handleAddUser()}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#kt_modal"><i className="fa fa-plus"></i> Add User</div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="btn btn-primary" onClick={() => setShowUploadModal(true)}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#kt_modal"><i class="fa fa-upload"></i> Upload Users</div>
                                                                <div><a href="/downloads/Upload_Users_Template V1.0.csv">Download sample template</a></div>
                                                            </div>
                                                            <div
                                                                className="col-md-3">
                                                                <div className="btn btn-primary" onClick={handleDownload}><i class="fa fa-download"></i> Download Users</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Company Users</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table
                                                                                        id="tbl_tickets"
                                                                                        className="table table-row-dashed align-middle gs-0 gy-3 my-0 users_list"
                                                                                    >
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                <th className="p-0 pb-3 min-w-20px text-start">
                                                                                                    #
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    EMAIL
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    NAME
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    TYPE
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    PRIMARY NO
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    ALTERNATE NO
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    CREATED AT
                                                                                                </th>
                                                                                                {/* <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    IS BLOCKED?
                                                                                                </th> */}
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">ACTIONS</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {users?.map((user, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={i} className={user.is_deleted ? 'deleted_row' : ''}>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        user.user_id
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        user.user_email
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        user.user_fname + ' ' + user.user_lname
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className={`badge py-3 px-4 fs-7 ${getUserTypeName(user).badge_color} `}>{getUserTypeName(user).name}</span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        user.user_contact_1
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        user.user_contact_2
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>

                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        formatDateString(user.created_at)
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            {/* <td className="text-start p-0 ">
                                                                                                                {currentUser.user_type <= 1?
                                                                                                                    <Form>
                                                                                                                        <Form.Check
                                                                                                                        disabled={user.is_deleted}
                                                                                                                            type="switch"
                                                                                                                            id={"block_switch_"+user.user_id}
                                                                                                                            label=""
                                                                                                                            defaultChecked={user.is_blocked} onChange={(event) => showBlockedDialog(event, user)}
                                                                                                                        /></Form> :
                                                                                                                    <span className={`text-gray-600 fs-7 text-hover-primary badge ${user.is_blocked === 1 ? 'badge-light-success' : 'badge-light-danger'}`}>{user.is_blocked === 1 ? 'Yes' : 'No'}</span>


                                                                                                                }
                                                                                                            </td> */}
                                                                                                            <td className="text-start p-0">
                                                                                                                {user.user_id === 1 ? null : (<div
                                                                                                                    onClick={() =>
                                                                                                                        changePassword(user)
                                                                                                                    }
                                                                                                                    class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2" title="Change Password">
                                                                                                                    <i className="fa fa-key" title="Change Password" />
                                                                                                                </div>)
                                                                                                                }
                                                                                                                {user.user_id === 1 ? null : (<div
                                                                                                                    
                                                                                                                    class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2" title="Change Password">
                                                                                                                    <i className="fa fa-toggle-on" title="Change Password" />
                                                                                                                </div>)
                                                                                                                }
                                                                                                                {user.user_id === 1 || user.user_id === (currentUser?.user_id) || user.is_deleted ? null : (<div
                                                                                                                    onClick={() =>
                                                                                                                        editUser(user)
                                                                                                                    }
                                                                                                                    class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" title="Edit">
                                                                                                                    <i className="fa fa-pencil" title="Edit" />
                                                                                                                </div>)
                                                                                                                }
                                                                                                                {user.user_id === 1 || user.user_id === (currentUser?.user_id) || user.is_deleted ? null : (<div
                                                                                                                    onClick={() =>
                                                                                                                        deleteUser(user)
                                                                                                                    }
                                                                                                                    class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2" title="Delete">
                                                                                                                    <i className="fa fa-trash" title="Delete" />
                                                                                                                </div>)
                                                                                                                }

                                                                                                                {/* {user.user_id === 1 || user.user_id === (currentUser?.user_id)  || user.is_deleted ? null : (<div
                                                                                                                    onClick={() =>
                                                                                                                        editCompany(user)
                                                                                                                    }
                                                                                                                    class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" title="Edit">
                                                                                                                    <i className="fa fa-edit" title="Edit Company" />
                                                                                                                </div>)
                                                                                                                } */}


                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                            {
                                                                                                users?.length === 0 ? <div className="no_data">No users found!</div> : null
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {showChangePasswordModal ? (
                                            <ModalBox
                                                showModal={showChangePasswordModal}
                                                hideModal={() => setShowChangePasswordModal(false)}
                                                title={"Change Password - " + selectedUser.user_fname + ' ' + selectedUser.user_lname}>
                                                <ChangePassword user_id={selectedUser ? selectedUser.user_id : ''}
                                                    handleFailure={(msg) => handleChangePasswordFailure(msg)}
                                                    handleSuccess={() => handleChangePasswordSuccess()}
                                                    hideModal={() => setShowChangePasswordModal(false)} showCurrentPassword={false}></ChangePassword>
                                            </ModalBox>) : null}
                                        {console.log(selectedUser)}
                                        {showModifyModal ? (
                                            <ModalBox
                                                showModal={showModifyModal}
                                                hideModal={() => setShowModifyModal(false)}
                                                title={"Change Company - " + selectedUser.user_fname + ' ' + selectedUser.user_lname}>
                                                <ChangeCompany user_id={selectedUser ? selectedUser.user_id : ''}
                                                    company_name={selectedUser ? selectedUser.company_name : ''}
                                                    company_id={selectedUser ? selectedUser.company_id : ''}
                                                    handleFailure={(msg) => handleChangePasswordFailure(msg)}
                                                    handleSuccess={(action) => handleChangeModifySuccess(action)}
                                                    hideModal={() => setShowModifyModal(false)} showCurrentPassword={false}></ChangeCompany>
                                            </ModalBox>) : null}

                                        {showUploadModal ? (
                                            <ModalBox
                                                showModal={showUploadModal}
                                                hideModal={() => showUploadModal(false)}
                                                title="Upload Users"
                                            >
                                                <Formik
                                                    initialValues={{
                                                        user_fname: ""
                                                    }}
                                                    enableReinitialize
                                                    onSubmit={(values, { resetForm }) => {
                                                        setTimeout(() => {
                                                            resetForm();
                                                        }, 500);
                                                        return false;
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                    }) => (
                                                        <div
                                                            className="d-flex flex-column flex-root"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <form onSubmit={handleSubmit}>
                                                                    <div className="row mb-6">
                                                                        <CSVParse handleUploadedProducts={(data) => handleUploadedUsers(data)} setRefreshFunction={(f) => { setChildRefreshFunction(f); }}></CSVParse>
                                                                    </div>

                                                                    <div className="row mb-6">
                                                                        <div className="table-responsive">
                                                                            <table
                                                                                id="tbl_tickets"
                                                                                className="table table-row-dashed table-row-gray-300 gy-7"
                                                                            >
                                                                                <thead>
                                                                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                                                            User Email
                                                                                        </th>
                                                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                                                            First Name
                                                                                        </th>
                                                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                                                            Last Name
                                                                                        </th>
                                                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                                                            Contact
                                                                                        </th>
                                                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                                                            Address
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {uploadedUsers?.map((user, i) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr key={i}>
                                                                                                    <td className="text-start p-0 ">
                                                                                                        <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                            {
                                                                                                                user.Email
                                                                                                            }
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td className="text-start p-0 ">
                                                                                                        <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                            {
                                                                                                                user["First Name"]
                                                                                                            }
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td className="text-start p-0 ">
                                                                                                        <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                            {
                                                                                                                user["Last Name"]
                                                                                                            }
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td className="text-start p-0 ">
                                                                                                        <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                            {
                                                                                                                user["Contact Number1"]
                                                                                                            }
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td className="text-start p-0 ">
                                                                                                        <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                            {
                                                                                                                user["Address"]
                                                                                                            }
                                                                                                        </span>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                    <div class="text-center">
                                                                        <button
                                                                            type="reset"
                                                                            id="kt_modal_new_target_cancel"
                                                                            class="btn btn-light me-3"
                                                                            onClick={() => setShowUploadModal(false)}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            id="kt_modal_new_target_submit"
                                                                            onClick={() => submitUsers()}
                                                                            class="btn btn-primary"
                                                                        >
                                                                            <span class="indicator-label">
                                                                                Submit
                                                                            </span>
                                                                            <span class="indicator-progress">
                                                                                Please wait...
                                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Formik>

                                            </ModalBox>
                                        ) : null}

                                        {showModal ? (
                                            <ModalBox
                                                showModal={showModal}
                                                hideModal={() => setShowModal(false)}
                                                title={dialogTitle}
                                            >
                                                <Formik
                                                    initialValues={{
                                                        user_type: selectedUser?.user_type,
                                                        user_first_name: selectedUser?.user_fname,
                                                        user_last_name: selectedUser?.user_lname,
                                                        user_address: selectedUser?.user_address,
                                                        user_contact_1: selectedUser?.user_contact_1,
                                                        user_contact_2: selectedUser?.user_contact_2,
                                                        user_email: selectedUser?.user_email,
                                                        user_password: '',
                                                        user_id: selectedUser?.user_id,
                                                        // user_gst: selectedUser?.user_gst,
                                                    }}
                                                    validate={validateInput}
                                                    enableReinitialize
                                                    onSubmit={(values, { resetForm }) => {
                                                        setTimeout(() => {
                                                            // console.log('values : ', values);
                                                            if (addNewUser(values) === true) {
                                                                resetForm();
                                                            }
                                                        }, 500);
                                                        return false;
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                    }) => (
                                                        <div
                                                            className="d-flex flex-column flex-root"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <form onSubmit={handleSubmit}>

                                                                    {/* User Type */}
                                                                    {/* <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>User Type</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <Form.Select aria-label="Select User Type" name="user_type" onChange={handleChange} value={values.user_type}>
                                                                                <option value="-1">Select UserType</option>
                                                                                <option value="2">User</option>
                                                                                <option value="1">Admin</option>
                                                                            </Form.Select>
                                                                            {errors.user_type && touched.user_type ? <div className="error">{errors.user_type}</div> : null}
                                                                        </div>
                                                                    </div> */}

                                                                    {/* User Name */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>First Name</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_first_name"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_first_name}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_first_name && touched.user_first_name ? <div className="error">{errors.user_first_name}</div> : null}
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Last Name</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_last_name"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_last_name}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_last_name && touched.user_last_name ? <div className="error">{errors.user_last_name}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    {/* Contact No 1 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Address</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_address"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_address}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Contact No 1 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Primary No</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_contact_1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_contact_1}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_contact_1 && touched.user_contact_1 ? <div className="error">{errors.user_contact_1}</div> : null}
                                                                        </div>
                                                                    </div>

                                                                    {/* Contact No 2 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Alternate No</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_contact_2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_contact_2}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Username */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Email</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_email"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_email}
                                                                                autoComplete="off"
                                                                                // disabled={values.user_id > 0 ? true : false}
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_email && touched.user_email ? <div className="error">{errors.user_email}</div> : null}
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>GST</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="user_gst"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.user_gst}
                                                                                autoComplete="off"
                                                                                // disabled={values.user_id > 0 ? true : false}
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.user_gst && touched.user_gst ? <div className="error">{errors.user_gst}</div> : null}
                                                                        </div>
                                                                    </div> */}

                                                                    {
                                                                        values.user_id > 0 ? null :
                                                                            <div className="row mb-6" >
                                                                                <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                                    <span>Password</span>
                                                                                </label>
                                                                                <div className="col-lg-8 fv-row">
                                                                                    <input
                                                                                        type="password"
                                                                                        placeholder=""
                                                                                        name="user_password"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={values.user_password}
                                                                                        autoComplete="off"
                                                                                        className="form-control bg-transparent"
                                                                                    />
                                                                                    {errors.user_password && touched.user_password ? <div className="error">{errors.user_password}</div> : null}
                                                                                </div>
                                                                            </div>
                                                                    }

                                                                    <div class="text-center">
                                                                        <button
                                                                            type="reset"
                                                                            id="kt_modal_new_target_cancel"
                                                                            class="btn btn-light me-3"
                                                                            onClick={() => setShowModal(false)}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            id="kt_modal_new_target_submit"
                                                                            class="btn btn-primary"
                                                                        >
                                                                            <span class="indicator-label">
                                                                                Submit
                                                                            </span>
                                                                            <span class="indicator-progress">
                                                                                Please wait...
                                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    </div>


                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Formik>

                                            </ModalBox>
                                        ) : null}

                                        {showBlockModal ? <ModalBox showModal={showBlockModal} title={"User " + userBlockedDialogTitle + " Confirmation"}>
                                            <div
                                                className="d-flex flex-column flex-root"
                                                id="kt_app_root"
                                            >
                                                <div className="row mb-6">{"Do you want to " + userBlockedDialogTitle + " the user?"}</div>
                                                <div className="text-center">
                                                    {/* <Form> */}
                                                    <button className="btn btn-danger btn-sm me-5"
                                                        onClick={(event) => handleBlocked(event)}
                                                    >Yes</button>
                                                    <button className="btn btn-secondary btn-sm"
                                                        onClick={(event) => cancelBlocked(event)}
                                                    >No</button>
                                                    {/* </Form> */}
                                                </div>
                                            </div>

                                        </ModalBox> : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddUser;