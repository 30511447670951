import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

import { getAllNotifications } from "../../services/NotificationServices";
import { getUserData, getUser } from '../../shared/UserUtil';

const NotificationsPage = (params) => {
    const [notifications, setNotifications] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [activePage, setActivePage] = useState(0);

    useEffect(() => {
        fetchNotifications();
    }, []);

    useEffect(() => {
    }, [activePage]);

    const fetchNotifications= async () => {
        let tmp_user = getUser();
        let payload = "";        
        setCurrentUser(tmp_user);

        let resp = null;
         { // Seller / Vendor
            payload = { user_id: tmp_user.user_id, page: activePage };
            resp = await getAllNotifications(payload);
        }

        
        if (resp && resp.data) {
            if (resp.status === 200) {
                setNotifications(resp.data);
            } else {
                console.log('500!');
            }
        }
    }

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">
        
                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Notifications</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Notifications</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-xxl"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Products */}
                                            <div className="w-100">
                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Notifications</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table id="tbl_tickets" className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">#</th>
                                                                                                <th className="p-0 pb-3 min-w-100px text-start">Comments</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                notifications?.map((o, i) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr key={i}>
                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{i + 1}</span>
                                                                                                                </td>

                                                                                                                <td className="text-start p-0" >
                                                                                                                    <span className="text-gray-600 fs-7">{o.comments}</span>
                                                                                                                </td>

                                                                                                               
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationsPage;