import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import $ from 'jquery';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';

import avatar2 from "../../assets/media/avatars/user-2.jpg";
import list_icon from "../../assets/images/list-icon.svg";
import { getUserData, getUser } from '../../shared/UserUtil';
import { getLatestNotifications, updateNotifications } from '../../services/NotificationServices';
import ModalBox from "../../shared/Modal";
import { getAllVendorProducts, updateQtyVendorProducts } from "../../services/ProductServices";
import { toastSuccess, toastError } from '../../shared/Toast';
import { RecordType, UserType } from '../../shared/Constants';
import ChangePassword from '../../pages/User/ChangePassword';

const TopMenu = () => {
    //const [userType, setUserType] = useState();
    const cart_data = useSelector((state) => state.cart.cart_data);
    const [notifications, setNotifications] = useState([]);
    let navigate = useNavigate();
    let user_data = getUserData();
    const [noQtyData, setNoQtyData] = useState([]);
    const [showNoQtyModal, setShowNoQtyModal] = useState(false);
    const [initialValues, setInitialValues] = useState({ products: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(getUser());

    useEffect(() => {
        if (currentUser === null) {
            navigate('/login');
        } else {
            console.log(currentUser);
            setShowChangePasswordModal(!currentUser.is_password_changed);
        }
        //setUserType(user_data.user.user_type);
        fetchNotifications();
        getNoQtyStocks();

    }, []);

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user_data');
        navigate('/');
        window.location.reload()
    }

    const handleSwitchMode = () => {
        console.log(user_data);
        user_data.user.mode = (user_data.user.mode === 1 ? 2 : 1);
        sessionStorage.setItem("user_data", JSON.stringify(user_data));

        if (user_data.user.mode === 1) {
            $('#kt_app_sidebar').removeClass('seller').addClass('buyer');
            navigate('/dashboard/view-products');
        }
        else {
            $('#kt_app_sidebar').removeClass('buyer').addClass('seller');
            navigate('/dashboard/add-product');
        }
    }

    const getNoQtyStocks = async () => {
        if (user_data.user.mode === 2) {
            let payload = { user_id: user_data.user.user_id, product_qty: 0, record_type: RecordType.Product };
            setIsLoading(true)
            let stockUpdates = await getAllVendorProducts(payload);
            console.log(stockUpdates);
            let stock = []
            stockUpdates.data.forEach(element => {
                element.isEdit = false;
                stock.push(element);
            });
            setIsLoading(false)
            setNoQtyData(stock);
            setInitialValues({ products: stock });
            setShowNoQtyModal(stockUpdates.data.length > 0);
        }
    }

    const fetchNotifications = async () => {
        let tmp_user = getUser();
        let payload = { user_id: tmp_user.user_id };
        let resp = await getLatestNotifications(payload);

        if (resp && resp.data) {
            if (resp.status === 200) {
                setNotifications(resp.data);
            } else {
                console.log('500!');
            }
        }
    }

    const updateNotificationFlag = async () => {
        let tmp_user = getUser();
        let payload = { user_id: tmp_user.user_id };
        let resp = await updateNotifications(payload);
        
        if (resp && resp.data) {
            if (resp.status === 200) {
                console.log(resp);
                fetchNotifications();
            } else {
                console.log('500!');
            }
        }
    }

    const hideNoQtyModal = () => {
        setShowNoQtyModal(false);
    }

    const updateStockQty = async (values) => {
        console.log(values);
        let updateProducts = values.products.filter(p => parseFloat(p.product_qty) > 0);
        //let updategtProducts = values.products.filter(p => parseFloat(p.product_qty) < 0);
        //setInitialValues({products:updategtProducts});

        if (null != updateProducts && updateProducts.length > 0) {
            setIsLoading(true);
            setShowNoQtyModal(false);
            let payload = { user_id: getUser().user_id, products: updateProducts }
            let resp = await updateQtyVendorProducts(payload);
            if (resp) {
                if (resp.status === 200) {
                    //setShowNoQtyModal(true);
                    setIsLoading(false);
                    toastSuccess("Stock updated Successfully!")
                    getNoQtyStocks();
                    //window.location.reload();
                } else {
                    console.log('500!');
                }
            }
        }
    }

    const handleChangePasswordSuccess = () => {        
        user_data.user.is_password_changed = true;
        sessionStorage.setItem("user_data", JSON.stringify(user_data));
        setCurrentUser(getUser());
        setShowChangePasswordModal(false);
        toastSuccess("Password Updated Successfully");
    }

    const handleChangePasswordFailure = (message) => {
        //setShowChangePasswordModal(false);
        toastError(message);
    }

    return (
        <>
            <div id="kt_app_header" className="app-header">
                <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
                    <div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
                        <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                            <span className="svg-icon svg-icon-1">
                                <img src={list_icon} alt="List Icon" />
                            </span>
                        </div>
                    </div>
                    {/* <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <a href="../../demo1/dist/index.html" className="d-lg-none">
                            <img alt="Logo" src={img3} className="h-30px" />
                        </a>
                    </div> */}
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                        <div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">
                        </div>
                        <div className="app-navbar flex-shrink-0">
                            <div className="app-navbar-item ms-1 ms-lg-3">
                                {
                                    notifications.length > 0 ?
                                        <Link to="/dashboard/notifications" onClick={updateNotificationFlag}>
                                            <i className="fa fa-bell fs-2"></i>
                                            <span className="badge badge-cart badge-danger cart_notify">{notifications ? notifications.length : 0}</span>
                                        </Link>
                                        : ""

                                }
                            </div>
                            <div className="app-navbar-item ms-1 ms-lg-3">
                                {
                                    getUser().mode === 1 ?
                                        <Link to="/dashboard/view-cart">
                                            <i className="fa fa-cart-shopping fs-2"></i>
                                            <span className="badge badge-cart badge-primary cart_notify">{cart_data ? cart_data.length : 0}</span>
                                        </Link>
                                        :
                                        null
                                }
                            </div>
                            <div className="app-navbar-item ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                                <div className="cursor-pointer symbol symbol-35px symbol-md-40px">
                                    {user_data.user.user_fname + ' ' + user_data.user.user_lname} 
                                    {/* <div>({user_data.user.company_name} - {user_data.user.user_email})</div> */}
                                </div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                                        <img src={avatar2} alt="user" style={{ 'width': '36px', 'height': '36px', 'borderRadius': '25px' }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item>Logged in {user_data.user.user_email}</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/dashboard/user-profile">Profile</Dropdown.Item>
                                        {/* {user_data.user.user_type === UserType.User ? <Dropdown.Item onClick={handleSwitchMode}>Switch as {getUserData().user.mode === 2 ? 'Buyer' : 'Seller'}</Dropdown.Item> : null} */}
                                        {user_data.user.user_type === UserType.User ? <Dropdown.Item onClick={handleSwitchMode}>Switch as {getUserData().user.mode === 2 ? 'Buyer' : 'Seller'}</Dropdown.Item> : null}
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/dashboard/complaint-box">Complaint Box</Dropdown.Item>
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
                                    <div className="menu-item px-3">
                                        <div className="menu-content d-flex align-items-center px-3">
                                            <div className="symbol symbol-50px me-5">
                                                <img alt="Logo" src={avatar2} />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="fw-bold d-flex align-items-center fs-5">Max Smith
                                                    <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span></div>
                                                <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">max@kt.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator my-2"></div>
                                    <div className="menu-item px-5">
                                        <a href="../../demo1/dist/account/overview.html" className="menu-link px-5">My Profile</a>
                                    </div>
                                    <div className="menu-item px-5">
                                        <a href="../../demo1/dist/apps/projects/list.html" className="menu-link px-5">
                                            <span className="menu-text">My Projects</span>
                                            <span className="menu-badge">
                                                <span className="badge badge-light-danger badge-circle fw-bold fs-7">3</span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start">
                                        <a href="#" className="menu-link px-5">
                                            <span className="menu-title">My Subscription</span>
                                            <span className="menu-arrow"></span>
                                        </a>
                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/referrals.html" className="menu-link px-5">Referrals</a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/billing.html" className="menu-link px-5">Billing</a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/statements.html" className="menu-link px-5">Payments</a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/statements.html" className="menu-link d-flex flex-stack px-5">Statements
                                                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="View your statements"></i></a>
                                            </div>
                                            <div className="separator my-2"></div>
                                            <div className="menu-item px-3">
                                                <div className="menu-content px-3">
                                                    <label className="form-check form-switch form-check-custom form-check-solid">
                                                        <input className="form-check-input w-30px h-20px" type="checkbox" value="1" defaultChecked={false} checked="checked" name="notifications" />
                                                        <span className="form-check-label text-muted fs-7">Notifications</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-item px-5">
                                        <a href="../../demo1/dist/account/statements.html" className="menu-link px-5">My Statements</a>
                                    </div>
                                    <div className="separator my-2"></div>
                                    <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start">
                                        <a href="#" className="menu-link px-5">
                                            <span className="menu-title position-relative">Language
                                                <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
                                                    <img className="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-states.svg" alt="" /></span></span>
                                        </a>
                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex px-5 active">
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
                                                    </span>English</a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex px-5">
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src="assets/media/flags/spain.svg" alt="" />
                                                    </span>Spanish</a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex px-5">
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src="assets/media/flags/germany.svg" alt="" />
                                                    </span>German</a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex px-5">
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src="assets/media/flags/japan.svg" alt="" />
                                                    </span>Japanese</a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex px-5">
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src="assets/media/flags/france.svg" alt="" />
                                                    </span>French</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-item px-5 my-1">
                                        <a href="../../demo1/dist/account/settings.html" className="menu-link px-5">Account Settings</a>
                                    </div>
                                    <div className="menu-item px-5">
                                        <a href="../../demo1/dist/authentication/flows/basic/sign-in.html" className="menu-link px-5">Sign Out</a>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
                                <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle">
                                    <span className="svg-icon svg-icon-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z" fill="currentColor" />
                                            <path opacity="0.3" d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {showNoQtyModal ? (<ModalBox
                className="no_qty_section"
                showModal={showNoQtyModal}
                hideModal={() => hideNoQtyModal(false)}
                title={"Stock Update Notification"}>
                {
                    <>
                        <div className="table-responsive">

                            <Formik
                                initialValues={initialValues}
                                onSubmit={async (values) => {
                                    //await new Promise((r) => setTimeout(r, 500));
                                    console.log(JSON.stringify(values, null, 2));
                                    updateStockQty(values);
                                }}
                            >
                                {({ values }) => (
                                    <Form>
                                        <table id="tbl_tickets" className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                            <thead>
                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                    <th className="p-0 pb-3 min-w-100px text-start">#</th>
                                                    <th className="p-0 pb-3 min-w-100px text-start">TYPE</th>
                                                    <th className="p-0 pb-3 min-w-100px text-start">BRAND</th>
                                                    <th className="p-0 pb-3 min-w-100px text-start">MODEL</th>
                                                    <th className="p-0 pb-3 min-w-100px text-start">QTY</th>
                                                    <th className="p-0 pb-3 min-w-100px text-start">NEW/USED</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name="products">
                                                    {({ insert, remove, push }) => (
                                                        <>
                                                            {values.products.length > 0 &&
                                                                values.products.map((product, index) => (

                                                                    <tr key={"noQtyData_" + index} className="product">
                                                                        <td className="text-start p-0" >
                                                                            <span className="text-gray-600 fw-bold fs-7">{index + 1}</span>
                                                                        </td>

                                                                        <td className="text-start p-0" >
                                                                            <span className="text-gray-600 fw-bold fs-7">{product.product_type}</span>
                                                                        </td>
                                                                        <td className="text-start p-0" >
                                                                            <span className="text-gray-600 fw-bold fs-7">{product.product_brand}</span>
                                                                        </td>

                                                                        <td className="text-start p-0" >
                                                                            <span className="text-gray-600 fw-bold fs-7">{product.product_model}</span>
                                                                        </td>

                                                                        <td className="text-start p-0" >
                                                                            <Field
                                                                                className="text_box form-control"
                                                                                name={`products.${index}.product_qty`}
                                                                                placeholder=""
                                                                                type="number"
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`products.${index}.product_qty`}
                                                                                component="div"
                                                                                className="field-error"
                                                                            /></td>
                                                                        <td className="text-start p-0">
                                                                            <span className={`badge py-3 px-4 fs-7 ${product.product_isnew === 1 ? 'badge-light-primary' : null} ${product.product_isnew === 2 ? 'badge-light-success' : null} `}>{product.product_isnew === 1 ? 'New' : null} {product.product_isnew === 2 ? 'Used' : null} </span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </>
                                                    )}
                                                </FieldArray>
                                                <tr>
                                                    <td className="text-end p-0" colSpan="6" >
                                                        <button
                                                            type="reset"
                                                            id="kt_modal_new_target_cancel"
                                                            className="btn btn-light me-3"
                                                            onClick={() => setShowNoQtyModal(false)}>
                                                            Close
                                                        </button>
                                                        <button type="submit" className='btn btn-primary btn-sm' >Submit</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </>
                }
            </ModalBox>
            ) : null}

            <ModalBox closeButton={false}
                showModal={showChangePasswordModal}
                title="Change Password">
                <ChangePassword user_id={currentUser ? currentUser.user_id : ''}
                    handleFailure={(msg) => handleChangePasswordFailure(msg)}
                    handleSuccess={() => handleChangePasswordSuccess()}
                    hideModal={() => setShowChangePasswordModal(false)}
                    showCurrentPassword={true}></ChangePassword>
            </ModalBox>

        </>
    )
}

export default TopMenu;