import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { getUserProfile, updateUser } from "../../services/UserServices";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../shared/Loader";
import { Link } from "react-router-dom";
import ModalBox from "../../shared/Modal";
import ChangePassword from "./ChangePassword";
import ChangeCompany from "./ChangeCompany";
import ChangeGst from "./ChangeGst";
import { UserType } from "../../shared/Constants";

import { toastError, toastSuccess } from "../../shared/Toast";
// import ChangeEmail from "./ChangeEmail";

const UserProfile = () => {
    const [userProfile, setUserProfile] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userId, setUserId] = useState("");
    const [showModifyModal, setShowModifyModal] = useState(false);
    const [showGstModal, setShowGstModal] = useState(false);

    // const [userEmail, setUserEmail] = useState('');
    const [showModal, setShowModal] = useState(false);
    // const [showEmailModal, setEmailShowModal] = useState(false);

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = async () => {
        let current_user_data = JSON.parse(sessionStorage.getItem("user_data"));
        const resp = await getUserProfile({
            user_id: current_user_data.user.user_id,
        });
        setUserProfile(resp.data[0]);
        setIsLoading(false);
        setUserId(current_user_data.user.user_id);
        // setUserEmail(current_user_data.user.user_email)
    };

    const notify = (type, msg) => {
        if (type === 1) {
            toast.success(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }

        if (type === 2) {
            toast.error(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }

        if (type === 3) {
            toast.warning(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }

        if (type === 4) {
            toast.info(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
    };

    const showChangePassword = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked);
    };

    const handleChangePasswordSuccess = () => {
        setShowModal(false);
        toastSuccess("Password Updated Successfully");
    };

    const handleChangeModifySuccess = (msg) => {
        setUserProfile({ ...userProfile, company_name: msg.company_name });
        setShowModifyModal(false);
        toastSuccess("User Company Updated Successfully");
    };

    const handleChangeGstSuccess = (msg) => {
        setUserProfile({ ...userProfile, user_gst: msg.user_gst });
        setShowGstModal(false);
        toastSuccess("User GST Updated Successfully");
    };

    const handleChangePasswordFailure = (msg) => {
        toastError(msg);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div
                    className="app-main flex-column flex-row-fluid mt-8"
                    id="kt_app_main"
                >
                    <div className="d-flex flex-column flex-column-fluid">
                        {/* Breadcrumb Start*/}
                        <div
                            id="kt_app_toolbar"
                            className="app-toolbar py-3 py-lg-6 text-start"
                        >
                            <div
                                id="kt_app_toolbar_container"
                                className="app-container container-xxl d-flex flex-stack"
                            >
                                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                                        User Profile
                                    </h1>
                                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link
                                                to="/dashboard"
                                                className="text-muted text-hover-primary"
                                            >
                                                Dashboard
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                        </li>
                                        <li className="breadcrumb-item text-muted">User Profile</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Breadcrumb End */}

                        <div id="kt_app_content" className="app-content flex-column-fluid ">
                            <div
                                id="kt_app_content_container"
                                className="app-container container-xxl"
                            >
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            initialValues={{
                                                company_name: userProfile.company_name,
                                                user_type: userProfile.user_type,
                                                user_fname: userProfile.user_fname,
                                                user_lname: userProfile.user_lname,
                                                user_contact_1: userProfile.user_contact_1,
                                                user_contact_2: userProfile.user_contact_2,
                                                user_address: userProfile.user_address,
                                                user_email: userProfile.user_email,
                                                company_id: userProfile.user_type,
                                                user_gst: userProfile.user_gst,
                                            }}
                                            // validationSchema={addTaskSchema}
                                            enableReinitialize
                                            onSubmit={(values, { }) => {
                                                setTimeout(() => {
                                                    console.log("values1...: ", values);
                                                    // handleChangePassword(values);
                                                }, 500);
                                                return false;
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,
                                            }) => (
                                                <div
                                                    className="d-flex flex-column flex-root"
                                                    id="kt_app_root"
                                                >
                                                    <div className="w-md">
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="row mb-6">
                                                                <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                    <span>Company Name</span>
                                                                </label>
                                                                <div className="col-lg-5 fv-row">
                                                                    <input
                                                                        type="text"
                                                                        placeholder=""
                                                                        name="user_fname"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_name}
                                                                        autoComplete="off"
                                                                        className="form-control bg-transparent text-capitalize"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                {userProfile.user_type === UserType.Admin ? (
                                                                    <a
                                                                        className="col-lg-2 fv-row"
                                                                        onClick={() => {
                                                                            setShowModifyModal(true);
                                                                        }}
                                                                    >
                                                                        Edit Company Name?
                                                                    </a>
                                                                ) : null}
                                                            </div>
                                                            {/* User First Name */}
                                                            <div className="row mb-6">
                                                                <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                    <span>First Name</span>
                                                                </label>
                                                                <div className="col-lg-5 fv-row">
                                                                    <input
                                                                        type="text"
                                                                        placeholder=""
                                                                        name="user_fname"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.user_fname}
                                                                        autoComplete="off"
                                                                        className="form-control bg-transparent text-capitalize"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* User Last Name */}
                                                            <div className="row mb-6">
                                                                <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                    <span>Last Name</span>
                                                                </label>
                                                                <div className="col-lg-5 fv-row">
                                                                    <input
                                                                        type="text"
                                                                        placeholder=""
                                                                        name="user_lname"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.user_lname}
                                                                        autoComplete="off"
                                                                        className="form-control bg-transparent text-capitalize"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>

                                                                {/* Contact No 1 */}
                                                                <div className="row mb-6">
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span>Primary No</span>
                                                                    </label>
                                                                    <div className="col-lg-5 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            name="user_contact_1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.user_contact_1}
                                                                            autoComplete="off"
                                                                            className="form-control bg-transparent text-capitalize"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>


                                                                {/* Contact No 2 */}
                                                                <div className="row mb-6">
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span>Alternate No</span>
                                                                    </label>
                                                                    <div className="col-lg-5 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            name="user_contact_2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.user_contact_2}
                                                                            autoComplete="off"
                                                                            className="form-control bg-transparent text-capitalize"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* Address */}
                                                                <div className="row mb-6">
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span>Address</span>
                                                                    </label>
                                                                    <div className="col-lg-5 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            name="user_address"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.user_address}
                                                                            autoComplete="off"
                                                                            className="form-control bg-transparent text-capitalize"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>

                                                            {/* Username */}
                                                            <div className="row mb-6">
                                                                <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                    <span>Email</span>
                                                                </label>
                                                                <div className="col-lg-5 fv-row">
                                                                    <input
                                                                        type="text"
                                                                        placeholder=""
                                                                        name="user_username"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.user_email}
                                                                        autoComplete="off"
                                                                        className="form-control bg-transparent"
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* GST */}
                                                            {userProfile.user_type === UserType.Admin ? (
                                                                <div className="row mb-6">
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span>GST No</span>
                                                                    </label>
                                                                    <div className="col-lg-5 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            name="user_gst"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.user_gst}
                                                                            autoComplete="off"
                                                                            className="form-control bg-transparent"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <a
                                                                        className="col-lg-2 fv-row"
                                                                        onClick={() => {
                                                                            setShowGstModal(true);
                                                                        }}
                                                                    >
                                                                        Edit GST No?
                                                                    </a>
                                                                </div>
                                                            ) : null}

                                                            {/* Password */}
                                                            <div className="row mb-6">
                                                                {
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span></span>
                                                                    </label>
                                                                }
                                                                <div className="col-lg-5 fv-row">
                                                                    {/* <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            name="user_password"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={'**********'}
                                                                            autoComplete="off"
                                                                            className="form-control bg-transparent"
                                                                            disabled
                                                                        /> */}
                                                                    <div className="d-flex align-items-center mt-3 cursor-pointer">
                                                                        <a
                                                                            className="fw-semibold ps-2 fs-6"
                                                                            onClick={() => {
                                                                                setShowModal(true);
                                                                            }}
                                                                        >
                                                                            Change Password?
                                                                        </a>
                                                                        {/* <a className="fw-semibold ps-6 fs-6" onClick={() => { setEmailShowModal(true) }} >Change Email?</a> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <ModalBox
                                                                showModal={showModifyModal}
                                                                hideModal={() => setShowModifyModal(false)}
                                                                title={"Edit Company"}
                                                            >
                                                                <ChangeCompany
                                                                    user_id={
                                                                        userProfile ? userProfile.user_id : ""
                                                                    }
                                                                    company_name={
                                                                        userProfile ? userProfile.company_name : ""
                                                                    }
                                                                    company_id={
                                                                        userProfile ? userProfile.company_id : ""
                                                                    }
                                                                    handleFailure={(msg) =>
                                                                        handleChangePasswordFailure(msg)
                                                                    }
                                                                    handleSuccess={(action) =>
                                                                        handleChangeModifySuccess(action)
                                                                    }
                                                                    hideModal={() => setShowModifyModal(false)}
                                                                    showCurrentPassword={false}
                                                                ></ChangeCompany>
                                                            </ModalBox>

                                                            <ModalBox
                                                                showModal={showGstModal}
                                                                hideModal={() => setShowGstModal(false)}
                                                                title={"Edit GST"}
                                                            >
                                                                <ChangeGst
                                                                    user_id={
                                                                        userProfile ? userProfile.user_id : ""
                                                                    }
                                                                    user_gst={
                                                                        userProfile ? userProfile.user_gst : ""
                                                                    }
                                                                    handleFailure={(msg) =>
                                                                        handleChangePasswordFailure(msg)
                                                                    }
                                                                    handleSuccess={(action) =>
                                                                        handleChangeGstSuccess(action)
                                                                    }
                                                                    hideModal={() => setShowModifyModal(false)}
                                                                    showCurrentPassword={false}
                                                                ></ChangeGst>
                                                            </ModalBox>

                                                            <ModalBox
                                                                showModal={showModal}
                                                                hideModal={() => setShowModal(false)}
                                                                title="Change Password"
                                                            >
                                                                <ChangePassword
                                                                    user_id={userId ? userId : ""}
                                                                    handleFailure={(msg) =>
                                                                        handleChangePasswordFailure(msg)
                                                                    }
                                                                    handleSuccess={() =>
                                                                        handleChangePasswordSuccess()
                                                                    }
                                                                    hideModal={() => setShowModal(false)}
                                                                    showCurrentPassword={true}
                                                                ></ChangePassword>
                                                            </ModalBox>
                                                            {/* <ModalBox
                                                                    showModal={showEmailModal}
                                                                    hideModal={() => setEmailShowModal(false)}
                                                                    title="Change Email"
                                                                >

                                                                <ChangeEmail user_email = {userEmail ? userEmail : ''} handleFailure={(msg) => handleChangePasswordFailure(msg)} handleSuccess={() => handleChangePasswordSuccess()} hideModal={() => setEmailShowModal(false)} showCurrentPassword={true}></ChangeEmail>                                                                

                                                                </ModalBox> */}
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserProfile;
