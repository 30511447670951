import axios from "axios";

export const init = () => {
    setInterceptors();
}

export function setInterceptors() {
    axios.interceptors.request.use(request => {
        const token = sessionStorage.getItem("token");
        if (token) {
            request.headers.common.Authorization = `Bearer ${token}`;
        }
        return request;
    })
}