import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import { saveAd, updateAd, getAllAds, deleteAd } from "../../services/AdsService";
import { APIBaseURL } from "../../constants/ApiEndPoints";
import ModalBox from "../../shared/Modal";
import { formatDateString } from "../../shared/DateUtil";
import { toastSuccess, toastError } from '../../shared/Toast';
import { getUser } from "../../shared/UserUtil";

const AdsPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [adsList, setAdsList] = useState([]);
    const [parentCategoryList, setParentCategoryList] = useState([]);
    const [selectedAd, setSelectedAd] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Add Banner');
    const [error, setError] = useState("");
    const [file, setFile] = useState("");
    const inputRef = useRef(null);
    const allowedExtensions = ["png", "jpg", "jpeg"];

    useEffect(() => {
        fetAllAds();
    }, []);

    const handleFileChange = (e) => {
        setError("");
        let inputFile = '';
        let extension = '';
        if (e.target.files.length) {
            inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];
            extension = inputFile.name.substring(inputFile.name.lastIndexOf(".") + 1);
            if (!allowedExtensions.includes(extension.toLowerCase())) {
                toastError(`Please input a ${allowedExtensions.toString()} file`);
                return;
            }
            setFile(inputFile);
        }
    }

    const fetAllAds = async () => {
        let current_user_data = getUser()
        let payload = { user_id: current_user_data.user_id };
        const adsList = await getAllAds(payload);
        //setParentCategoryList(categoryList.data.filter(f => f.parent_category_id === null));
        setAdsList(adsList.data);
    }

    const addNewAds = async (values) => {
        console.log(values);
        let currentUser = getUser();
        let response = {};
        if (values.ads_id) {
            let formData = new FormData();
            formData.append("banner_image", file);
            formData.append("price", values.price);
            formData.append("discount", values.discount);
            formData.append("user_id", currentUser.user_id);
            formData.append("ads_id", values.ads_id);
            response = await updateAd(formData);
        } else {
            let formData = new FormData();
            formData.append("banner_image", file);
            // formData.append("price", values.price);
            // formData.append("discount", values.discount);
            formData.append("hyperlink", values.hyperlink);
            formData.append("user_id", currentUser.user_id);
            response = await saveAd(formData);
        }

        if (response.status === 200) {
            setShowModal(false);
            toastSuccess("Banner " + (values.category_id ? "updated" : "created") + " successfully!");
            fetAllAds();
            return true;
        } else {
            toastError(response.msg || response.message);
            return false;
        }
    }

    const validateInput = (values) => {
        const errors = {}

        // if (!values.price) {
        //     errors.price = 'Price cannot be empty!'
        // }

        // if (!values.discount) {
        //     errors.discount = "Discount cannot be empty!";
        // }

        return errors;
    }

    const handleEditModal = (ads) => {
        setSelectedAd(ads);
        setShowModal(true);
        setDialogTitle('Edit Banner');
    }

    const handleDeleteModal = (ads) => {
        setSelectedAd(ads)
        setShowDeleteModal(true);
    }

    const handleDeleteAds = async () => {
        console.log(selectedAd);

        let response = await deleteAd({ ads_id: selectedAd?.ads_id, is_deleted: true });
        if (response.status === 200) {
            toastSuccess('Banner Deleted Successfully!');
            setShowDeleteModal(false);
            setSelectedAd(null)
            fetAllAds()
            return true;
        } else {
            toastError(response.message);
            return false;
        }
    }

    const showAddAdModal = () => {
        setShowModal(true);
        setSelectedAd(null);
    }

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">
                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Banners</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard/orders" className="text-muted text-hover-primary">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Banners</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-fluid"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Products */}
                                            <div className="w-100">
                                                <div className="card-header align-items-center mb-2">
                                                    <div
                                                        className="card-toolbar flex-row-fluid justify-content-end gap-5"
                                                        onClick={() => showAddAdModal()}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal"
                                                    >
                                                        <div className="btn btn-primary">+ Add Banner</div>
                                                    </div>
                                                </div>

                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            {/* <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Manage Categories</span>
                                                                                </h3>
                                                                            </div> */}
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table
                                                                                        id="tbl_tickets"
                                                                                        className="table table-row-dashed table-row-gray-300 gy-7"
                                                                                    >
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                {/* <th className="p-0 pb-3 min-w-20px text-start">
                                                                                                    PRODUCT #
                                                                                                </th> */}
                                                                                                 <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    HyperLink
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Discount %
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Price
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Image
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Created At
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Actions
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {adsList?.map((ads, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={i}>
                                                                                                        <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        ads.hyperlink
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        ads.discount
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        ads.price
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className={"fw-bold fs-7 text-hover-primary"}>
                                                                                                                    {
                                                                                                                        <img src={APIBaseURL+"/files/ads/"+ads.filepath} title="Ads File" className="ads_image"/>

                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        formatDateString(ads.created_at)
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-3" title="Edit" onClick={() => handleEditModal(ads)}><i className="fa fa-pencil" title="Edit" /></div>
                                                                                                                <div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" title="Delete" onClick={() => handleDeleteModal(ads)}><i className="fa fa-trash" title="Delete" /></div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                    {adsList.length === 0 ? <div>No Banners Found!</div> : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {showModal ? (
                                            <ModalBox
                                                showModal={showModal}
                                                hideModal={() => setShowModal(false)}
                                                title={dialogTitle} className="add_ads"
                                            >
                                                <Formik
                                                    initialValues={{
                                                        discount: selectedAd?.discount,
                                                        price: selectedAd?.price,
                                                        filepath: selectedAd?.filepath,
                                                        image: null,
                                                        ads_id: selectedAd?.ads_id
                                                    }}
                                                    validate={validateInput}
                                                    enableReinitialize
                                                    onSubmit={(values, { resetForm }) => {
                                                        setTimeout(() => {
                                                            if (!addNewAds(values)) {
                                                                resetForm();
                                                            }
                                                        }, 500);
                                                        return false;
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                    }) => (
                                                        <div
                                                            className="d-flex flex-column flex-root"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <form onSubmit={handleSubmit}>
                                                                    {/* <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Discount</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="discount"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.discount}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.discount && touched.discount ? <div className="error">{errors.discount}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Price</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="price"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.price}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.price && touched.price ? <div className="error">{errors.price}</div> : null}
                                                                        </div>
                                                                    </div> */}

                                                                    {/* <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Discount</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="discount"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.discount}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.discount && touched.discount ? <div className="error">{errors.discount}</div> : null}
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>HyperLink</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="hyperlink"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.hyperlink}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.hyperlink && touched.hyperlink ? <div className="error">{errors.hyperlink}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Image</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            <input ref={inputRef} class="form-control form-control-sm" onChange={handleFileChange} id="csvInput" name="image" type="file" />
                                                                            {errors.image && touched.image ? <div className="error">{errors.image}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-center">
                                                                        <button
                                                                            type="reset"
                                                                            id="kt_modal_new_target_cancel"
                                                                            class="btn btn-light me-3"
                                                                            onClick={() => setShowModal(false)}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            id="kt_modal_new_target_submit"
                                                                            class="btn btn-primary">
                                                                            <span class="indicator-label">
                                                                                Submit
                                                                            </span>
                                                                            <span class="indicator-progress">
                                                                                Please wait...
                                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Formik>

                                            </ModalBox>
                                        ) : null}


                                        {showDeleteModal ?
                                            <ModalBox showModal={showDeleteModal}
                                                hideModal={() => setShowDeleteModal(false)}
                                                title="Delete Banner Confirmation">
                                                <div
                                                    className="d-flex flex-column flex-root"
                                                    id="kt_app_root"
                                                >
                                                    <div className="w-md"></div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-12 col-form-label fw-semibold fs-6">
                                                            <span>Are you sure to delete the banner? </span>
                                                        </label>
                                                    </div>
                                                    <div class="text-center">
                                                        <button
                                                            type="reset"
                                                            id="kt_modal_new_target_cancel"
                                                            class="btn btn-light me-3"
                                                            onClick={() => setShowDeleteModal(false)}>
                                                            No
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            id="kt_modal_new_target_submit"
                                                            onClick={handleDeleteAds}
                                                            class="btn btn-danger">
                                                            <span class="indicator-label">
                                                                Yes
                                                            </span>
                                                            <span class="indicator-progress">
                                                                Please wait...
                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ModalBox> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdsPage;