import axios from "axios";
import { loginURL, verify2ndPasswordURL, getAllUsersURL } from "../constants/ApiEndPoints";

export const loginWithPassword = async (payload) => {
  try {
    const response = await axios.post(`${loginURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      if (data.status === 200 && data.token) {
        console.log(data)
        if (data.token !== "" && data.user.user_id !== "" && data.user.user_type>0) {
          sessionStorage.setItem("token", data.token);
          data.user.mode=1;
          sessionStorage.setItem("user_data", (JSON.stringify(data)));
          // console.log('user_data : ', data);
        }
      }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const verify2ndPassword = async (payload) => {
  try {
    const response = await axios.post(`${verify2ndPasswordURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      if (data.status === 200 && data.token) {
        if (data.token !== "" && data.user.user_id !== "") {
          sessionStorage.setItem("token", data.token);
          data.user.mode=1;
          sessionStorage.setItem("user_data", (JSON.stringify(data)));
        }
      }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getAllUsers = async (payload) => {
  try {
    const response = await axios.post(`${getAllUsersURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}






