import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { getAllCustomers, addCustomer } from "../../services/CustomerServices";
import ModalBox from "../../shared/Modal";
import { ToastContainer, toast } from 'react-toastify';

const AddCustomer = () => {
    const [showModal, setShowModal] = useState(false);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        fetchAllCustomers();
    }, []);
 

    const addNewCustomer = async (values) => {
        console.log(values);

        setShowModal(false);

        let custResp = await addCustomer({
            customer_name: values.customer_name,
            customer_contcat_1: values.customer_contcat_1,
            customer_contact_2: values.customer_contact_2,
            customer_address: values.customer_address,
            customer_lat: values.customer_lat,
            customer_long: values.customer_long,
            customer_email: values.customer_email,
            company_id: values.company_id
        });

        if (custResp.status === 200) {
            notify(1, "Success!");
            fetchAllCustomers();
        } else {
            notify(2, "Error!");
        }
    }

    // const deleteUser = async (params) => {
    //     let resp = await deleteUserById({data: {user_id : params.user_id}});
    //     if (resp.status === 200) {
    //         fetchAllUsers();
    //         notify(1, "Success!");
    //     } else {
    //         notify(2, "Error!");
    //     }
    // }
        
    const fetchAllCustomers = async () => {
		let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));
        
        const resp = await getAllCustomers({company_id: current_user_data.user.company_id});
        setCustomers(resp.data);
    }   

	const notify = (type, msg) => {
		if(type === 1) {
			toast.success(msg, {
			  position: "top-right",
			  autoClose: 3000,
			  hideProgressBar: false,
			  closeOnClick: true,
			});
		  } 
	  
		  if(type === 2) {
			toast.error(msg, {
			  position: "top-right",
			  autoClose: 3000,
			  hideProgressBar: false,
			  closeOnClick: true,
			});      
		  }
	  
		  if(type === 3) { 
			toast.warning(msg, {
			  position: "top-right",
			  autoClose: 3000,
			  hideProgressBar: false,
			  closeOnClick: true,
			});
		  }
	  
		  if(type === 4) {  
			toast.info(msg, {
			  position: "top-right",
			  autoClose: 3000,
			  hideProgressBar: false,
			  closeOnClick: true,
			});
		  }
	};

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">
                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-xxl"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >

                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Customers */}

                                            <div className="w-100">
                                                <div className="card-header align-items-center mb-2">
                                                    <div
                                                        className="card-toolbar flex-row-fluid justify-content-end gap-5"
                                                        onClick={() => setShowModal(true)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal"
                                                    >
                                                        <div className="btn btn-primary">+ Add Customer</div>
                                                    </div>
                                                </div>

                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                        <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Customers</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table
                                                                                        id="tbl_tickets"
                                                                                        className="table table-row-dashed align-middle gs-0 gy-3 my-0"
                                                                                    >
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                <th className="p-0 pb-3 min-w-20px text-start">
                                                                                                    CUSTOMER #
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    CUSTOMER NAME
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    CONTACT 1
                                                                                                </th>
                                                                                                {/* <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    CONTACT 2
                                                                                                </th> */}
                                                                                                {/* <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    ADDRESS
                                                                                                </th> */}
                                                                                                {/* <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    LAT
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    LONG
                                                                                                </th> */}
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    EMAIL
                                                                                                </th>

                                                                                                <th className="p-0 pb-3 min-w-50px text-start"></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {customers?.map((customer, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={i}>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_id
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_name
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_contact_1
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            {/* <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_contact_2
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td> */}
                                                                                                            {/* <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_address
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td> */}
                                                                                                            {/* <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_lat
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_long
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td> */}
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        customer.customer_email
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>

                                                                                                            
                                                                                                            {/* <td className="text-start p-0">
                                                                                                                <div
                                                                                                                    onClick={() =>
                                                                                                                        deleteUser(user)
                                                                                                                    }
                                                                                                                    class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                                                                                                >
                                                                                                                    <span class="svg-icon svg-icon-3">
                                                                                                                        <svg
                                                                                                                            width="24"
                                                                                                                            height="24"
                                                                                                                            viewBox="0 0 24 24"
                                                                                                                            fill="none"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                        >
                                                                                                                            <path
                                                                                                                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                                                                                                fill="currentColor"
                                                                                                                            ></path>
                                                                                                                            <path
                                                                                                                                opacity="0.5"
                                                                                                                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                                                                                                fill="currentColor"
                                                                                                                            ></path>
                                                                                                                            <path
                                                                                                                                opacity="0.5"
                                                                                                                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                                                                                                fill="currentColor"
                                                                                                                            ></path>
                                                                                                                        </svg>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </td> */}
                                                                                                            
                                                                                                          
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {showModal ? (
                                            <ModalBox
                                                showModal={showModal}
                                                hideModal={() => setShowModal(false)}
                                                title="Add Customer"
                                            >
                                                <Formik
                                                    initialValues={{ }}
                                                    // validationSchema={addTaskSchema}
                                                    enableReinitialize
                                                    onSubmit={(values, { resetForm }) => {
                                                        setTimeout(() => {
                                                            // console.log('values : ', values);
                                                             addNewCustomer(values);
                                                            // setSubmitting(false);
                                                            resetForm();
                                                        }, 500);
                                                        return false;
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                    }) => (
                                                        <div
                                                            className="d-flex flex-column flex-root"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <form onSubmit={handleSubmit}>

                                                                    {/* customer_name */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Name</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="customer_name"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.customer_name}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* customer_contcat_1 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Contcat No 1</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="customer_contcat_1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.customer_contcat_1}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {/* customer_contact_2 */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Alternate No</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="customer_contact_2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.customer_contact_2}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {/* customer_address */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Address</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="customer_address"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.customer_address}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {/* customer_lat */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Lat</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="customer_lat"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.customer_lat}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* customer_long */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Long</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="customer_long"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.customer_long}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {/* customer_email */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Email</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="customer_email"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.customer_email}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {/* company_id */}
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Company ID</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="company_id"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.company_id}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div class="text-center">
                                                                        <button
                                                                            type="reset"
                                                                            id="kt_modal_new_target_cancel"
                                                                            class="btn btn-light me-3"
                                                                            onClick={() => setShowModal(false)}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            id="kt_modal_new_target_submit"
                                                                            // onClick={handleClickSubmit}
                                                                            class="btn btn-primary"
                                                                        >
                                                                            <span class="indicator-label">
                                                                                Submit
                                                                            </span>
                                                                            <span class="indicator-progress">
                                                                                Please wait...
                                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Formik>

                                            </ModalBox>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default AddCustomer;