import { createSlice } from '@reduxjs/toolkit';
import { toastWarning } from '../../shared/Toast';

const initialState = {
  cart_data: []
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      var existingItem = state.cart_data.filter(f=>f.vendor_product_id === action.payload.vendor_product_id);
      if(existingItem.length>0){
        existingItem[0].product_cart_qty +=action.payload.product_cart_qty;
      }else {
        state.cart_data.push(action.payload);
      }
    },
    incrementItemQty: (state, action) => {
      let tmp_qty = state.cart_data[action.payload].product_cart_qty;
      if(tmp_qty >= 1) {
        state.cart_data[action.payload].product_cart_qty ++;
      }
    },
    decrementItemQty: (state, action) => {
      let tmp_qty = state.cart_data[action.payload].product_cart_qty;
      //if(tmp_qty > 1)
       {
        state.cart_data[action.payload].product_cart_qty --;
      }      
      var items = state.cart_data.filter(f=>f.product_cart_qty>0);
      var noItems = state.cart_data.filter(f=>f.product_cart_qty==0);
      if(noItems && noItems.length){
        toastWarning(noItems[0].product_brand + ' '+ noItems[0].product_model +  ' removed' );
      }
      state.cart_data=items;  

    },
    resetCart: (state, cart) => {
      state.cart_data = [];
    }
  },
});

export const { addToCart, incrementItemQty, decrementItemQty, resetCart } = cartSlice.actions;

export default cartSlice.reducer;