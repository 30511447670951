import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { getAllVendorProducts } from "../../services/ProductServices";
import ModalBox from "../../shared/Modal";
import { addToCart } from '../../store/actions/cartSlice';
import { RecordType } from "../../shared/Constants";

const ProductDetails = () => {
    const [showModal, setShowModal] = useState(false);
    const [vendorProducts, setVendorProducts] = useState([]);
    const [productName, setProductName] = useState();
    const [totalProducts, setTotalProducts] = useState(0);
    const [activePage, setActivePage] = useState(0);
    const location = useLocation();
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        fetchAllVendorProducts();
    }, []);

    useEffect(() => {
    }, [activePage]);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.description}
        </Tooltip>
    );

    const fetchAllVendorProducts = async () => {
        let product = location.state.details;
        let payload = { product_id: product.product_id, page: activePage + 1 };
        const resp = await getAllVendorProducts(payload);
        setVendorProducts(resp.data);
        if(product.record_type === RecordType.Product)
        setProductName(product.product_brand + ' ' + product.product_model);
        else if(product.record_type === RecordType.Service){
            setProductName(product.title);
        }
        setTotalProducts(resp.total);
    }

    const handlePageChange = async (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber)

        let product = location.state.details;
        let payload = { product_type: product.product_type, product_brand: product.product_brand, product_model: product.product_model, product_isnew: product.product_isnew, page: pageNumber };
        const resp = await getAllVendorProducts(payload);
        setVendorProducts(resp.data);
        setProductName(product.product_brand + ' ' + product.product_model);
        setTotalProducts(resp.total);
    };

    const notify = (type, msg) => {
        if (type === 1) {
            toast.success(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }

        if (type === 2) {
            toast.error(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }

        if (type === 3) {
            toast.warning(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }

        if (type === 4) {
            toast.info(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
    };

    const addItem = (item, i) => {
        item.product_cart_qty = 1;
        dispatch(addToCart(item));
        document.getElementById(`btn_${i}`).disabled = true;
        notify(1, "Product Added to Cart")
    }

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">

                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Vendors</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard/view-products" className="text-muted text-hover-primary">Products</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Vendors</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-xxl"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            <div className="w-100">
                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800 text-uppercase">Vendors - {productName}</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table
                                                                                        id="tbl_tickets"
                                                                                        className="table table-row-bordered table-striped table-row-gray-300 gy-7"
                                                                                    >
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Serial #
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    VENDOR #
                                                                                                </th>                                                                                                
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    TITLE/TYPE
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    BRAND
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    MODEL
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    QUANTITY
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    PRICE
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    DESCRIPTION
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {vendorProducts?.map((v, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={i}>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        i + 1
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        "Seller #" + (i + 1)
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        v.record_type===RecordType.Product? v.product_type: v.title
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        v.product_brand
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        v.product_model
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        v.record_type===RecordType.Product?v.product_qty:''
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        v.product_price
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <OverlayTrigger
                                                                                                                    placement="bottom"
                                                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                                                    overlay={renderTooltip({ description: v.product_description })}>
                                                                                                                    <i className="bi bi-info-circle m-5">
                                                                                                                        {/* {
                                                                                                                            v.product_description ? v.product_description.substring(0, 25) + (v.product_description.length > 25 ? " ..." : "") : ""
                                                                                                                        } */}
                                                                                                                    </i>
                                                                                                                </OverlayTrigger>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <button id={`btn_${i}`} type="button" className="btn btn-primary btn-sm btn-hover-scale" onClick={() => addItem(v, i)}>

                                                                                                                    {/* Add to Cart */}
                                                                                                                    <i className="bi bi-cart-plus fs-1x"></i>
                                                                                                                    <span style={{ 'marginLeft': '7px' }}>Add to Cart</span>

                                                                                                                    {/* Added to Cart */}
                                                                                                                    {/* <i className="bi bi-check-lg fs-2x"></i> */}
                                                                                                                    {/* <span style={{ 'margin-left': '7px' }}>Added to Cart</span> */}

                                                                                                                </button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                                <Pagination
                                                                                    activePage={activePage + 1}
                                                                                    itemsCountPerPage={pageSize}
                                                                                    totalItemsCount={totalProducts ? totalProducts : 0}
                                                                                    pageRangeDisplayed={pageSize}
                                                                                    onChange={handlePageChange}
                                                                                    itemClass="page-item"
                                                                                    linkClass="page-link"
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {showModal ? (
                                            <ModalBox
                                                showModal={showModal}
                                                hideModal={() => setShowModal(false)}
                                                title="Upload Products"
                                            >
                                                <Formik
                                                    initialValues={{
                                                        company_name: ""
                                                    }}
                                                    // validationSchema={addTaskSchema}
                                                    enableReinitialize
                                                    onSubmit={(values, { resetForm }) => {
                                                        setTimeout(() => {
                                                            // addNewCompany(values);
                                                            // setSubmitting(false);
                                                            resetForm();
                                                        }, 500);
                                                        return false;
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                    }) => (
                                                        <div
                                                            className="d-flex flex-column flex-root"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <form onSubmit={handleSubmit}>
                                                                    <div className="row mb-6">
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <button
                                                                            type="reset"
                                                                            id="kt_modal_new_target_cancel"
                                                                            className="btn btn-light me-3"
                                                                            onClick={() => setShowModal(false)}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            id="kt_modal_new_target_submit"
                                                                            // onClick={handleClickSubmit}
                                                                            className="btn btn-primary"
                                                                        >
                                                                            <span className="indicator-label">
                                                                                Submit
                                                                            </span>
                                                                            <span className="indicator-progress">
                                                                                Please wait...
                                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Formik>

                                            </ModalBox>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default ProductDetails;