import axios from "axios";
import { addTaskURL, getTasksByTaskIdURL, updateTaskByTaskIdURL } from "../constants/ApiEndPoints";

export const addTask = async (payload) => {
  try {
    const response = await axios.post(`${addTaskURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getTasksByTicketId = async (payload) => {
  try {
    const response = await axios.post(`${getTasksByTaskIdURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updateTaskByTaskId = async (payload) => {
  try {
    const response = await axios.put(`${updateTaskByTaskIdURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}


