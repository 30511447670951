import './App.css';
import "./assets/css/style.bundle.css";
import "./assets/css/common.css";
import "./assets/plugins/global/plugins.bundle.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { init as initAxios } from "./services/AxiosServices";
// Import Pages
import Landing from './pages/Landing/LandingMain';
import LoginMain from "./pages/Login/LoginMain";
import DashboardHome from "./pages/Dashboard/DashboardHome";
import Dashboard from "./pages/Dashboard/Dashboard";
import CreateTicket from "./pages/Ticket/CreateTicket";
import AddCompany from "./pages/Company/AddCompany";
import AddProduct from "./pages/Product/AddProduct";
import AddCustomer from "./pages/Customer/AddCustomer";
import AddUser from "./pages/User/AddUser";
import TrackTicketStatus from "./pages/Ticket/TrackTicketStatus";
import Map from "./pages/GoogleMap/GoogleMap";
import DashboardOrdersPage from "./pages/Dashboard/DashboardOrdersPage";
import ViewProducts from "./pages/Product/ViewProducts";
import Cart from "./pages/ShoppingCart/Cart";
import PageNotFound from "./pages/Error/PageNotFound"
import ProductDetails from './pages/Product/ProductDetails';
import OrderDetails from './pages/Order/OrderDetails';
import UserProfile from './pages/User/UserProfile';
import SwitchMode from './pages/User/SwitchMode';
import { ToastContainer } from 'react-toastify';
import NotificationsPage from './pages/Notification/NotificationsPage';
import SessionTimeout from './shared/components/SessionTimeout';
import EnquiryPage from './pages/Enquiry/EnquiryPage';
import ComplaintBoxPage from './pages/ComplaintBox/ComplaintBoxPage';
import CompanyDetail from './pages/Company/CompanyDetail';
import CategoryPage from './pages/Category/CategoryPage';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import AdsPage from './pages/Ads/AdsPage';

import $ from 'jquery';

function App() {
  initAxios();

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_data');    
    window.location.href="/";
   
  }
  console.log("env", process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === 'development') {
    console.log = function () {

    }
  }
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
    <div className="App">
      <SessionTimeout logOut={handleLogout} />
      <BrowserRouter basename='/'>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/login" element={<LoginMain />} />

          {/* Parent Child Routes */}
          {/* /dashboard/add-user */}
          {/* dashboard/create-ticket */}
          <Route exact path="/landing" element={<Landing />} />
          <Route exact path="/dashboard" element={<DashboardHome />}>
            <Route path="home" element={<Dashboard />}></Route>
            <Route path="orders" element={<DashboardOrdersPage />}></Route>
            <Route path="add-company" element={<AddCompany />}></Route>
            <Route path="add-product" element={<AddProduct />}></Route>
            <Route path="add-user" element={<AddUser />}></Route>
            <Route path="add-user/:companyId" element={<AddUser />}></Route>
            <Route path="add-customer" element={<AddCustomer />}></Route>
            <Route path="create-ticket" element={<CreateTicket />} />
            <Route path="track-ticket-status" element={<TrackTicketStatus />} />
            <Route path="view-products" element={<ViewProducts />} />
            <Route path="product-details" element={<ProductDetails />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="view-cart" element={<Cart />} />
            <Route path="track-user" element={<Map />} />
            <Route path="user-profile" element={<UserProfile />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="enquiry" element={<EnquiryPage />} />
            <Route path="complaint-box" element={<ComplaintBoxPage />} />
            <Route path="settings" element={<CompanyDetail />} />
            <Route path="category" element={<CategoryPage />} />
            <Route path="ads" element={<AdsPage />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
          <Route path="/user/switch-mode" element={<SwitchMode />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
    </GoogleReCaptchaProvider>
  );

}

export default App;