import axios from "axios";
import {
    saveRequirementURL, updateRequirementURL, getRequirementsURL, approveRequirementURL, deleteRequirementURL
} from "../constants/ApiEndPoints";


export const saveRequirement = async (payload) => {
  try {
    const response = await axios.post(`${saveRequirementURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}


export const updateRequirement = async (payload) => {
    try {
      const response = await axios.put(`${updateRequirementURL}`, payload);
      if (response.status === 200) {
        const data = response.data;
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const getAllRequirements = async (payload) => {
    try {
      const response = await axios.post(`${getRequirementsURL}`, payload);
      if (response.status === 200) {
        const data = response.data;
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const approveRequirement = async (payload) => {
    try {
      const response = await axios.put(`${approveRequirementURL}`, payload);
      if (response.status === 200) {
        const data = response.data;
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const deleteRequirement = async (payload) => {
    try {
      const response = await axios.put(`${deleteRequirementURL}`, payload);
      if (response.status === 200) {
        const data = response.data;
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }