import axios from "axios";
import { getAllOrdersURL as getMyOrdersURL, generateOrderIdURL, placeOrderURL,
   updateOrderURL, getSellerOrdersURL, cancelOrderURL, chartOrderURL } from "../constants/ApiEndPoints";

export const getMyOrders = async (payload) => {
  try {
    const response = await axios.post(`${getMyOrdersURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getSellerOrders = async (payload) => {
  try {
    const response = await axios.post(`${getSellerOrdersURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const generateOrderId = async (payload) => {
  try {
    const response = await axios.post(`${generateOrderIdURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const placeOrder = async (payload) => {
  try {
    const response = await axios.post(`${placeOrderURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updateOrder = async (payload) => {
  try {
    const response = await axios.post(`${updateOrderURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const cancelOrder = async (payload) => {
  try {
    const response = await axios.post(`${cancelOrderURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}


export const getOrderChart = async (payload) => {
  try {
    const response = await axios.post(`${chartOrderURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

