import React from 'react';
import { toast } from 'react-toastify';
import { MessageTypes } from './Constants';

export const toastMessage = (props) => {
  let type = props.type;
  let msg = props.message;
  if (type === MessageTypes.Success) {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }

  if (type === MessageTypes.Error) {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }

  if (type === MessageTypes.Warning) {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }

  if (type === MessageTypes.Info) {
    toast.info(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }
};

export const toastError = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
  });
}

export const toastSuccess = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
  });
}

export const toastWarning = (msg) => {
  toast.warning(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
  });
}

export const toastInfo = (msg) => {
  toast.info(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
  });
}

