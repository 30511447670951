// Base Url
export const APIBaseURL = process.env.REACT_APP_BASEURL;
export const SECRET_KEY = process.env.REACT_APP_SECRETKEY;
export const getAllVendorProductsURL = APIBaseURL + '/product/getVendorProducts'
export const getAllStockProductsURL = APIBaseURL + '/product/getStockProducts'
export const getAllStockProductsByKeywordURL = APIBaseURL + '/product/stock/keyword'
export const uploadStockProductsURL = APIBaseURL + '/product/upload/products'

export const uploadVendorProductsURL = APIBaseURL + '/uploadVendorProducts'
export const getAllVendorsGroupURL = APIBaseURL + '/getVendorsGroup'

export const productDeliveryMethodtURL = APIBaseURL + '/product/get/product-feature';
export const updateVendorProductURL = APIBaseURL + '/product/update/VendorProducts';
export const deleteVendorProductURL = APIBaseURL + '/product/delete/VendorProducts';
export const downloadVendorProductURL = APIBaseURL + '/product/download/excel';
export const updateProductQtyURL = APIBaseURL + '/product/update/qty';
export const addVendorProductURL = APIBaseURL + '/product/update/VendorProducts';
export const approveProductsURL = APIBaseURL + '/product/approve';
export const downloadTemplateURL = APIBaseURL + '/product/download/template';


export const updateVendorServiceURL = APIBaseURL + '/product/update/VendorService';
export const addVendorServiceURL = APIBaseURL + '/product/update/VendorService';
export const uploadStockServicesURL = APIBaseURL + '/product/upload/services'

//Order
export const generateOrderIdURL = APIBaseURL + '/order/generateOrderId'
export const placeOrderURL = APIBaseURL + '/order/placeOrder'
export const updateOrderURL = APIBaseURL + '/order/updateOrder'
export const getAllOrdersURL = APIBaseURL + '/order/getMyOrders'
export const getSellerOrdersURL = APIBaseURL + '/order/getSellerOrders'
export const cancelOrderURL =  APIBaseURL + '/order/cancel'
export const chartOrderURL =  APIBaseURL + '/order/chart'

//User
export const getAllUsersGroupURL = APIBaseURL + '/getUsersGroup'
export const getUsersURL = APIBaseURL + '/user/getUsers'
export const addUserURL = APIBaseURL + '/user/addUser'
export const updateUserURL = APIBaseURL + '/user/updateUser'
export const loginURL = APIBaseURL + '/user/login'
export const getAllUsersURL = APIBaseURL + '/user/getUsers'
export const uploadUsersURL = APIBaseURL + '/user/uploadUsers'
export const deleteUserURL = APIBaseURL + '/user/update/deleted'
export const updateTermsUserURL = APIBaseURL + '/user/updateTerms'
export const downloadUsersURL = APIBaseURL + '/user/download'
export const refreshTokenURL = APIBaseURL + '/user/refreshToken'
export const updatePasswordURL = APIBaseURL + '/user/update/password'
export const verify2ndPasswordURL = APIBaseURL + '/user/verify/2ndpassword'
export const updateBlockedURL = APIBaseURL + '/user/update/blocked'

export const createTicketURL = APIBaseURL + '/createTicket'
export const addCustomerURL = APIBaseURL + '/addCustomer'
export const getAllCustomersURL = APIBaseURL + '/getAllCustomers'
export const addTaskURL = APIBaseURL + '/addTask'
export const getTasksByTaskIdURL = APIBaseURL + '/getTasksByTicketId'
export const updateTaskByTaskIdURL = APIBaseURL + '/updateTask'
export const updateTicketByTicketIdURL = APIBaseURL + '/updateTicket'

//Company
export const subscriptionValidationURL = APIBaseURL + '/company/getProductUserUploadCount';
export const allCompaniesURL = APIBaseURL + '/company/getAllCompanies'
export const addCompanyURL = APIBaseURL + '/company/addCompany'
export const deleteCompanyURL = APIBaseURL + '/deleteCompany'
export const getCompanyURL = APIBaseURL + '/company/getById'
export const updateCompanyURL = APIBaseURL + '/company/update'
export const blockCompanyURL = APIBaseURL + '/company/blockCompany'

//notification
export const allNotificaitonsURL = APIBaseURL + '/notification/all'
export const latestNotificationsURL = APIBaseURL + '/notification/latest'
export const updateNotificationsURL = APIBaseURL + '/notification/update'

//Enquiry
export const saveEnquiryURL = APIBaseURL + '/enquiry/save'
export const getEnquiryURL = APIBaseURL + '/enquiry/get/all'

//category
export const getAllCategoriesURL = APIBaseURL + '/category/get/all'
export const saveCategoryURL = APIBaseURL + '/category/save'
export const updateCategoryURL = APIBaseURL + '/category/update'
export const deleteCategoryURL = APIBaseURL + '/category/delete'


//ads
export const getAllAdsURL = APIBaseURL + '/web/get/all'
export const saveAdURL = APIBaseURL + '/web/save'
export const updateAdURL = APIBaseURL + '/web/update'
export const deleteAdURL = APIBaseURL + '/web/delete'
export const getAllActiveAdsURL = APIBaseURL + '/web/get/active'


//Subscription Type
export const subscriptionTypesURL = APIBaseURL + '/subscription/subscriptionTypes'
export const subscriptionTypesAllURL = APIBaseURL + '/subscription/all'
export const updateSubscriptionTypeURL = APIBaseURL + '/subscription/update'


//New Requirement
export const saveRequirementURL = APIBaseURL + '/requirement/save'
export const updateRequirementURL = APIBaseURL + '/requirement/update'
export const getRequirementsURL = APIBaseURL + '/requirement/get/requirements'
export const approveRequirementURL = APIBaseURL + '/requirement/approve'
export const deleteRequirementURL = APIBaseURL + '/requirement/delete'
