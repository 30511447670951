import React, { Fragment, useCallback, useRef, useState } from "react";
import { GoogleMap, Polyline, useLoadScript, Marker } from "@react-google-maps/api";

const GPSTracker = () => {
  const polylineRef = useRef(null);
  const listenersRef = useRef([]);
  const [path, setPath] = useState([
    { lat: 18.5667234, lng: 73.881313 },
    { lat: 18.526967, lng: 73.9278252 }
  ]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polylineRef.current) {
      const nextPath = polylineRef.current
        .getPath()
        .getArray()
        .map((latLng) => latLng.toJSON());
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polyline and listeners
  const onLoad = useCallback(
    (polyline) => {
      polylineRef.current = polyline;
      const path = polyline.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polylineRef.current = null;
  }, []);

  const mapContainerStyle = {
    width: "80vw",
    height: "80vh"
  };

  const showPath = () => {
    console.log(path); //What should be here to show the edited path if its possible to access?
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: ""
  });

  const centre = { lat: 18.5667234, lng: 73.881313 };

  if (loadError) return "Error loading Google Map";
  if (!isLoaded) return "Loading Maps....";

  return (
    <Fragment>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={centre}
      >
        <Marker position={{lat: 18.5667234, lng: 73.881313}} 
                icon={{
                  url: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
                }}
        />
        <Marker position={{lat: 18.526967, lng: 73.9278252 }} 
                icon={{
          url: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
        }}
        />

        <Polyline
          ref={polylineRef}
          path={path}
          options={{ editable: false, strokeColor: "#ff0000" }}
          // Event used when manipulating and adding points
          onMouseUp={onEdit}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
      </GoogleMap>
      <button onClick={showPath}>Show Path in Console</button>
    </Fragment>
  );
};

export default GPSTracker;
