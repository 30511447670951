import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { Formik } from "formik";
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { getMyOrders, updateOrder, getSellerOrders, cancelOrder } from "../../services/OrderServices";
import { getAllVendorsGroup } from "../../services/VendorServices";
import { getAllVendorProducts } from "../../services/ProductServices";
import ModalBox from "../../shared/Modal";
import Loader from '../../shared/Loader';
import { PerPageSize } from '../../constants/CommonConstants';
import arrow_right from "../../assets/images/arrow-right.svg";
import { toastSuccess, toastError } from '../../shared/Toast';
import { OrderReviewStatus } from '../../shared/Constants';
import { getUser } from '../../shared/UserUtil';
import { UserType, UserMode } from '../../shared/Constants';
import { Something_Went_Wrong } from '../../shared/MessageUtil';
import { formatDateString } from '../../shared/DateUtil';

const DashboardOrdersPage = () => {
	const [orders, setOrders] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [orderProducts, setOrderProducts] = useState();
	const [showModal, setShowModal] = useState(false);
	const [userType, setUserType] = useState();
	const [activePage, setActivePage] = useState(0);
	const [totalProducts, setTotalProducts] = useState(0);
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(PerPageSize);
	const [showApprovalModal, setShowApprovalModal] = useState(false);
	const [currentOrder, setCurrentOrder] = useState(null);
	const [currentUser, setCurrentUser] = useState(getUser());
	const [keyword, setKeyword] = useState('');
	const [showCancelModal, setShowCancelModal] = useState(false);

	useEffect(() => {
		getAllOrders();
	}, [])

	useEffect(() => {
	}, [activePage]);

	const getPayload = (pageNumber, search) => {
		switch (currentUser.user_type) {

			case UserType.SuperAdmin:
				return { group_by_order_id: 1, page: pageNumber, user_id: currentUser.user_id, order_id: search != null ? search : keyword };

			case UserType.MasterAdmin:
				return { group_by_order_id: 1, page: pageNumber, user_id: currentUser.user_id, order_id: search != null ? search : keyword };

			case UserType.Admin:
				return { group_by_order_id: 1, page: pageNumber, company_id: currentUser.company_id, user_id: currentUser.user_id, order_id: search != null ? search : keyword };

			case UserType.User:
				if (UserMode.Buyer) {
					return { user_id: currentUser.user_id, group_by_order_id: 1, page: pageNumber };
				} else {
					return { vendor_id: currentUser.user_id, group_by_order_id: 1, page: pageNumber };
				}

			default:
				return {};
		}
	}

	const getOrdersByPage = async (pageNumber, search) => {
		setIsLoading(true);
		setCurrentUser(getUser());
		setUserType(currentUser.user_type);
		let payload = getPayload(pageNumber, search);
		console.log(currentUser, payload);
		let resp = null;

		if (currentUser.user_type === UserType.User) {
			if (currentUser.mode === UserMode.Buyer)
				resp = await getMyOrders(payload);
			else if (currentUser.mode === UserMode.Seller) {
				resp = await getSellerOrders(payload);
			}
		} else {
			resp = await getSellerOrders(payload);
		}

		if (resp && resp.data) {
			setOrders(resp.data);
			setTotalProducts(resp.total);
			if (resp.status === 200) {
				setIsLoading(false);
			} else {
				console.log('500!');
				setIsLoading(false);
			}
		}
	}
	const getAllOrders = async () => {
		getOrdersByPage(activePage);
	}

	const handlePageChange = async (pageNumber) => {
		setActivePage(pageNumber);
		getOrdersByPage(pageNumber);
	}

	const showViewModal = async (ticket) => {
		console.log(ticket)
		navigate('/dashboard/order-details', { state: { data: ticket } });
	}

	const hideModal = (val) => {
		setShowModal(false);
	}

	const showReviewModal = (order) => {
		setShowApprovalModal(true);
		setCurrentOrder(order);
	}

	const handleShowCancelModal = (order) => {
		setShowCancelModal(true)
		setCurrentOrder(order);
	}

	const handleOrderApproval = async (values) => {
		console.log(values)
		if (currentOrder.order_id) {
			// console.log('order: ', currentOrder);
			setShowApprovalModal(false);
			let reviewStatus = OrderReviewStatus.Pending;
			let resp = {};
			if (userType === UserType.Admin) {
				reviewStatus = values.approved === true ? OrderReviewStatus.AdminAccepted : OrderReviewStatus.AdminRejected;
				resp = await updateOrder({
					order_id: currentOrder.order_id,
					order_admin_approval: reviewStatus,
					comments: values.comments,
					admin_id: currentUser.user_id
				});
			}
			else {
				reviewStatus = values.approved === true ? OrderReviewStatus.SellerApproved : OrderReviewStatus.SellerRejected;
				resp = await updateOrder({
					order_id: currentOrder.order_id,
					order_vendor_approval: reviewStatus,
					comments: values.comments,
					seller_id: currentUser.user_id
				});
			}

			if (resp) {
				if (resp.status === 200) {
					// console.log('update order resp : ', resp);
					toastSuccess("Status Updated Successfully!")
					await getAllOrders();
				} else {
					console.log('500!');
				}
			}
		}
	}

	const handleOrderComplete = async (order) => {
		//setCurrentOrder(order)
		if (order.order_id) {
			let resp = await updateOrder({
				order_id: order.order_id,
				order_vendor_approval: OrderReviewStatus.Completed,
				seller_id: currentUser.user_id
			});

			if (resp) {
				if (resp.status === 200) {
					// console.log('update order resp : ', resp);
					toastSuccess("Order completed Successfully!")
					await getAllOrders();
				} else {
					console.log('500!');
				}
			}
		}
	}

	const showVendors = async (item) => {
		// console.log('Item : ', item);
		let payload = { product_type: item.order_product_type, product_brand: item.order_product_brand, product_model: item.order_product_model, product_isnew: item.order_product_isnew, page: activePage }
		let resp = await getAllVendorProducts(payload);
		// console.log('Resp: ', resp);

		let userArr = '';
		resp.data.map(i => {
			userArr += i.user_id + ','
		})

		console.log(userArr.replace(/,\s*$/, ""));

		let user_ids = userArr.replace(/,\s*$/, "");
		let vendors_resp = await getAllVendorsGroup({ user_id: user_ids, product_type: item.order_product_type, product_brand: item.order_product_brand, product_model: item.order_product_model, product_isnew: item.order_product_isnew });
		// console.log('vendors_resp: ', vendors_resp);
	}

	const getOrderReviewStatus = (reviewStatus) => {
		switch (reviewStatus) {
			case OrderReviewStatus.Pending:
				return { style: 'badge-light', name: 'Pending' };
			case OrderReviewStatus.AdminAccepted:
				return { style: 'badge-light-primary', name: 'AdminAccepted' };
			case OrderReviewStatus.AdminRejected:
				return { style: 'badge-light-danger', name: 'AdminRejected' };
			case OrderReviewStatus.SellerApproved:
				return { style: 'badge-light-success', name: 'SellerApproved' };
			case OrderReviewStatus.SellerRejected:
				return { style: 'badge-light-warning', name: 'SellerRejected' };
			case OrderReviewStatus.Completed:
				return { style: 'badge-light-info', name: 'Completed' };
			case OrderReviewStatus.ReOrder:
				return { style: 'badge-light-danger', name: 'ReOrder' };
			default:
				return "";
		}
	}

	const validateInput = (values) => {
		const errors = {}
		if (!values.comments) {
			errors.comments = 'Comments cannot be empty!';
		} else if (values.comments.length > 250) {
			errors.comments = "Comments cannot be exceeds 250 characters!";
		}
		return errors;
	}

	const getCurrentUserStatus = () => {
		let current_user = getUser();
		//setCurrentUser(current_user);
		console.log(current_user);
		return current_user.user_type === UserType.SuperAdmin || current_user.user_type === UserType.Admin || current_user.mode === UserMode.Seller;
	}

	const searchFilter = async (values) => {
		setKeyword(values.keyword);
		getOrdersByPage(0, values.keyword)
	}

	const removeSearchFilters = () => {
		setKeyword('');
		getOrdersByPage(0, '');
	}


	const handleCancelOrder = async (values) => {
		if (values.order_id) {
			let resp = await cancelOrder({
				order_id: values.order_id,
				user_id: currentUser.user_id,
				comments: values.comments
			});


			if (resp.status === 200) {
				setShowCancelModal(false)
				// console.log('cancel order resp : ', resp);
				toastSuccess("Order canceled Successfully!")
				await getAllOrders();
			} else {
				toastError(Something_Went_Wrong + resp.msg);
			}

		}
	}

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{props.description}
		</Tooltip>
	);

	return (
		<>
			{
				isLoading ? <Loader />
					:
					<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
						<div className="d-flex flex-column flex-column-fluid">

							{/* Breadcrumb Start*/}
							<div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
								<div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
									<div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
										<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Orders</h1>
										<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
											<li className="breadcrumb-item text-muted">
												<Link to="/dashboard/orders" className="text-muted text-hover-primary">Dashboard</Link>
											</li>
											<li className="breadcrumb-item">
												<span className="bullet bg-gray-400 w-5px h-2px"></span>
											</li>
											<li className="breadcrumb-item text-muted">Orders</li>
										</ul>
									</div>
								</div>
							</div>
							{/* Breadcrumb End */}

							<div id="kt_app_content" className="app-content flex-column-fluid">
								<div id="kt_app_content_container" className="app-container container-fluid">
									<div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
										{currentUser?.user_type <= 1 ?
											<Formik
												initialValues={{ keyword: keyword }}
												enableReinitialize
												onSubmit={(values, { setSubmitting, resetForm }) => {
													setTimeout(() => {
														searchFilter(values);
													}, 500);
													return false;
												}}
											>
												{({
													values,
													errors,
													touched,
													handleChange,
													handleBlur,
													handleSubmit,
													isSubmitting,
												}) => (
													<form onSubmit={handleSubmit}>
														<div className="col-md-12 mb-5 order">
															<div className="search">
																<i className="fa fa-search"></i>
																<input type="text" name="keyword" className="form-control" onChange={handleChange} onBlur={handleBlur} value={values.keyword} placeholder="Search by OrderId" />
																{
																	values.keyword.length > 0 ?
																		<button onClick={() => removeSearchFilters()} style={{ 'margin-right': '70px', 'width': '50px', 'background': 'transparent' }} className="btn border-left-0 rounded-0 rounded-right btn-sm" type="button">
																			<i className="fa fa-times"></i>
																		</button>
																		:
																		null
																}
																<button type="submit" className="btn btn-primary btn-sm">Search</button>
															</div>
														</div>
													</form>
												)}
											</Formik> : null}
										<div className="col-xl-12 mt-0">
											<div className="card card-flush h-md-100">
												<div className="card-header pt-7">
													{<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-800"></span>
													</h3>}

													<span className="card-label fw-bold text-gray-800">Total: {totalProducts}</span>
												</div>
												<div className="card-body pt-6">

													<div className="table-responsive">
														<table id="tbl_tickets" className="table table-row-dashed  align-middle gs-0 gy-3 my-0">
															<thead>
																<tr className="fs-7 fw-bold text-gray-400 orders_table  border-bottom-0">
																	<th className="py-3 pb-3 text-nowrap  text-start" >ORDER #</th>
																	{/* <th className="py-3 pb-3 min-w-120px text-start">Approved By Admin?</th> */}
																	<th className="py-3 pb-3 text-nowrap text-gray-400 text-end" >ORDER STATUS</th>
																	<th className="py-3 pb-3 text-nowrap   text-end" >ORDERED DATE</th>
																	{(getCurrentUserStatus()) ?
																		<> <th className="py-3 pb-3   text-nowrap text-end">BUYER NAME</th></> : null
																	}

																	<th className="py-3 pb-3     text-nowrap text-end" >BUYER EMAIL</th>

																	<th className="py-3 pb-3  text-nowrap  text-end" >PRIMARY NO</th>

																	<th className="py-3 pb-3   text-nowrap text-end" >ALTERNATE NO</th>

																	<th className="py-3 pb-3  text-nowrap text-end">CANCELLED BY BUYER?</th>

																	{/* <th className="py-3 pb-3 min-w-120px text-start"></th>
																	{(getCurrentUserStatus()) ?
																		<> <th className="py-3 pb-3 min-w-100px text-start">CANCELED BY</th></> : null
																	} */}
																	{
																		(userType <= UserType.Admin) ?
																			<th className="py-3 pb-3   text-end">ACTIONS</th>
																			:
																			null
																	}
																</tr>
															</thead>
															<tbody>
																{orders?.length == 0 ? <div className='text-start'>No Orders Found!</div> : ""}
																{
																	orders?.map((order, i) => {
																		return (
																			<>
																				<tr key={i}>
																					<td className="text-start p-0" >
																						<span className="text-dark fw-bold fs-7">Order-{order.order_id}</span>
																					</td>
																					{/* <td className="text-end p-0">
																						<span className={`badge py-3 px-4 fs-7 ${getOrderReviewStatus(order.order_admin_approval).style} `}>{getOrderReviewStatus(order.order_admin_approval).name} </span>
																					</td> */}
																					<td className="text-end p-0">
																						<span className={`badge py-3 px-4 fs-7 ${getOrderReviewStatus(order.order_vendor_approval).style} `}>{getOrderReviewStatus(order.order_vendor_approval).name} </span>
																					</td>
																					<td className="text-end p-0">
																						<span className="text-gray-600 fw-bold fs-7">{formatDateString(order.order_created_at)}</span>
																					</td>
																					{(getCurrentUserStatus()) ?
																						<> <td className=" py-3  text-gray-600 fw-bold fs-7 text-center text-nowrap">{order.user_fname + " " + order.user_lname}</td>
																						</> : null
																					}

																					<td className="text-end p-0">
																						<span className="text-gray-600 fw-bold fs-7">{order.user_email}</span>
																					</td>


																					<td className="text-end p-0 px-3">
																						<span className="text-gray-600 fw-bold fs-7">{order.user_contact_1}</span>
																					</td>

																					<td className="text-end p-0">
																						<span className="text-gray-600 fw-bold fs-7">{order.user_contact_2}</span>
																					</td>

																					<td className="text-end p-0">
																						<span className={`badge py-3 px-4 fs-7 ${order.is_buyer_cancel === true ? 'badge-light-danger' : ''} `}>{order.is_buyer_cancel === true ? 'Yes' : ''}
																						</span>
																						{order.is_buyer_cancel === true ? <OverlayTrigger
																							placement="bottom"
																							delay={{ show: 250, hide: 400 }}
																							overlay={renderTooltip({ description: 'Canceled At:' + formatDateString(order.canceled_at) + ' Canceled By: ' + order.canceled_user_fname + " " + order.canceled_user_lname + '(' + order.canceled_user_email + ')' })}>
																							<i className="bi bi-info-circle"></i>
																						</OverlayTrigger> : null
																						}

																					</td>

																					<td className="text-end p-0" >
																						<div className='d-flex '>
																							<div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-3" data-bs-toggle="modal" data-bs-target="#kt_modal" onClick={() => showViewModal(order)} title="View Order">
																								<span className="svg-icon svg-icon-5 svg-icon-gray-700">
																									<i className='fa fa-eye' />
																								</span>
																							</div>
																							{(currentUser.user_type === UserType.Admin && order.order_admin_approval === OrderReviewStatus.Pending)
																								|| (currentUser.user_type === UserType.User && currentUser.mode === UserMode.Seller && order.order_admin_approval === OrderReviewStatus.AdminAccepted && order.order_vendor_approval === OrderReviewStatus.Pending) ?
																								<div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" title="Review" onClick={() => showReviewModal(order)}><i className={'fa fa-comments'}></i></div> : null
																							}
																							{/* {(userType > UserType.Admin && currentUser.mode === UserMode.Seller && order.order_admin_approval === OrderReviewStatus.AdminAccepted !== OrderReviewStatus.SellerApproved) ?
																							<div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" title="Review" onClick={() => showReviewModal(order)}><i className={'fa fa-comments'}></i></div> : null
																						} */}
																							{
																								userType > UserType.Admin && currentUser.mode === UserMode.Seller && order.order_vendor_approval === OrderReviewStatus.SellerApproved ?
																									<div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" title="Mark as Complete" onClick={() => handleOrderComplete(order)}><i className={'fa fa-check'}></i></div> : null
																							}
																							{(userType > UserType.Admin && currentUser.mode === UserMode.Buyer && order.is_buyer_cancel === false) ?
																								<div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" title="Cancel Order" onClick={() => handleShowCancelModal(order)}><i className={'fa fa-times'}></i></div> : null
																							}
																						</div>

																					</td>
																				</tr>
																			</>
																		)
																	})
																}
															</tbody>
														</table>
													</div>
													{orders?.length == 0 ? null :
														<Pagination
															activePage={activePage}
															itemsCountPerPage={pageSize}
															totalItemsCount={totalProducts ? totalProducts : 0}
															pageRangeDisplayed={pageSize}
															onChange={handlePageChange}
															itemClass="page-item"
															linkClass="page-link"
														/>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<ModalBox
							showModal={showModal}
							hideModal={() => hideModal(false)}
							title={"Order Details"}>
							{
								<>
									<div className="table-responsive">
										<table id="tbl_tickets" className="table table-row-dashed align-middle gs-0 gy-3 my-0">
											<thead>
												<tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
													<th className="p-0 pb-3 min-w-100px text-start">#</th>
													<th className="p-0 pb-3 min-w-100px text-start">TYPE</th>
													<th className="p-0 pb-3 min-w-100px text-start">BRAND</th>
													<th className="p-0 pb-3 min-w-100px text-start">MODEL</th>
													<th className="p-0 pb-3 min-w-100px text-start">QTY</th>
													<th className="p-0 pb-3 min-w-100px text-start">PRICE</th>
													<th className="p-0 pb-3 min-w-100px text-start">NEW/USED</th>

													<th className="p-0 pb-3 min-w-120px text-start">VENDORS</th>
													{/* <th className="p-0 pb-3 min-w-120px text-start">CREATED AT</th> */}
													{/* <th className="p-0 pb-3 min-w-100px text-start">VIEW</th> */}
												</tr>
											</thead>
											<tbody>
												{
													orderProducts?.map((o, i) => {
														return (
															<>
																<tr key={i}>
																	<td className="text- p-0" >
																		<span className="text-gray-600 fw-bold fs-7">{i + 1}</span>
																	</td>

																	<td className="text-start p-0" >
																		<span className="text-gray-600 fw-bold fs-7">{o.order_product_type}</span>
																	</td>

																	<td className="text-start p-0" >
																		<span className="text-gray-600 fw-bold fs-7">{o.order_product_brand}</span>
																	</td>

																	<td className="text-start p-0" >
																		<span className="text-gray-600 fw-bold fs-7">{o.order_product_model}</span>
																	</td>

																	<td className="text-start p-0" >
																		<span className="text-gray-600 fw-bold fs-7">{o.order_product_qty}</span>
																	</td>
																	<td className="text-start p-0" >
																		<span className="text-gray-600 fw-bold fs-7">{o.order_product_price}</span>
																	</td>

																	<td className="text-start p-0">
																		<span className={`badge py-3 px-4 fs-7 ${o.product_isnew === 1 ? 'badge-light-primary' : null} ${o.product_isnew === 2 ? 'badge-light-success' : null} `}>{o.product_isnew === 1 ? 'New' : null} {o.product_isnew === 2 ? 'Used' : null} </span>
																	</td>

																	<td className="text-start p-0" >
																		<button className='btn btn-primary' onClick={() => { showVendors(o) }}>Vendors</button>
																	</td>

																	{/* <td className="text-start p-0">
																<span className={`badge py-3 px-4 fs-7 ${order.order_admin_approval === 0 ? 'badge-light-primary' : null} ${order.order_admin_approval === 1 ? 'badge-light-success' : null} `}>{order.order_admin_approval === 0 ? 'Pending' : null} {order.order_admin_approval === 1 ? 'Approved' : null} </span>
															</td> */}

																	{/* <td className="text-start p-0">
																<span className="text-gray-600 fw-bold fs-7">{order.created_at}</span>
															</td> */}


																</tr>
															</>
														)
													})
												}
											</tbody>
										</table>
									</div>
								</>
							}
						</ModalBox>


						{showApprovalModal ? (
							<ModalBox
								showModal={showApprovalModal}
								hideModal={() => setShowApprovalModal(false)}
								title="Review Order"
							>
								<Formik
									initialValues={{
										comments: "",
										approved: false
									}}
									validate={validateInput}
									// validationSchema={addTaskSchema}
									enableReinitialize
									onSubmit={(values, { resetForm }) => {
										setTimeout(() => {
											handleOrderApproval(values);
											resetForm();
										}, 500);
										return false;
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
									}) => (
										<div
											className="d-flex flex-column flex-root"
											id="kt_app_root"
										>
											<div className="w-md">
												<form onSubmit={handleSubmit}>
													{/* User Name */}
													<div className="row mb-6">
														<label className="col-lg-4 col-form-label fw-semibold fs-6">
															<span>Comments:</span>
														</label>
														<div className="col-lg-8 fv-row">
															<input
																type="text"
																placeholder=""
																name="comments"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.comments}
																autoComplete="off"
																className="form-control bg-transparent"
															/>
															{errors.comments && touched.comments ? <div className="error">{errors.comments}</div> : null}
														</div>
													</div>

													<div className="text-center">
														<button
															type="reset"
															id="kt_modal_new_target_cancel"
															className="btn  btn-sm btn-light me-3"
															onClick={() => setShowApprovalModal(false)}
														>
															Close
														</button>
														<button
															type="submit"
															disabled={!values.comments}
															id="kt_modal_new_target_submit"
															// onClick={handleClickSubmit}
															className="btn btn-sm btn-success me-3"
															onClick={() => {
																values.approved = true;
																if (values.comments) {
																	handleOrderApproval(values);
																}
															}
															}
														>
															<span className="indicator-label">
																Accept
															</span>
															<span className="indicator-progress">
																Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
															</span>
														</button>
														<button
															type="submit"
															disabled={!values.comments}
															id="kt_modal_new_target_submit"
															// onClick={handleClickSubmit}
															className="btn  btn-sm btn-danger"
															onClick={() => {
																values.approved = false;
																handleOrderApproval(values);
															}
															}
														>
															<span className="indicator-label">
																Reject
															</span>
															<span className="indicator-progress">
																Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
															</span>
														</button>
													</div>
												</form>
											</div>
										</div>
									)}
								</Formik>

							</ModalBox>
						) : null}


						{showCancelModal ? (
							<ModalBox
								showModal={showCancelModal}
								hideModal={() => setShowCancelModal(false)}
								title="Cancel Order"
							>
								<Formik
									initialValues={{
										comments: "",
										order_id: currentOrder.order_id
									}}
									validate={validateInput}
									// validationSchema={addTaskSchema}
									enableReinitialize
									onSubmit={(values, { resetForm }) => {
										setTimeout(() => {
											handleCancelOrder(values);
											resetForm();
										}, 500);
										return false;
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
									}) => (
										<div
											className="d-flex flex-column flex-root"
											id="kt_app_root"
										>
											<div className="w-md">
												<form onSubmit={handleSubmit}>
													{/* User Name */}
													<div className="row mb-6">
														<label className="col-lg-12 col-form-label fw-semibold fs-6">

															<span>Do you want to cancel the order? Enter your comment:</span>
														</label>
														<div className="col-lg-12 fv-row">
															<Form.Control as="textarea" rows={3}
																name="comments"
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.comments}>
															</Form.Control>
															{errors.comments && touched.comments ? <div className="error">{errors.comments}</div> : null}
														</div>
													</div>

													<div className="text-center">
														<button
															type="reset"
															id="kt_modal_new_target_cancel"
															className="btn  btn-sm btn-light me-3"
															onClick={() => setShowCancelModal(false)}
														>
															Close
														</button>
														<button
															type="submit"
															disabled={isSubmitting}
															id="kt_modal_new_target_submit"                                                                            // onClick={handleClickSubmit}
															className="btn btn-primary">
															<span className="indicator-label">
																Submit
															</span>
															<span className="indicator-progress">
																Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
															</span>
														</button>
													</div>
												</form>
											</div>
										</div>
									)}
								</Formik>

							</ModalBox>
						) : null}

					</div>
			}
		</>
	)
}

export default DashboardOrdersPage;