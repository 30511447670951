import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Carousel from "react-bootstrap/Carousel";
import Form from "react-bootstrap/Form";
import { APIBaseURL } from "../../constants/ApiEndPoints";
import { getAllActiveAds } from "../../services/AdsService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import {
  getAllStockProducts,
  getAllStockProductsByKeyword,
  getAllCategories,
} from "../../services/ProductServices";
import {
  saveRequirement,
  updateRequirement,
  getAllRequirements,
} from "../../services/RequirementService";
import Loader from "../../shared/Loader";
import ModalBox from "../../shared/Modal";
import { toastSuccess, toastError } from "../../shared/Toast";
import { saveEnquiry } from "../../services/EnquiryServices";
import { getUserData, getUser } from "../../shared/UserUtil";
import { CommentType, RecordType, UserType } from "../../shared/Constants";
import {
  getProductStageClass,
  getProductStageName,
} from "../../shared/ProductUtil";
import BootstrapSelect from "react-bootstrap-select-dropdown";

// import { giveawayicon } from "../../assets/images/giveaway.png";
const giveawayicon = require("../../assets/images/giveaway.png");
const producticon = require("../../assets/images/productimg.png");
const ViewProducts = () => {
  const [products, setProducts] = useState([]);
  const [productFeature, setProductFeature] = useState([]);
  useEffect(() => {
    let productFeatureSplit = products?.map((item) => item?.product_feature_list?.split(","))
    setProductFeature(productFeatureSplit?.map((item, i) => {
      return item
    }))

  }, [products])
  console.log(productFeature?.map((item) => { return item }))
  const [isLoading, setIsLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  let pageSize = 12;
  const [showEnquiry, setShowEnquiry] = useState(false);
  const typeRef = useRef(null);

  const [showNewRequirementModal, setShowNewRequirementModal] = useState(false);
  const [currentRequirement, setCurrentRequirement] = useState(null);
  const [categoryList, setCategoryList] = useState([]);

  const [requirementList, setRequirementList] = useState([]);
  const [activeRequirementPage, setActiveRequirementPage] = useState(0);
  const [totalRequirements, setTotalRequirements] = useState(0);
  const [adsList, setAdsList] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);


  const handleHideEditModal = () => {
    setShowNewRequirementModal(false);
  };

  const fetchLookups = async () => {
    let categories = await getAllCategories("category_name");
    setCategoryList(categories.data);
  };

  const validateRequirementInput = (values) => {
    const errors = {};
    if (!values.product_name) {
      errors.product_name = "Product cannot be empty!";
    }

    if (!values.product_brand) {
      errors.product_brand = "Product Brand cannot be empty!";
    }

    if (!values.product_model) {
      errors.product_model = "Product Model cannot be empty!";
    }

    if (!values.description) {
      errors.description = "Description cannot be empty";
    }

    if (
      !values.product_category_id ||
      parseInt(values.product_category_id) <= -1
    ) {
      errors.product_category_id = "Select a Category";
    }
    return errors;
  };

  const handleSaveRequirement = async (values) => {
    console.log(values);
    let currentUser = getUserData();
    values.user_id = currentUser.user.user_id;
    values.company_id = currentUser.user.company_id;

    let response = {};
    if (values.new_requirement_id > 0) {
      response = await updateRequirement(values);
    } else {
      response = await saveRequirement(values);
    }
    if (response.status === 200) {
      toastSuccess(
        `New Requirement ${values.new_requirement_id > 0 ? "Updated" : "Added"
        } Successfully!`
      );
      setShowNewRequirementModal(false);
      setCurrentRequirement(null);
      return true;
    } else {
      toastError(response.message);
      return false;
    }
  };

  const getPayload = (pageNumber, search) => {
    let current_user_data = getUserData();
    return {
      user_id: current_user_data.user.user_id,
      page: pageNumber,
      page_size: pageSize,
      company_id: current_user_data.user.company_id,
      keyword: search != null ? search : selectedKeyword.product_model,
    };
  };

  useEffect(() => {
    fetchAllStockProducts(getPayload(activePage));
    fetchLookups();
    fetchAllRequirements(0);
    fetAllAds();
  }, []);

  const fetAllAds = async () => {
    const adsList = await getAllActiveAds({});
    console.log(adsList);
    //setParentCategoryList(categoryList.data.filter(f => f.parent_category_id === null));
    setAdsList(adsList.data);
  };

  const fetchAllRequirements = async (page) => {
    let payload = {};
    let user = getUser();
    if (user.user_type === UserType.User) {
      payload = {
        company_id: user.company_id,
        page: page ? page : 0,
        is_admin_approved: true,
        is_exclude_product: true,
      };
    } else {
      payload = { company_id: user.company_id, page: page ? page : 0 };
    }
    let requirements = await getAllRequirements(payload);
    console.log(requirements);
    setRequirementList(requirements.data);
    setTotalRequirements(requirements.total);
  };

  useEffect(() => { }, [activePage, totalProducts]);

  const renderTooltip = (props) => (
    <Tooltip id={"button-tooltip" + props.index.toString()} {...props}>
      {props.description}
    </Tooltip>
  );

  const removeSearchFilters = () => {
    setKeyword("");
    setSelectedKeyword("");
    setActivePage(1);
    fetchAllStockProducts(getPayload(0, ""));
  };

  const fetchAllStockProducts = async (payload) => {
    setIsLoading(true);
    const products = await getAllStockProducts(payload);
    setProducts(products.data);
    setTotalProducts(products.total);
    // if (products.total <= 0) {
    //     setShowEnquiry(true);
    // }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (selectedKeyword.length) {
      if (selectedKeyword[0].record_type === 2) {
        fetchAllStockProducts(getPayload(1, selectedKeyword[0].title));

      } else {
        fetchAllStockProducts(getPayload(1, selectedKeyword[0].product_model));
      }
    } else if (keyword.length) {
      fetchAllStockProducts(getPayload(1, keyword));

    }
    // console.log("hello",selectedKeyword,keyword)


  }, [keyword, selectedKeyword]);

  const searchFilter = async (keyword) => {
    console.log(keyword);
    if (!keyword) keyword = typeRef.current.state.text;
    setIsLoading(true);
    setKeyword(keyword);
    setActivePage(1);
    // if(!selectedKeyword.length) {
    // fetchAllStockProducts(getPayload(1, keyword));

    // }
  };

  const handlePageChange = async (pageNumber) => {
    setIsLoading(true);
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
    const resp_companies = await getAllStockProducts(getPayload(pageNumber));
    setProducts(resp_companies.data);
    setTotalProducts(resp_companies.total);
    setIsLoading(false);
  };

  const handleRequirementPageChange = async (pageNumber) => {
    fetchAllRequirements(pageNumber);
    setActiveRequirementPage(pageNumber);
  };

  // const refreshPage = () => {
  //     window.location.reload();
  // }

  const submitEnquiry = async (values) => {
    console.log(values);
    let user = getUser();
    let response = await saveEnquiry({
      user_id: user.user_id,
      comments: values.comments,
      comment_type: CommentType.Enquiry,
    });
    if (response.status === 200) {
      setShowEnquiry(false);
      toastSuccess("Enquiry posted successfully!");
      return true;
    } else {
      toastError(response.msg || response.message);
      return false;
    }
  };

  const validateEnquiryInput = (values) => {
    console.log(values);
    var errors = {};
    if (!values.comments) {
      errors.comments = "Comments cannot be empty!";
    } else if (values.comments.length > 250) {
      errors.comments = "Comments should be less than 250 characters";
    }
    return errors;
  };

  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const filterBy = () => true;

  const handleSearch = async (query) => {
    setIsSearchLoading(true);
    setInputValue(query);
    const products = await getAllStockProductsByKeyword(getPayload(0, query));
    let productList = products.data.map((element, index) => {
      if (element.record_type === 1 && element.product_brand != null) {
        element.product_brand = element.product_brand;
        return element;
      } else if (element.record_type === 2) {
        element.product_brand = element?.title;
        element.product_isnew = 1;
        return element;
      }
    });

    var res = productList.filter(elements => {

      return (elements?.product_brand != null && elements?.product_brand !== undefined && elements?.product_brand !== "");

    });

    setOptions(res);
    setTimeout(() => {
      setIsSearchLoading(false);
    }, 1000);
  };

  const typeAheadChange = (ev) => {
    console.log(ev);
    setSelectedKeyword(ev);
    if (ev && ev.length > 0) {
      searchFilter(ev[0]?.product_brand.split("||")[0]);
    }
    if (!typeRef.current.state.text) {
      searchFilter(null);
    }
  };
  const keyPressEvent = (event) => {
    console.log("onKeyDown", event.defaultPrevented, event);
    if (event.which === 13) {
      searchFilter(event.target.value);
    }
    //    else if(selectedKeyword && !event.target.value){
    //     searchFilter(null);
    //    }
  };
  const textwrap = (word) => {
    if (word) {
      return word.length > 50 ? word.substring(0, 50) + "..." : word;
    }
  };

  console.log(products);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="app-main flex-column flex-row-fluid mt-8"
          id="kt_app_main"
        >
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid ">
              <div
                id="kt_app_content_container"
                className="app-container container-fluid"
              >
                <div className="col-md-12 mb-5 searchBar-division">
                  <div className="search col-md-9">
                    <AsyncTypeahead
                      ref={typeRef}
                      filterBy={filterBy}
                      id="async-example"
                      isLoading={isSearchLoading}
                      labelKey="product_brand"
                      minLength={2}
                      onSearch={handleSearch}
                      options={options}
                      selected={selectedKeyword}
                      onChange={(ev) => typeAheadChange(ev)}
                      placeholder="Search for products or services ..."
                      defaultInputValue={""}
                      onKeyDown={(event) => {
                        keyPressEvent(event);
                      }}
                      renderMenuItemChildren={(option, index) => (
                        <>
                          <span
                            className={`badge py-3 px-4 fs-7 me-1 ${getProductStageClass(
                              option?.product_isnew
                            )} `}
                          >
                            {getProductStageName(option?.product_isnew)}{" "}
                          </span>

                          <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 ms-2 me-2 text-uppercase">
                            {option?.category_name}
                          </span>
                          <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                            {/* {option?.product_model.toLowerCase().includes(inputValue.toLowerCase()) ? (
                              <>
                                {option?.product_model
                                  .split(inputValue)
                                  .map((part, index) => (
                                    <React.Fragment key={index}>
                                      {index > 0 && (
                                        <i >
                                          {inputValue}
                                        </i>
                                      )}
                                      {part}
                                    </React.Fragment>
                                  ))}
                              </>
                            ) : (
                              option?.product_model
                            )} */}
                            {option?.product_model}
                          </span>

                          <span className="ms-1">{option?.product_type}</span>

                          <span className="badge py-3 px-4 fs-7 badge-light-primary ms-1">
                            &#x20b9; {option?.product_price}
                          </span>
                        </>
                      )}
                    />
                    {selectedKeyword && selectedKeyword.length > 0 ? (
                      <button
                        onClick={() => removeSearchFilters()}
                        style={{
                          "margin-right": "120px",
                          width: "50px",
                          background: "transparent",
                        }}
                        className="btn border-left-0 rounded-0 rounded-right"
                        type="button"
                      >
                        <i className="fa fa-times fs-2"></i>
                      </button>
                    ) : null}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() =>
                        searchFilter(
                          selectedKeyword && selectedKeyword.length > 0
                            ? selectedKeyword[0]?.product_brand
                            : null
                        )
                      }
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-md-3 align-items-center new-req-btn">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => setShowNewRequirementModal(true)}
                    >
                      + New Requirement
                    </button>
                  </div>
                </div>

                <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                  <Carousel>
                    {adsList?.map((element, index) => {
                      return (
                        <Carousel.Item interval={1000}>
                          <a href={"//" + element.hyperlink}>
                            <img
                              className="d-block w-100"
                              src={
                                APIBaseURL + "/files/ads/" + element.filepath
                              }
                              alt="First slide"
                            />
                          </a>

                          {/* <Carousel.Caption>
                                  <h3>First slide label</h3>
                                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                  <h3>Discount: {element.discount}%</h3>
                                  <h3>Price: {element.price}</h3>
                                </Carousel.Caption> */}
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>

                <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                  {products?.map((product, i) => {
                    return (
                      <div className="col-sm-4 col-xxl-3" key={i}>
                        <div className="card product-card card-flush h-xl-100">
                          <div className="card-body product-body text-center pb-5">
                            <div className="align-items-end flex-stack mb-1">
                              <div className="text-start">
                                <span className="cursor-pointer text-hover-primary d-block text-uppercase text-center product-title fs-4">
                                  {product.record_type === RecordType.Product
                                    ? "(" +
                                    product.product_brand +
                                    ") " +
                                    product.product_model
                                    : product.title}
                                </span>

                                {/* <span className="text-gray-600 text-end fw-bold fs-6 text-capitalize">{product.part_number}</span> */}
                              </div>

                              <span className="text-center fw-bold fs-6 text-capitalize prod-desc">
                                {textwrap(product.product_description)}
                              </span>

                              {product.part_number ? (
                                <div className="text-start">
                                  <span className="ms-4 text-start fw-bold fs-6 text-capitalize part_number">
                                    Part No:{" "}
                                  </span>
                                  {product.part_number}
                                </div>
                              ) : null}

                              <div className="product-availability">
                                {product.record_type === RecordType.Product ? (
                                  <span className="mt-1 fw-bold fs-6">
                                    <span className="giveaway-icon">
                                      <img src={giveawayicon} />
                                    </span>
                                    <span className="availabe-quantity">
                                      {" "}
                                      : {product.product_qty}
                                    </span>
                                  </span>
                                ) : null}
                                <span className="record_type">
                                  {" "}
                                  {product.record_type ===
                                    RecordType.Product ? (
                                    <span className="product-image">
                                      <img src={producticon} />
                                    </span>
                                  ) : (
                                    <span className="product-image">
                                      <img src={giveawayicon} />
                                    </span>
                                  )}{" "}
                                  {product.record_type === RecordType.Product
                                    ? "Product"
                                    : "Service"}{" "}
                                  <OverlayTrigger
                                    key={i}
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({
                                      description: product.product_description,
                                      index: i,
                                    })}
                                  >
                                    <span className="tooltip_section">
                                      <i className="bi bi-info-circle"></i>
                                    </span>
                                  </OverlayTrigger>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div >
                            <OverlayTrigger
                              key={i}
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip({
                                description: product?.product_feature_list?.split(",")?.join(",  "),
                                index: i,
                              })}
                            >
                              <span className="p-1 mb-2 text-start d-flex flex-wrap gap-2">
                                {
                                  product?.product_feature_list?.split(",")?.slice(0, 3)?.map((item) => (
                                    <span className="border border-2 rounded px-2 py-2 text-muted fs-8 ">
                                      {item}

                                    </span>
                                  ))
                                }
                              </span>
                            </OverlayTrigger>
                          </div>




                          {/* <span className="border border-2 rounded px-2 py-2 text-muted fs-8 ">

                            </span> */}


                          <div className="card-footer prod-cardfooter d-flex flex-stack pt-0">
                            <a
                              className="btn btn-sm btn-primary flex-shrink-0 me-2 btn-hover-scale w-100 btnview-vendor"
                              data-bs-target="#kt_modal_bidding"
                              onClick={() =>
                                navigate(`/dashboard/product-details`, {
                                  state: { details: product },
                                })
                              }
                            >
                              View Vendors
                            </a>
                            {/* <a className="btn btn-sm btn-light flex-shrink-0" onClick={() => navigate(`/dashboard/product-details`, { state: { details: product } })} >View Item</a> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {products?.length === 0 ? (
                    <div>
                      <div className="no_data">No Products Found.</div>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowEnquiry(true)}
                      >
                        Submit Enquiry
                      </button>
                      <div>
                        <button
                          className="btn btn-secondary btn-sm mt-10"
                          onClick={() => searchFilter(null)}
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {products.length > 0 ? (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={pageSize}
                    totalItemsCount={totalProducts ? totalProducts : 0}
                    pageRangeDisplayed={pageSize}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                ) : null}

                {requirementList?.length > 0 ? (
                  <div className="row g-5 g-xl-10 mb-5 mb-xl-10 coming-soonSection">
                    <h4 className="soon-txt">Coming Soon</h4>
                    {requirementList?.map((product, i) => {
                      return (
                        <div className="col-sm-4 col-xxl-3" key={i}>
                          <div className="card product-card card-flush h-xl-100">
                            <div className="card-body product-body text-center pb-5">
                              <div className="align-items-end flex-stack mb-1">
                                <div className="text-start">
                                  <span className="cursor-pointer text-hover-primary d-block text-uppercase text-center product-title">
                                    {"(" +
                                      product.product_brand +
                                      ") " +
                                      product.product_model}
                                  </span>
                                  <span className="text-gray-600 text-end fw-bold fs-6 text-capitalize">
                                    {product.part_number}
                                  </span>{" "}
                                </div>
                                <span className="text-center fw-bold fs-6 text-capitalize prod-desc">
                                  {textwrap(product.description)}
                                </span>
                                <div className="product-availability">
                                  <OverlayTrigger
                                    key={i}
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({
                                      description: product.description,
                                      index: i,
                                    })}
                                  >
                                    <span className="tooltip_section">
                                      <i className="bi bi-info-circle"></i>
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {requirementList?.length > pageSize ? (
                      <Pagination
                        activePage={activeRequirementPage}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={
                          totalRequirements ? totalRequirements : 0
                        }
                        pageRangeDisplayed={pageSize}
                        onChange={handleRequirementPageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div >
      )}
      {
        showEnquiry ? (
          <ModalBox
            showModal={showEnquiry}
            title="Enquiry"
            hideModal={() => {
              setShowEnquiry(false);
            }}
          >
            <Formik
              initialValues={{
                comments: "",
              }}
              validate={validateEnquiryInput}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                setTimeout(() => {
                  console.log("values : ", values);
                  if (submitEnquiry(values) === true) {
                    resetForm();
                  }
                }, 500);
                return false;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div className="d-flex flex-column flex-root">
                  <div className="w-md">
                    <form onSubmit={handleSubmit}>
                      {/* User Type */}
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                          <span>Comments</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="comments"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.comments}
                          ></Form.Control>
                          {errors.comments && touched.comments ? (
                            <div className="error">{errors.comments}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="reset"
                          id="kt_modal_new_target_cancel"
                          className="btn btn-light me-3"
                          onClick={() => setShowEnquiry(false)}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          id="kt_modal_new_target_submit"
                          className="btn btn-primary"
                        >
                          <span className="indicator-label">Submit</span>
                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Formik>
          </ModalBox>
        ) : null
      }
      {
        showNewRequirementModal ? (
          <ModalBox
            className="add_product"
            showModal={showNewRequirementModal}
            hideModal={() => handleHideEditModal()}
            title={"Add New Requirement"}
          >
            <Formik
              initialValues={{
                product_name: currentRequirement?.product_name,
                product_brand: currentRequirement?.product_brand,
                product_model: currentRequirement?.product_model,
                part_number: currentRequirement?.part_number,
                new_requirement_id: currentRequirement?.new_requirement_id,
                description: currentRequirement?.description,
                product_category_id: currentRequirement?.product_category_id,
                is_admin_approved: currentRequirement?.is_admin_approved
                  ? currentRequirement?.is_admin_approved
                  : false,
              }}
              validate={validateRequirementInput}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                setTimeout(() => {
                  console.log("values : ", values);
                  if (handleSaveRequirement(values) === true) {
                    resetForm();
                  }
                }, 500);
                return false;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <div
                  className="d-flex flex-column flex-root"
                  id="kt_app_root_update_product"
                >
                  <div className="w-md">
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                          <span>Product</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            placeholder=""
                            name="product_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.product_name}
                            autoComplete="off"
                            className="form-control bg-transparent"
                          />
                          {errors.product_name && touched.product_name ? (
                            <div className="error">{errors.product_name}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                          <span>Brand</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            placeholder=""
                            name="product_brand"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.product_brand}
                            autoComplete="off"
                            className="form-control bg-transparent"
                          />
                          {errors.product_brand && touched.product_brand ? (
                            <div className="error">{errors.product_brand}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                          <span>Model</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            placeholder=""
                            name="product_model"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.product_model}
                            autoComplete="off"
                            className="form-control bg-transparent"
                          />
                          {errors.product_model && touched.product_model ? (
                            <div className="error">{errors.product_model}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                          <span>Part Number</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            placeholder=""
                            name="part_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.part_number}
                            autoComplete="off"
                            className="form-control bg-transparent"
                          />
                          {errors.part_number && touched.part_number ? (
                            <div className="error">{errors.part_number}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                          <span>Description</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                          ></Form.Control>
                          {errors.description && touched.description ? (
                            <div className="error">{errors.description}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                          <span>Category</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <BootstrapSelect
                            showSearch
                            options={categoryList.map((e) => ({
                              labelKey: e.category_id,
                              value: e.category_name,
                            }))}
                            onChange={(e) =>
                              setFieldValue(
                                "product_category_id",
                                e.selectedKey[0]
                              )
                            }
                          />
                          {/* <Form.Select
                          name="product_category_id"
                          onChange={(e) => {
                            console.log(e)
                            handleChange(e);
                            // values.product_subcategory_id = "-1";
                            // let subCategories = allCategoryList.filter(f => f.parent_category_id === parseInt(e.target.value));
                            // setSubcategoryList(subCategories);
                          }}
                          value={values.product_category_id}
                        >
                          <option value="-1">Select Category</option>
                          {categoryList?.map((element, index) => {
                            return (
                              <option key={index} value={element.category_id}>
                                {element.category_name}
                              </option>
                            );
                          })}
                        </Form.Select> */}
                          {errors.product_category_id &&
                            touched.product_category_id ? (
                            <div className="error">
                              {errors.product_category_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="reset"
                          id="kt_modal_new_target_cancel"
                          className="btn btn-light me-3"
                          onClick={() => setShowNewRequirementModal(false)}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          id="kt_modal_new_target_submit" // onClick={handleClickSubmit}
                          className="btn btn-primary"
                        >
                          <span className="indicator-label">Submit</span>
                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Formik>
          </ModalBox>
        ) : null
      }
    </>
  );
};

export default ViewProducts;