import axios from "axios";
import { saveAs } from 'file-saver'
import { getAllUsersGroupURL, getAllUsersURL, addUserURL, deleteUserURL, getUsersURL, 
  updateUserURL, uploadUsersURL, updateTermsUserURL, downloadUsersURL, refreshTokenURL,
  updatePasswordURL, updateBlockedURL } from "../constants/ApiEndPoints";
import { formatDate } from "../shared/DateUtil";


export const getAllUsersGroup = async (payload) => {
  try {
    const response = await axios.post(`${getAllUsersGroupURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getAllUsers = async (payload) => {
  try {
    const response = await axios.post(`${getAllUsersURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const addUser = async (payload) => {
  try {
    const response = await axios.post(`${addUserURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updateTerms = async (payload) => {
  try {
    const response = await axios.put(`${updateTermsUserURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updateBlocked = async (payload) => {
  try {
    const response = await axios.put(`${updateBlockedURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updateUser = async (payload) => {
  try {
    const response = await axios.put(`${updateUserURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const deleteUser = async (payload) => {
  try {
    const response = await axios.put(`${deleteUserURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getUserProfile = async (payload) => {
  try {
    const response = await axios.post(`${getUsersURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const uploadUsers = async (payload) => {
  try {
    const response = await axios.post(`${uploadUsersURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const downloadUsers = async (payload) => {
  try {
    const response = await axios.post(`${downloadUsersURL}`, payload, {responseType: 'blob'});
    if (response.status === 200) {
      let downloadURL = window.URL.createObjectURL(response.data);
      saveAs(downloadURL, "Users_"+formatDate(new Date(), 'default')+".csv");      
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const refreshToken = async (payload) => {
  try {
    const response = await axios.post(`${refreshTokenURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updatePassword = async (payload) => {
  try {
    const response = await axios.put(`${updatePasswordURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

