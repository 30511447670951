import React, { useState, useRef, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BootstrapSelect from "react-bootstrap-select-dropdown";
// import Multiselect from 'react-bootstrap-multiselect'
import Select from "react-select";
import { Check, Info } from 'react-bootstrap-icons';
import {
  getAllVendorProducts,
  uploadStockProducts,
  updateVendorProduct,
  deleteVendorProduct,
  downloadFile,
  addVendorProduct,
  approveProduct,
  updateVendorService,
  addVendorService,
  uploadStockServices,
  getAllCategories,
  downloadTemplate,
  productsDeliveryMethod,
} from "../../services/ProductServices";
import ModalBox from "../../shared/Modal";
import CSVParse from "../../components/CSVParse";
import Loader from "../../shared/Loader";
import { PerPageSize } from "../../constants/CommonConstants";
import { getUser, getUserData } from "../../shared/UserUtil";
import { toastSuccess, toastError } from "../../shared/Toast";
import {
  getProductStageClass,
  getProductStageName,
  getProductStageClassByName,
  getProductStageByName,
} from "../../shared/ProductUtil";
import { UserType, RecordType, CategoryType } from "../../shared/Constants";
import { getUserProfile } from "../../services/UserServices";
import {
  saveRequirement,
  updateRequirement,
  getAllRequirements,
  approveRequirement,
  deleteRequirement,
} from "../../services/RequirementService";
import { getCompany } from "../../services/CompanyService";
import { subscriptionValidationUpload } from "../../services/CompanyService";

const AddProduct = () => {
  const selectInputRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [uploadedProducts, setUploadedProducts] = useState([]);
  const [childRefreshFunction, setChildRefreshFunction] = useState(null);
  const [activePage, setActivePage] = useState(0);
  let currentUser = getUser();
  //console.log(currentUser);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [noProducts, setNoProducts] = useState([]);
  const [noTotalProducts, setNoTotalProducts] = useState(0);
  const [noActivePage, setNoActivePage] = useState(0);
  const [activeTab, setActiveTab] = useState("first");
  const [showEditServiceModal, setShowEditServiceModal] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [showUploadServiceModal, setShowUploadServiceModal] = useState(false);
  const [uploadedServices, setUploadedServices] = useState([]);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  //const [subCategoryList, setSubcategoryList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [requirementList, setRequirementList] = useState([]);
  const [totalRequirements, setTotalRequirements] = useState(0);
  const [reqActivePage, setReqActivePage] = useState(0);
  const [showEditRequirementModal, setShowEditRequirementModal] =
    useState(false);
  const [currentRequirement, setCurrentRequirement] = useState(null);
  const [showDeleteRequirementModal, setShowDeleteRequirementModal] =
    useState(false);
  const [showQtyRequirementModal, setShowQtyEditRequirementModal] =
    useState(false);
  const [product_categories, setProductCategories] = useState([]);
  const [service_categories, setServiceCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [applyKey, setApplyKey] = useState(0);
  const [productFeature, setProductFeature] = useState([]);
  const [productDeliveryMethod, setProductDeliveryMethod] = useState([]);
  const [companyProfile, setCompanyProfile] = useState([]);
  const [remainingProductCount, setRemainingProductCount] = useState(null);
  useEffect(() => {
    fetchCompanyProfile();
  }, []);
  useEffect(() => {
    subscriptionValidation()
  }, [companyProfile?.company_id])
  const subscriptionValidation = async () => {
    const res = await subscriptionValidationUpload({ company_id: companyProfile?.company_id })

    setRemainingProductCount(res?.data?.product_remaining_count)
  }

  const fetchCompanyProfile = async () => {
    setIsLoading(true);
    const resp = await getCompany({ company_id: currentUser.company_id });
    if (resp && resp.data && resp.data.length > 0)
      setCompanyProfile(resp.data[0]);
    setIsLoading(false);
    //setCompanyId(currentUser.company_id);
  }
  useEffect(() => {
    fetchAllUserProducts(selectedUser);
    fetchLookups();
    if (currentUser.user_type >= UserType.Admin) fetchAllRequirements();
  }, []);

  useEffect(() => {
    productsDeliveryMethod().then((res) => {
      let data = res?.data
      setProductDeliveryMethod(
        data?.map((item) => ({
          value: item.product_feature_id,
          label: item.name
        }))
      )
    })
    // setProductDeliveryMethod(res?.data)
  }, [])

  const handleChangeMultiSelect = (value) => {
    setSelectedOption(
      value?.map((item) => ({
        product_feature_id: item?.value,
        name: item?.label
      }))
    );
    // setSelectedDeliveryMethod( selectedOption)
  };
  const handleChange = (selectedOptions) => {
    console.log(selectedOptions);
  };
  const fetchAllRequirements = async (page) => {
    let payload = {};
    let user = getUser();
    if (user.user_type === UserType.User) {
      payload = {
        company_id: user.company_id,
        page: page ? page : 0,
        is_admin_approved: true,
        is_exclude_product: true,
      };
    } else {
      payload = { company_id: user.company_id, page: page ? page : 0 };
    }
    let requirements = await getAllRequirements(payload);
    console.log(requirements);
    setRequirementList(requirements.data);
  };

  const fetchLookups = async () => {
    let categories = await getAllCategories("category_name");
    setAllCategoryList(categories.data);

    const product_category = categories.data.filter(
      (e) => e.category_type === 1
    );

    let temp = product_category.map((e) => ({
      labelKey: e.category_id,
      value: e.category_name,
    }));
    setProductCategories(temp);

    const service_category = categories.data.filter(
      (e) => e.category_type === 2
    );
    temp = service_category.map((e) => ({
      labelKey: e.category_id,
      value: e.category_name,
    }));

    setServiceCategories(temp);

    if (currentUser.user_type === UserType.Admin) {
      let users = await getUserProfile({
        company_id: currentUser.company_id,
        user_type: UserType.User,
      });
      setUsers(users.data);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.description}
    </Tooltip>
  );

  const getPayload = (pageNumber, isApproved, userId) => {
    let current_user_data = getUserData();
    let payload = {};

    if (current_user_data.user.user_type <= 1) {
      if (userId > 0) {
        payload = {
          company_id: current_user_data.user.company_id,
          page: pageNumber,
          is_admin_approved: isApproved,
          user_id: userId,
        };
      }
      // else if (selectedUser && parseInt(selectedUser)>0) {
      //     payload = { company_id: current_user_data.user.company_id, page: pageNumber, is_admin_approved: isApproved, user_id: selectedUser };
      // }
      else {
        payload = {
          company_id: current_user_data.user.company_id,
          page: pageNumber,
          is_admin_approved: isApproved,
        };
      }
    } else {
      payload = {
        company_id: current_user_data.user.company_id,
        page: pageNumber,
        is_admin_approved: isApproved,
        user_id: current_user_data.user.user_id,
      };
    }
    return payload;
  };

  const fetchAllUserProducts = async (userId) => {
    var pageNumber = userId !== selectedUser ? 1 : activePage;
    if (pageNumber !== activePage) {
      setNoActivePage(pageNumber);
      setActivePage(pageNumber);
    }

    setIsLoading(true);
    let products = await getAllVendorProducts(
      getPayload(pageNumber, true, userId)
    );
    setProducts(products.data);
    setTotalProducts(products.total);

    products = await getAllVendorProducts(
      getPayload(pageNumber, false, userId)
    );
    setNoProducts(products.data);
    setNoTotalProducts(products.total);
    setIsLoading(false);
  };

  const generateStockProducts = async (stock_products) => {
    return new Promise(async (resolve, reject) => {
      let user_id = currentUser.user_id;
      if (selectedUser) user_id = selectedUser;
      let is_admin_approval = 0;
      if (currentUser.user_type <= UserType.Admin) {
        is_admin_approval = 1;
      }
      let resp = await uploadStockProducts({
        products: JSON.stringify(stock_products),
        user_id: user_id,
        company_id: currentUser.company_id,
        is_admin_approval: is_admin_approval,
      });
      console.log(resp);
      if (resp.status === 200) {
        setActiveTab("second");
        return resolve(resp);
      } else {
        return reject(resp.msg || resp.message);
      }
    });
  };

  const generateServices = async (stock_products) => {
    return new Promise(async (resolve, reject) => {
      let user_id = currentUser.user_id;
      if (selectedUser) user_id = selectedUser;
      let is_admin_approval = 1;
      if (currentUser.user_type <= UserType.Admin) {
        is_admin_approval = 1;
      }
      let resp = await uploadStockServices({
        products: JSON.stringify(stock_products),
        user_id: user_id,
        company_id: currentUser.company_id,
        is_admin_approval: is_admin_approval,
      });
      console.log(resp);
      if (resp.status === 200) {
        setActiveTab("second");
        return resolve(resp);
      } else {
        return reject(resp.msg || resp.message);
      }
    });
  };

  // const generateVendorProducts = async (vendor_products) => {
  //     return new Promise(async (resolve, reject) => {
  //         let resp = await uploadVendorProducts({ products: JSON.stringify(vendor_products) });
  //         if (resp.status === 200) {
  //             return resolve(resp);
  //         } else {
  //             return reject("Error2");
  //         }
  //     });
  // }

  // console.log(productDeliveryMethod)
  const handleUploadedProducts = async (data, hasHeader) => {
    if (data.length <= remainingProductCount) {
      let uploadedArr = data;
      if (data.length > 1 && hasHeader) uploadedArr = data.slice(0, -1);
      let datacheck = uploadedArr?.map((item) => {
        let payload = {
          ...item,
          selectedCategory: null,
          isSelectedProducts: false

        }
        productDeliveryMethod?.map((item) => {
          payload = {
            ...payload,
            [item.label]: false
          }
        })
        return payload
      })
      console.log(datacheck)
      if (uploadedArr.length > totalProducts + currentUser.product_count) {
        toastError("Upload limits exceeds, please contact admin if required.");
      } else {
        setUploadedProducts(datacheck);

        toastSuccess("CSV Parsed Successfully!");
      }
    }
    else {
      toastError(`maximun products uploaded remain product count ${remainingProductCount}, please subscribe and upload products`)
    }
  };

  const handleUploadedServices = async (data, hasHeader) => {
    let uploadedArr = data;
    if (data.length > 1 && hasHeader) uploadedArr = data.slice(0, -1);

    if (uploadedArr.length > totalProducts + currentUser.product_count) {
      toastError("Upload limits exceeds, please contact admin if required.");
    } else {
      setUploadedServices(uploadedArr);
      toastSuccess("CSV Parsed Successfully!");
    }
  };

  const getProductStage = (stage) => {
    console.log("test" + stage + "test");
    switch (stage.toLowerCase()) {
      case "n":
      case "new":
      case "1":
        return 1;
      case "u":
      case "used":
      case "2":
        return 2;
      case "r":
      case "refurbished":
      case "3":
        return 3;
      default:
        return stage;
    }
  };

  const submitProducts = async () => {
    //let current_user_data = getUserData();
    let uploadedArr = uploadedProducts;
    let stock_products = [];
    //let vendor_products = [];
    let failed_products = [];
    let failed_categorylist = [];

    await uploadedArr.forEach((e) => {
      if (
        parseInt(e.qty) <= 0 ||
        (e.rate && parseFloat(e.rate) <= 0) ||
        !e.brand ||
        !e.product ||
        !e.modelno ||
        !e.neworold
      ) {
        failed_products.push(e);
      } else {
        try {
          var category = allCategoryList.filter((element, index) => {
            console.log(
              element.category_name.trim().toLowerCase() ===
              e.category?.toLowerCase() &&
              element.parent_category_id === null &&
              element.category_type === CategoryType.Product
            );
            return (
              element.category_name.trim().toLowerCase() ===
              e.category?.toLowerCase() &&
              element.parent_category_id === null &&
              element.category_type === CategoryType.Product
            );
          });
        } catch (err) {
          toastError("Select all categories");
        }

        console.log(category);

        // if (category && category.length) {
        //   e.category_id = category[0].category_id;
        // } else {
        //   failed_categorylist.push(e.category);
        // }

        // var subcategory = allCategoryList.filter((element, index) => { return element.category_name.toLowerCase() === e.subcategory.trim().toLowerCase() && element.parent_category_id !== null && element.category_type === CategoryType.Product });
        // if (subcategory && subcategory.length) {
        //     e.subcategory_id = subcategory[0].category_id;
        // } else {
        //     failed_categorylist.push(e.subcategory);
        // }
        e.subcategory_id = null;
        if (parseInt(e.category_id) > 0) {
          stock_products.push([
            e.product,
            e.brand,
            e.modelno,
            getProductStage(e.neworold),
            e.qty,
            e.rate ? e.rate : 0,
            e.remark,
            e.category_id,
            e.subcategory_id,
            e.partnumber,
            e.id,
          ]);
          //vendor_products.push([current_user_data.user.user_id, e.type, e.brand, e.modelno, getProductStage(e.neworold), e.qty, e.rate, e.remark, e.category_id, e.subcategory_id]);
        }
      }
    });
    if (failed_categorylist.length) {
      toastError("Invalid category name, kindly update for all and retry.");
    } else if (failed_products.length > 0) {
      toastError(
        "Product data has invalid part number, quantity and rate values, cannot be uploaded. Please correct the data and try again."
      );
    } else {
      //, generateVendorProducts(vendor_products)
      await Promise.all([generateStockProducts(stock_products)]).then(
        (values) => {
          console.log(values)
          toastSuccess("Products Uploaded Successfully!");
          setShowModal(false);
          setUploadedProducts([]);
          fetchAllUserProducts(selectedUser);
          if (currentUser.has_auto_approval === true) setActiveTab("first");
          else setActiveTab("second");
        }
      );
    }
  };

  const submitServices = async () => {
    let uploadedArr = uploadedServices;
    let stock_products = [];
    //let vendor_products = [];
    let failed_products = [];
    let failed_categorylist = [];

    await uploadedArr.forEach((e) => {
      if (!e.title || !e.remark || (e.rate && parseFloat(e.rate) <= 0)) {
        failed_products.push(e);
      } else {
        var category = allCategoryList.filter((element, index) => {
          console.log(
            element.category_name.trim().toLowerCase() ===
            e.category?.toLowerCase() &&
            element.parent_category_id === null &&
            element.category_type === CategoryType.Product
          );
          return (
            element.category_name.trim().toLowerCase() ===
            e.category?.toLowerCase() &&
            element.parent_category_id === null &&
            element.category_type === CategoryType.Service
          );
        });
        if (category && category.length) {
          e.category_id = category[0].category_id;
        } else {
          failed_categorylist.push(e.category);
        }
        e.subcategory_id = null;
        // var subcategory = allCategoryList.filter((element, index) => { return element.category_name.toLowerCase() === e.subcategory.trim().toLowerCase() && element.parent_category_id !== null && element.category_type === CategoryType.Service });
        // if (subcategory && subcategory.length) {
        //     e.subcategory_id = subcategory[0].category_id;
        // } else {
        //     failed_categorylist.push(e.subcategory);
        // }
        if (parseInt(e.category_id) > 0) {
          stock_products.push([
            e.title,
            e.remark,
            e.rate ? e.rate : 0,
            2,
            e.category_id,
            e.subcategory_id,
          ]);
          //vendor_products.push([e.title, e.remark, e.rate, 2, e.category_id, e.subcategory_id]);
        }
      }
    });
    if (failed_categorylist.length) {
      toastError("Invalid category name, kindly update for all and retry.");
    } else if (failed_products.length > 0) {
      toastError(
        "Service data has invalid title/description/rate values, cannot be uploaded. Please correct the data and try again."
      );
    } else {
      //, generateVendorProducts(vendor_products)
      await Promise.all([generateServices(stock_products)]).then((values) => {
        if (currentUser.has_auto_approval === true) setActiveTab("first");
        else setActiveTab("second");
        toastSuccess("Services Uploaded Successfully!");
        setShowUploadServiceModal(false);
        setUploadedServices([]);
        fetchAllUserProducts(selectedUser);
      });
    }
  };

  const handlePageChange = async (pageNumber) => {
    setIsLoading(true);
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
    const respProducts = await getAllVendorProducts(
      getPayload(pageNumber, true, selectedUser)
    );
    setProducts(respProducts.data);
    setTotalProducts(respProducts.total);
    setIsLoading(false);
    setActiveTab("first");
  };

  const handleNoPageChange = async (pageNumber) => {
    setIsLoading(true);
    console.log(`active page is ${pageNumber}`);
    setNoActivePage(pageNumber);
    const respProducts = await getAllVendorProducts(
      getPayload(pageNumber, false, selectedUser)
    );
    setNoProducts(respProducts.data);
    setNoTotalProducts(respProducts.total);
    setIsLoading(false);
    setActiveTab("second");
  };

  const handleReqPageChange = async (pageNumber) => {
    setIsLoading(true);
    console.log(`active page is ${pageNumber}`);
    setReqActivePage(pageNumber);
    const respProducts = await fetchAllRequirements(pageNumber);
    setRequirementList(respProducts.data);
    setTotalRequirements(respProducts.total);
    setIsLoading(false);
    setActiveTab("third");
  };

  const handleHideModal = () => {
    setShowModal(false);
    setUploadedProducts([]);
  };

  const handleHideUploadServiceModal = () => {
    setShowUploadServiceModal(false);
    setUploadedServices([]);
  };

  const handleHideEditModal = () => {
    setShowEditModal(false);
  };

  const handleHideEditRequirementModal = () => {
    setShowEditRequirementModal(false);
  };

  const handleHideEditServiceModal = () => {
    setShowEditServiceModal(false);
  };

  const getCategories = () => {
    let categories = allCategoryList.filter(
      (f) => f.parent_category_id === null && f.category_type === 1
    );
    categories.sort((a, b) => {
      let fa = a.category_name.toLowerCase(),
        fb = b.category_name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setCategoryList(categories);
  };

  const handleEditRequirement = (requirement) => {
    console.log(requirement);
    setShowEditRequirementModal(true);
    setCurrentRequirement(requirement);
    if (requirement) {
      setDialogTitle("Edit Requirement");
    } else {
      setDialogTitle("Add Requirement");
    }
    getCategories();
  };

  const handleAddQtyRequirement = (requirement) => {
    console.log(requirement);
    setShowQtyEditRequirementModal(true);
    setCurrentRequirement(requirement);
    if (requirement) {
      setDialogTitle("Edit Requirement");
    } else {
      setDialogTitle("Add Requirement");
    }
  };

  const handleEditProduct = (product) => {
    console.log(product);
    setShowEditModal(true);
    setCurrentProduct(product);
    if (product) {
      setDialogTitle("Edit Product");
    } else {
      setDialogTitle("Add Product");
    }
    getCategories();
  };

  const handleEditService = (service) => {
    console.log(service);
    setShowEditServiceModal(true);
    setCurrentService(service);
    if (service) {
      setDialogTitle("Edit Service");
    } else {
      setDialogTitle("Add Service");
    }

    let categories = allCategoryList.filter(
      (f) => f.parent_category_id === null && f.category_type === 2
    );
    categories.sort((a, b) => {
      let fa = a.category_name.toLowerCase(),
        fb = b.category_name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setCategoryList(categories);
    // let subCategories = allCategoryList.filter(f => f.parent_category_id === parseInt(service?.product_category_id));
    // setSubcategoryList(subCategories);
  };

  const openEditView = (product) => {
    if (product.record_type === RecordType.Product) {
      handleEditProduct(product);
    } else if (product.record_type === RecordType.Service) {
      handleEditService(product);
    }
  };

  const openEditRequirementView = (product) => {
    handleEditRequirement(product);
  };

  const handleApproved = async (event, product) => {
    console.log(event, product);
    product.is_admin_approved = !product.is_admin_approved;
    console.log(product);
    let user_id = currentUser.user_id;
    if (selectedUser) user_id = selectedUser;
    let response = await approveProduct({
      user_id: user_id,
      product_id: [product.product_id],
      is_admin_approved: product.is_admin_approved,
    });
    if (response.status === 200) {
      toastSuccess(
        `Product ${product.is_admin_approved ? "Approved" : "Unapproved"
        } Successfully!`
      );
      return true;
    } else {
      product.is_admin_approved = !product.is_admin_approved;
      toastError(response.message);
      return false;
    }
  };

  const handleRequirementApproved = async (event, requirement) => {
    console.log(event, requirement);
    requirement.is_admin_approved = !requirement.is_admin_approved;
    console.log(requirement);
    let user_id = currentUser.user_id;
    if (selectedUser) user_id = selectedUser;
    let response = await approveRequirement({
      user_id: user_id,
      new_requirement_id: requirement.new_requirement_id,
      is_admin_approved: requirement.is_admin_approved,
    });
    if (response.status === 200) {
      toastSuccess(
        `Requirement ${requirement.is_admin_approved ? "Approved" : "Unapproved"
        } Successfully!`
      );
      return true;
    } else {
      requirement.is_admin_approved = !requirement.is_admin_approved;
      toastError(response.message);
      return false;
    }
  };

  const handleDeleteRequirement = (requirement) => {
    setCurrentRequirement(requirement);
    setShowDeleteRequirementModal(true);
  };

  const handleDeleteProduct = (product) => {
    setCurrentProduct(product);
    setShowDeleteModal(true);
  };

  const handleUpdateRequirement = async (values) => {
    console.log(values);
    let current_user_data = getUserData();
    values.user_id = current_user_data.user.user_id;
    values.company_id = current_user_data.user.company_id;

    if (selectedUser) values.user_id = selectedUser;

    let response = {};
    if (values.new_requirement_id > 0) {
      response = await updateRequirement(values);
    } else {
      response = await saveRequirement(values);
    }
    if (response.status === 200) {
      toastSuccess(
        `Requrement ${values.new_requirement_id > 0 ? "Updated" : "Added"
        } Successfully!`
      );
      setShowEditRequirementModal(false);
      setCurrentRequirement(null);
      fetchAllRequirements(selectedUser);
      setActiveTab("third");
      return true;
    } else {
      toastError(response.message);
      return false;
    }
  };

  const updateProduct = async (values, isQty) => {
    console.log(values);
    let current_user_data = getUserData();
    values.user_id = current_user_data.user.user_id;
    values.company_id = current_user_data.user.company_id;

    if (selectedUser) values.user_id = selectedUser;

    let response = {};
    if (values.product_id > 0) {
      response = await updateVendorProduct(values);
    } else {
      response = await addVendorProduct(values);
    }
    if (response.status === 200) {
      toastSuccess(
        `Product ${values.product_id > 0 ? "Updated" : "Added"} Successfully!`
      );
      if (isQty === true) {
        setShowQtyEditRequirementModal(false);
        setCurrentRequirement(null);
        fetchAllRequirements(0);
      } else {
        setShowEditModal(false);
        setCurrentProduct(null);
        fetchAllUserProducts(selectedUser);

        if (values.is_admin_approved === true) {
          setActiveTab("first");
        } else {
          setActiveTab("second");
        }
      }
      return true;
    } else {
      toastError(response.message);
      return false;
    }
  };

  const updateService = async (values) => {
    console.log(values);
    let current_user_data = getUserData();
    values.user_id = current_user_data.user.user_id;
    values.company_id = current_user_data.user.company_id;
    values.record_type = RecordType.Service;
    if (selectedUser) values.user_id = selectedUser;

    let response = {};
    if (values.product_id > 0) {
      response = await updateVendorService(values);
    } else {
      response = await addVendorService(values);
    }
    if (response.status === 200) {
      toastSuccess(
        `Service ${values.product_id > 0 ? "Updated" : "Added"} Successfully!`
      );
      setShowEditServiceModal(false);
      setCurrentService(null);
      fetchAllUserProducts(selectedUser);
      if (values.is_admin_approved === true) {
        setActiveTab("first");
      } else {
        setActiveTab("second");
      }
      return true;
    } else {
      toastError(response.message);
      return false;
    }
  };

  const handleDeleteProductSubmit = async () => {
    console.log(currentProduct);
    let response = await deleteVendorProduct(currentProduct);
    if (response.status === 200) {
      toastSuccess("Product Deleted Successfully!");
      setShowDeleteModal(false);
      setCurrentProduct(null);
      fetchAllUserProducts(selectedUser);
      return true;
    } else {
      toastError(response.message);
      return false;
    }
  };

  const handleDeleteRequirementSubmit = async () => {
    console.log(currentRequirement);
    currentRequirement.is_deleted = true;
    let response = await deleteRequirement(currentRequirement);
    if (response.status === 200) {
      toastSuccess("Requirement Deleted Successfully!");
      setShowDeleteRequirementModal(false);
      setCurrentRequirement(null);
      fetchAllRequirements(selectedUser);
      return true;
    } else {
      toastError(response.message);
      return false;
    }
  };

  const handleDownloadFile = async (recordType) => {
    let currentUser = getUser();
    setIsLoading(true);
    let payload = {};
    if (currentUser.user_type === UserType.SuperAdmin) {
      payload = { record_type: recordType, is_admin_approved: 1 };
    } else if (currentUser.user_type === UserType.User)
      payload = {
        user_id: currentUser.user_id,
        company_id: currentUser.company_id,
        record_type: recordType,
        is_admin_approved: 1,
      };
    else if (currentUser.user_type === UserType.Admin) {
      payload = {
        company_id: currentUser.company_id,
        record_type: recordType,
        is_admin_approved: 1,
      };
      let userId = currentUser.user_id;
      if (selectedUser > 0) {
        userId = selectedUser;
        payload = {
          user_id: userId,
          company_id: currentUser.company_id,
          record_type: recordType,
          is_admin_approved: 1,
        };
      }
    }
    await downloadFile(payload);
    setIsLoading(false);
  };

  const handleDownloadTemplate = async (recordType) => {
    setIsLoading(true);
    await downloadTemplate(recordType === RecordType.Service ? true : false);
    setIsLoading(false);
  };

  const validateInput = (values) => {
    const errors = {};
    if (values.record_type === RecordType.Product) {
      if (!values.product_type) {
        errors.product_type = "Product cannot be empty!";
      }

      if (!values.product_brand) {
        errors.product_brand = "Product Brand cannot be empty!";
      }

      if (!values.product_model) {
        errors.product_model = "Product Model cannot be empty!";
      }

      if (!values.product_qty) {
        errors.product_qty = "Product Quantity cannot be empty!";
      } else if (!parseFloat(values.product_qty)) {
        errors.product_qty = "Product Quantity should be greater than zero";
      }

      // if (!values.part_number) {
      //     errors.part_number = 'Part Number cannot be empty!'
      // }
    }
    // if (!values.product_price) {
    //     errors.product_price = 'Product Price cannot be empty!'
    // }
    // else
    if (values.product_price && !parseFloat(values.product_price)) {
      errors.product_price = "Product Price should be greater than zero";
    }
    if (values.product_isnew === "-1") {
      errors.product_isnew = "Select an option";
    }

    if (!values.product_description) {
      errors.product_description = "Description cannot be empty";
    }

    if (
      !values.product_category_id ||
      parseInt(values.product_category_id) <= -1
    ) {
      errors.product_category_id = "Select a Category";
    }

    // if (parseInt(values.product_subcategory_id) <= -1) {
    //     errors.product_subcategory_id = 'Select a Subcategory';
    // }

    return errors;
  };

  const validateServiceInput = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Title cannot be empty!";
    }
    if (!values.product_description) {
      errors.product_description = "Description cannot be empty!";
    }
    // if (!values.product_price) {
    //     errors.product_price = 'Service Price cannot be empty!'
    // }
    // else
    if (values.product_price && !parseFloat(values.product_price)) {
      errors.product_price = "Service Price should be greater than zero";
    }

    if (
      !values.product_category_id ||
      parseInt(values.product_category_id) <= -1
    ) {
      errors.product_category_id = "Select a Category";
    }

    return errors;
  };

  const validateRequirementInput = (values) => {
    const errors = {};
    if (!values.product_name) {
      errors.product_name = "Product cannot be empty!";
    }

    if (!values.product_brand) {
      errors.product_brand = "Product Brand cannot be empty!";
    }

    if (!values.product_model) {
      errors.product_model = "Product Model cannot be empty!";
    }

    if (!values.description) {
      errors.description = "Description cannot be empty";
    }

    if (
      !values.product_category_id ||
      parseInt(values.product_category_id) <= -1
    ) {
      errors.product_category_id = "Select a Category";
    }
    return errors;
  };

  const validateQtyRequirementInput = (values) => {
    const errors = {};
    if (values.product_price && !parseFloat(values.product_price)) {
      errors.product_price = "Product Price should be greater than zero";
    }
    if (values.product_isnew === "-1") {
      errors.product_isnew = "Select an option";
    }
    if (!values.product_qty) {
      errors.product_qty = "Product Quantity cannot be empty!";
    } else if (!parseFloat(values.product_qty)) {
      errors.product_qty = "Product Quantity should be greater than zero";
    }
    if (values.product_isnew === "-1") {
      errors.product_isnew = "Select an option";
    }
    return errors;
  };

  const resetUploadModal = () => {
    setUploadedProducts([]);
    document.getElementById("csvInput").value = "";
  };

  const [deliveryMethod, setDeliveryMethod] = useState([])
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState([])
  const [checkboxes, setCheckboxes] = useState(Array(uploadedProducts.length).fill(false));
  const [deliveryMethodCheckBox, setdeliveryMethodCheckBox] = useState(Array(selectedOption?.length).fill(false));
  const [checkBoxAll, setCheckBoxAll] = useState(false)

  const handleCheckBox = (index, checked) => {

    if (checked) {
      setDeliveryMethod((deliveryMethod) => [...deliveryMethod, uploadedProducts[index]])

    }
    else {
      setDeliveryMethod(
        (deliveryMethod) => deliveryMethod.filter((item, i) => index !== i)
      )
    }
    setCheckboxes((prevCheckboxes) => {
      const newCheckboxes = [...prevCheckboxes]
      newCheckboxes[index] = checked
      return newCheckboxes
    })
  }
  const handleCheckBoxAll = (checked) => {

    if (checked) {
      uploadedProducts?.map((item, index) => {
        setDeliveryMethod((deliveryMethod) => [...deliveryMethod, uploadedProducts[index]])
      })
      // let isSelectedProductsAll = uploadedProducts?.filter((item) => item.isSelectedProducts !== true)
      // console.log(isSelectedProductsAll)
      setCheckboxes(Array(uploadedProducts.length).fill(true))
      setCheckBoxAll(true)

    }
    else {

      setDeliveryMethod(
        []
      )


      setCheckboxes(Array(uploadedProducts.length).fill(false))
      setCheckBoxAll(false)
    }
  };

  const handleApplyChanges = () => {

    let data = uploadedProducts?.map((item, index) => {
      let payload = item;
      deliveryMethod?.map((item1) => {
        if (item1?.srno === item?.srno) {
          let getSelectedCheckboxId = null
          let getSelectedCheckboxName = ""
          selectedOption?.map((select) => {
            Object.keys(item)?.map((key, value) => {
              console.log(key)
              if (key === select?.name) {
                console.log(true)
                getSelectedCheckboxId = getSelectedCheckboxId ? getSelectedCheckboxId + `,${select?.product_feature_id}` : select?.product_feature_id
                getSelectedCheckboxName = getSelectedCheckboxName ? getSelectedCheckboxName + `,${select?.name}` : select?.name
                payload = {
                  ...payload,
                  [key]: true,

                }

              }
            })
          })
          payload = {
            ...payload,
            id: getSelectedCheckboxId,
            product_feature_name: getSelectedCheckboxName,
            isSelectedProducts: true
          }
        }
      })

      uploadedProducts[index] = payload
    })
    // setUploadedProducts(data)
    setSelectedOption([])
    setApplyKey((prevKey) => prevKey + 1);
    // setProductDeliveryMethod(null)
    setDeliveryMethod([])
    setSelectedDeliveryMethod([])
    setCheckboxes(Array(uploadedProducts.length).fill(false))
    setdeliveryMethodCheckBox(Array(selectedOption?.length).fill(false))
    setCheckBoxAll(false)

  }

  const handleDeliveryMethod = (index, check) => {
    if (check) {
      setSelectedDeliveryMethod((selectedDeliveryMethod) => [...selectedDeliveryMethod, selectedOption[index]])
    }
    else {
      setSelectedDeliveryMethod(
        (selectedDeliveryMethod) => selectedDeliveryMethod.filter((item, i) => index !== i)
      )
    }
    setdeliveryMethodCheckBox((prevCheckboxes) => {
      const newCheckboxes = [...prevCheckboxes]
      newCheckboxes[index] = check
      return newCheckboxes
    })
  }
  const handleCategoryApply = (value) => {
    console.log(value)
    let uploadProduct = uploadedProducts?.map((item) => {
      let payload = {
        ...item,
        category_id: value,

      }
      return payload
    })
    setUploadedProducts(uploadProduct)

  }
  console.log(uploadedProducts)
  // console.log(product_categories)
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="app-main flex-column flex-row-fluid mt-4"
          id="kt_app_main"
        >
          <div className="d-flex flex-column flex-column-fluid">
            {/* Breadcrumb Start*/}
            <div
              id="kt_app_toolbar"
              className="app-toolbar py-3 py-lg-2 text-start"
            >
              <div
                id="kt_app_toolbar_container"
                className="app-container container-xxl d-flex flex-stack"
              >
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                  <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                    {currentUser.user_type <= 1 ? "Listed" : "Seller"} Products
                  </h1>
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <li className="breadcrumb-item text-muted">
                      <Link
                        to="/dashboard"
                        className="text-muted text-hover-primary"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <span className="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <li className="breadcrumb-item text-muted">Products</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Breadcrumb End */}
            <div id="kt_app_content" className="app-content flex-column-fluid ">
              <div
                id="kt_app_content_container"
                className="app-container container-fluid"
              >
                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-1">
                  <div className="col-xl-12">
                    <div className="card card-flush h-md-100">
                      <div className="card-body">
                        <div
                          className="stepper stepper-links d-flex flex-column"
                          id="kt_create_account_stepper"
                        >
                          <div className="w-100"></div>
                          <div className="card-header align-items-center mb-2">
                            {currentUser.user_type === UserType.SuperAdmin ? (
                              <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                <div className="row col-md-12 justify-content-end">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4">
                                    <div
                                      className="btn btn-info me-3 btn-sm"
                                      onClick={() => handleDownloadFile(1)}
                                      title="Download products csv"
                                    >
                                      <i className="fa fa-download"></i>{" "}
                                      Products
                                    </div>
                                    <div
                                      className="btn btn-primary btn-sm"
                                      onClick={() => handleDownloadFile(2)}
                                      title="Download products csv"
                                    >
                                      <i className="fa fa-download"></i>{" "}
                                      Services
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="link_section">
                                      <a
                                        title="Download Product Template"
                                        onClick={() =>
                                          handleDownloadTemplate(1)
                                        }
                                      >
                                        Sample Product Template
                                      </a>
                                    </div>
                                    <div className="link_section">
                                      <a
                                        title="Download Service Template"
                                        onClick={() =>
                                          handleDownloadTemplate(2)
                                        }
                                      >
                                        Sample Service Template
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {currentUser.user_type !== UserType.SuperAdmin ? (
                              <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                {currentUser.user_type === UserType.Admin ? (
                                  <div className="row justify-content-start col-md-6">
                                    <div className="row mb-6">
                                      <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                        <span>Sellers</span>
                                      </label>
                                      <div className="col-lg-10 fv-row">
                                        <Form.Select
                                          value={selectedUser}
                                          name="user"
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            setSelectedUser(e.target.value);
                                            setActivePage(1);
                                            setNoActivePage(1);
                                            fetchAllUserProducts(
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option value="-1">
                                            Select Seller
                                          </option>
                                          {users?.map((element, index) => {
                                            return (
                                              <option
                                                key={"user_" + index}
                                                value={element.user_id}
                                              >
                                                {element.user_fname +
                                                  " " +
                                                  element.user_lname +
                                                  " (" +
                                                  element.user_email +
                                                  ")"}
                                              </option>
                                            );
                                          })}
                                        </Form.Select>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="row col-md-12 justify-content-end">
                                  {(currentUser.user_type === UserType.Admin &&
                                    selectedUser > 0) ||
                                    currentUser.user_type === UserType.User ? (
                                    <>
                                      <div className="col-md-4">
                                        <button
                                          className="btn btn-info me-3 btn-sm"
                                          onClick={() =>
                                            handleEditProduct(null)
                                          }
                                        >
                                          <i className="fa fa-plus"></i> Product
                                        </button>
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={() =>
                                            handleEditService(null)
                                          }
                                        >
                                          <i className="fa fa-plus"></i> Service
                                        </button>
                                      </div>

                                      <div className="col-md-2">
                                        <div
                                          className="btn btn-info btn-sm"
                                          data-bs-target="#kt_modal"
                                          data-bs-toggle="modal"
                                          onClick={() => {
                                            setShowModal(true);
                                          }}
                                        >
                                          <i className="fa fa-upload"></i>{" "}
                                          Products
                                        </div>
                                        <div className="link_section">
                                          <a
                                            title="Download Product Template"
                                            onClick={() =>
                                              handleDownloadTemplate(1)
                                            }
                                          >
                                            Sample Template
                                          </a>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div
                                          className="btn btn-primary btn-sm"
                                          data-bs-target="#kt_modal"
                                          data-bs-toggle="modal"
                                          onClick={() => {
                                            setShowUploadServiceModal(true);
                                          }}
                                        >
                                          <i className="fa fa-upload"></i>{" "}
                                          Services
                                        </div>
                                        <div className="link_section">
                                          <a
                                            title="Download Service Template"
                                            onClick={() =>
                                              handleDownloadTemplate(2)
                                            }
                                          >
                                            Sample Template
                                          </a>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  <div className="col-md-4">
                                    <div
                                      className="btn btn-info me-3 btn-sm"
                                      onClick={() => handleDownloadFile(1)}
                                      title="Download products csv"
                                    >
                                      <i className="fa fa-download"></i>{" "}
                                      Products
                                    </div>
                                    <div
                                      className="btn btn-primary btn-sm"
                                      onClick={() => handleDownloadFile(2)}
                                      title="Download products csv"
                                    >
                                      <i className="fa fa-download"></i>{" "}
                                      Services
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <Tabs defaultActiveKey={activeTab}>

                          <Tab eventKey="first" title="Listed Products">
                            <div
                              className="mx-auto w-100 pb-10"
                              noValidate="novalidate"
                              id="kt_create_account_form"
                            >
                              {/* All Products */}
                              <div className="w-100">
                                <div className="fv-row">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="row mb-6">
                                        <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                          <div className="col-xl-12">
                                            <div className="card card-flush h-md-100">
                                              <div className="card-header h30">
                                                <h3 className="card-title align-items-start flex-column">
                                                  {/* <span className="card-label fw-bold text-gray-800">Products</span> */}
                                                </h3>
                                                <span className="card-label fw-bold text-gray-800">
                                                  Total: {totalProducts}
                                                </span>
                                              </div>
                                              <div className="card-body">
                                                <div className="table-responsive">
                                                  <table
                                                    id="tbl_tickets"
                                                    className="table table-row-bordered table-striped table-row-gray-300 gy-7"
                                                  >
                                                    <thead>
                                                      <tr className="fs-7 fw-bold  orders_table  text-gray-400  border-bottom-0 ">
                                                        {/* <th className="p-0 pb-3 min-w-20px text-start">
                                                                                                        #
                                                                                                    </th> */}
                                                        <th className="py-3 pb-3 text-nowrap text-start">
                                                          SERIAL #
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-start">
                                                          IS PRODUCT/SERVICE?
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          TYPE/TITLE
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          BRAND
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          MODEL
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          PART NUMBER
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          STAGE
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          QUANTITY
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          PRICE
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          DESCRIPTION
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          CATEGORY
                                                        </th>
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          IS APPROVED?
                                                        </th>
                                                        {/* <th className="py-3 pb-3 text-nowrap text-end">
                                                                                                                        PRODUCT SUBCATEGORY
                                                                                                                    </th> */}
                                                        <th className="py-3 pb-3 text-nowrap text-end">
                                                          ACTIONS
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {products?.map(
                                                        (product, i) => {
                                                          return (
                                                            <>
                                                              <tr
                                                                key={
                                                                  "product_" + i
                                                                }
                                                              >
                                                                {/* <td className="text-start p-0 ">
                                                                                                                    <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                        {i + 1}
                                                                                                                    </span>
                                                                                                                </td> */}
                                                                <td className="text-start p-0 ">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {
                                                                      product.vendor_product_id
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0 ">
                                                                  <span
                                                                    className={
                                                                      "badge py-3 px-4 fs-7 " +
                                                                      (product.record_type ===
                                                                        1
                                                                        ? "badge-light-primary"
                                                                        : "badge-light-success")
                                                                    }
                                                                  >
                                                                    {product.record_type ===
                                                                      1
                                                                      ? "Product"
                                                                      : "Service"}
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0 ">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {product.record_type ===
                                                                      1
                                                                      ? product.product_type
                                                                      : product.title}
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0 ">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {
                                                                      product.product_brand
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {
                                                                      product.product_model
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {
                                                                      product.part_number
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  <span
                                                                    className={`badge py-3 px-4 fs-7 ${getProductStageClass(
                                                                      product.product_isnew
                                                                    )} `}
                                                                  >
                                                                    {getProductStageName(
                                                                      product.product_isnew
                                                                    )}{" "}
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {product.record_type ===
                                                                      RecordType.Product
                                                                      ? product.product_qty
                                                                      : ""}
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {
                                                                      product.product_price
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  <OverlayTrigger
                                                                    placement="bottom"
                                                                    delay={{
                                                                      show: 250,
                                                                      hide: 400,
                                                                    }}
                                                                    overlay={renderTooltip(
                                                                      {
                                                                        description:
                                                                          product.product_description,
                                                                      }
                                                                    )}
                                                                  >
                                                                    <span className="text-gray-600 fs-7 text-hover-primary">
                                                                      {product.product_description
                                                                        ? product.product_description.substring(
                                                                          0,
                                                                          25
                                                                        ) +
                                                                        (product
                                                                          .product_description
                                                                          .length >
                                                                          25
                                                                          ? " ..."
                                                                          : "")
                                                                        : ""}
                                                                    </span>
                                                                  </OverlayTrigger>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {
                                                                      product.category_name
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td className="text-start p-0">
                                                                  {currentUser.user_type <=
                                                                    2 ? (
                                                                    <Form>
                                                                      <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        label=""
                                                                        defaultChecked={
                                                                          product.is_admin_approved === 1
                                                                            ? true
                                                                            : false
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) =>
                                                                          handleApproved(
                                                                            event,
                                                                            product
                                                                          )
                                                                        }
                                                                      />
                                                                    </Form>
                                                                  ) : (
                                                                    <span
                                                                      className={`text-gray-600 fs-7 text-hover-primary badge ${product.is_admin_approved ===
                                                                        1
                                                                        ? "badge-light-success"
                                                                        : "badge-light-warning"
                                                                        }`}
                                                                    >
                                                                      {product.is_admin_approved ===
                                                                        1
                                                                        ? "Yes"
                                                                        : "No"}
                                                                    </span>
                                                                  )}
                                                                </td>

                                                                {/* <td className="text-start p-0">
                                                                                                                                    <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                                        {
                                                                                                                                            product.subcategory_name
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                </td> */}
                                                                <td className="text-start p-0">
                                                                  {/* <span className="text-gray-600 fs-7 text-hover-primary"> */}
                                                                  <div
                                                                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-3"
                                                                    title="Edit"
                                                                    onClick={() =>
                                                                      openEditView(
                                                                        product
                                                                      )
                                                                    }
                                                                  >
                                                                    <i
                                                                      className="fa fa-pencil"
                                                                      title="Edit"
                                                                    />
                                                                  </div>
                                                                  <div
                                                                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                                                                    title="Delete"
                                                                    onClick={() =>
                                                                      handleDeleteProduct(
                                                                        product
                                                                      )
                                                                    }
                                                                  >
                                                                    <i
                                                                      className="fa fa-trash"
                                                                      title="Delete"
                                                                    />
                                                                  </div>
                                                                  {/* </span> */}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </tbody>
                                                  </table>
                                                  {products?.length === 0 && (
                                                    <div className="no_data">
                                                      No Products Found
                                                    </div>
                                                  )}
                                                </div>
                                                {totalProducts > 0 ? (
                                                  <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={
                                                      PerPageSize
                                                    }
                                                    totalItemsCount={
                                                      totalProducts
                                                        ? totalProducts
                                                        : 0
                                                    }
                                                    pageRangeDisplayed={
                                                      PerPageSize
                                                    }
                                                    onChange={handlePageChange}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {showModal ? (
                              <ModalBox
                                showModal={showModal}
                                hideModal={() => handleHideModal()}
                                title="Upload Products from CSV/Excel"
                                className="upload_product"
                              >
                                <div
                                  className="d-flex flex-column flex-root"
                                  id="kt_app_root"
                                >
                                  <div className="w-md">
                                    <div className="row mb-6">
                                      <CSVParse
                                        handleUploadedProducts={(
                                          data,
                                          hasHeader
                                        ) =>
                                          handleUploadedProducts(
                                            data,
                                            hasHeader
                                          )
                                        }
                                        setRefreshFunction={(f) => {
                                          setChildRefreshFunction(f);
                                        }}
                                      ></CSVParse>
                                    </div>
                                    <div>
                                      <div className="d-flex mb-4 gap-4 justify-content-center align-items-center ">


                                        <div className=" w-100 ">
                                          {uploadedProducts?.length > 0 ?
                                            <BootstrapSelect
                                              className="w-100 h-100"
                                              placeholder="Category"
                                              showSearch
                                              options={
                                                product_categories
                                              }
                                              onChange={(
                                                e
                                              ) => {

                                                handleCategoryApply(e.selectedKey[0])
                                              }
                                              }
                                            />
                                            : ""}

                                        </div>
                                        {/* <Multiselect  data={dataselected} multiple /> */}
                                        <div className="w-100 ">
                                          {uploadedProducts?.length > 0 ?
                                            <div className="d-flex" >

                                              <div className="w-100">
                                                <Select
                                                  key={applyKey}
                                                  value={selectedOption?.product_feature_id}
                                                  onChange={handleChangeMultiSelect}
                                                  options={productDeliveryMethod}
                                                  isMulti
                                                  className="w-100 h-100"
                                                />
                                                {/* {
                                            productDeliveryMethod?.map((item, i) => {
                                              return (
                                                <div key={i}>
                                                  <input type="checkbox" className="mt-1" onChange={(e) => handleDeliveryMethod(i, e.target.checked)} checked={deliveryMethodCheckBox[i] || false} /> {item?.name}
                                                </div>
                                              )

                                            })
                                          } */}


                                              </div>


                                            </div>
                                            : ""
                                          }
                                        </div>
                                        <div className=" ">
                                          {uploadedProducts?.length > 0 ?
                                            <button class="btn btn-success h-100 "
                                              onClick={() => { handleApplyChanges() }}
                                            >

                                              Apply

                                            </button>
                                            : ""
                                          }
                                        </div>
                                      </div>
                                      {uploadedProducts?.length > 0 ? (
                                        <>
                                          <div className="row mb-6">
                                            <div
                                              className="table-responsive"
                                              style={{ maxHeight: "60vh" }}
                                            >
                                              <table
                                                id="tbl_tickets"
                                                className="table table-row-dashed table-row-gray-300 gy-7"
                                              >
                                                <thead>
                                                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                    <th className="p-0 pb-3 text-nowrap text-start">
                                                      <input type='checkbox' onChange={e => { handleCheckBoxAll(e.target.checked) }} checked={checkBoxAll ? true : false} />
                                                    </th>
                                                    <th className="p-0 pb-3 text-nowrap text-end">
                                                      #
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      PRODUCT
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      BRAND
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      MODEL
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      PARTNUMBER
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      STAGE
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      QTY
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      PRICE
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      DESCRIPTION
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      PRODUCT FEATURES
                                                    </th>


                                                    {/* <th className="py-3 pb-3 text-nowrap text-end">
                                                      CATEGORY
                                                    </th> */}
                                                    {/* <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                                    PRODUCT SUBCATEGORY
                                                                                                                </th> */}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {uploadedProducts?.map(
                                                    (product, i) => {
                                                      return (
                                                        <>
                                                          <tr
                                                            key={
                                                              "upload_product_" +
                                                              i
                                                            }
                                                          >

                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                <input type='checkbox' onChange={(e) => handleCheckBox(i, e.target.checked)} checked={checkboxes[i] || false} />
                                                              </span>
                                                            </td>
                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {i + 1}
                                                              </span>
                                                            </td>

                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-nowrap text-hover-primary">
                                                                {
                                                                  product?.product
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product?.brand}
                                                              </span>
                                                            </td>
                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product?.modelno
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product?.partnumber
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-end p-0">
                                                              <span
                                                                className={`badge py-3 px-4 fs-7 ${getProductStageClassByName(
                                                                  product?.neworold
                                                                )}`}
                                                              >
                                                                {getProductStageByName(
                                                                  product?.neworold
                                                                )}{" "}
                                                              </span>
                                                            </td>
                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product?.qty}
                                                              </span>
                                                            </td>
                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product?.rate}
                                                              </span>
                                                            </td>
                                                            <td className="text-center py-3">
                                                              <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{
                                                                  show: 250,
                                                                  hide: 400,
                                                                }}
                                                                overlay={renderTooltip(
                                                                  {
                                                                    description:
                                                                      product?.remark,
                                                                  }
                                                                )}
                                                              >
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {/* {product.remark
                                                                    ? product.remark.substring(
                                                                        0,
                                                                        25
                                                                      ) +
                                                                      (product
                                                                        .remark
                                                                        .length >
                                                                      25
                                                                        ? " ..."
                                                                        : "")
                                                                    : ""} */}
                                                                  <Info size={20} />
                                                                </span>
                                                              </OverlayTrigger>
                                                              {/* <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product.remark}
                                                              </span> */}
                                                            </td>

                                                            {/* <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  <BootstrapSelect
                                                                    showSearch
                                                                    options={
                                                                      product_categories
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                    (product.category =
                                                                      e.selectedValue[0])
                                                                    }
                                                                  />
                                                                }
                                                              </span>
                                                            </td> */}
                                                            {/* <td className="text-start p-0 ">
                                                                                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                                    {
                                                                                                                                        product.subcategory
                                                                                                                                 }
                                                                                                                                </span>
                                                                                                                            </td> */}
                                                            <td className="text-end py-3">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product?.product_feature_name}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>

                                          <div class="text-center">
                                            <button
                                              type="button"
                                              id="kt_modal_new_target_submit"
                                              onClick={() => submitProducts()}
                                              class="btn btn-success me-3"
                                            >
                                              <span class="indicator-label">
                                                Save
                                              </span>
                                            </button>

                                            <button
                                              type="reset"
                                              id="kt_modal_new_target_cancel"
                                              class="btn btn-secondary me-3"
                                              onClick={() => resetUploadModal()}
                                            >
                                              Reset
                                            </button>

                                            {/* <button
                                                                                    type="reset"
                                                                                    id="kt_modal_new_target_cancel"
                                                                                    class="btn btn-primary me-3"
                                                                                    onClick={() => setShowModal(false)}
                                                                                >
                                                                                    Cancel
                                                                                </button> */}
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </ModalBox>
                            ) : null}

                            {showEditModal ? (
                              <ModalBox
                                className="add_product"
                                showModal={showEditModal}
                                hideModal={() => handleHideEditModal()}
                                title={dialogTitle}
                              >
                                <Formik
                                  initialValues={{
                                    product_type: currentProduct?.product_type,
                                    product_brand:
                                      currentProduct?.product_brand,
                                    product_model:
                                      currentProduct?.product_model,
                                    product_isnew: currentProduct?.product_isnew
                                      ? currentProduct?.product_isnew
                                      : "-1",
                                    product_qty: currentProduct?.product_qty,
                                    product_price:
                                      currentProduct?.product_price,
                                    vendor_product_id:
                                      currentProduct?.vendor_product_id,
                                    product_id: currentProduct?.product_id,
                                    product_description:
                                      currentProduct?.product_description,
                                    product_category_id:
                                      currentProduct?.product_category_id,
                                    product_subcategory_id:
                                      currentProduct?.product_subcategory_id,
                                    part_number: currentProduct?.part_number,
                                    is_admin_approved:
                                      currentProduct?.is_admin_approved
                                        ? currentProduct?.is_admin_approved
                                        : false,
                                    record_type: currentProduct?.record_type
                                      ? currentProduct?.record_type
                                      : RecordType.Product,
                                  }}
                                  validate={validateInput}
                                  enableReinitialize
                                  onSubmit={(values, { resetForm }) => {
                                    setTimeout(() => {
                                      console.log("values : ", values);
                                      if (
                                        updateProduct(values, false) === true
                                      ) {
                                        resetForm();
                                      }
                                    }, 500);
                                    return false;
                                  }}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    handleSubmit,
                                    isSubmitting,
                                  }) => (
                                    <div
                                      className="d-flex flex-column flex-root"
                                      id="kt_app_root_update_product"
                                    >
                                      <div className="w-md">
                                        <form onSubmit={handleSubmit}>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Product</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_type}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_type &&
                                                touched.product_type ? (
                                                <div className="error">
                                                  {errors.product_type}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Brand</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_brand"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_brand}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_brand &&
                                                touched.product_brand ? (
                                                <div className="error">
                                                  {errors.product_brand}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Model</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_model"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_model}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_model &&
                                                touched.product_model ? (
                                                <div className="error">
                                                  {errors.product_model}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                              <span>Part Number</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="part_number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.part_number}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.part_number &&
                                                touched.part_number ? (
                                                <div className="error">
                                                  {errors.part_number}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Quantity</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_qty"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_qty}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_qty &&
                                                touched.product_qty ? (
                                                <div className="error">
                                                  {errors.product_qty}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                              <span>Price</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_price"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_price}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_price &&
                                                touched.product_price ? (
                                                <div className="error">
                                                  {errors.product_price}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>
                                                Is New/Used/Refurbished?
                                              </span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <Form.Select
                                                name="product_isnew"
                                                onChange={handleChange}
                                                value={values.product_isnew}
                                              >
                                                <option value="-1">
                                                  Select
                                                </option>
                                                <option value="1">New</option>
                                                <option value="2">Used</option>
                                                <option value="3">
                                                  Refurbished
                                                </option>
                                              </Form.Select>
                                              {errors.product_isnew &&
                                                touched.product_isnew ? (
                                                <div className="error">
                                                  {errors.product_isnew}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Description</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="product_description"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                  values.product_description
                                                }
                                              ></Form.Control>
                                              {errors.product_description &&
                                                touched.product_description ? (
                                                <div className="error">
                                                  {errors.product_description}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Category</span>
                                            </label>
                                            <div className="col-lg-8 fv-row" >

                                              <BootstrapSelect
                                                showSearch
                                                menuSize={10}
                                                options={categoryList.map(
                                                  (e) => ({
                                                    labelKey: e.category_id,
                                                    value: e.category_name,
                                                  })
                                                )}
                                                onChange={(e) =>
                                                  setFieldValue(
                                                    "product_category_id",
                                                    e.selectedKey[0]
                                                  )
                                                }
                                              />
                                              {/* <Form.Select
                                                name="product_category_id"
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  // values.product_subcategory_id = "-1";
                                                  // let subCategories = allCategoryList.filter(f => f.parent_category_id === parseInt(e.target.value));
                                                  // setSubcategoryList(subCategories);
                                                }}
                                                value={
                                                  values.product_category_id
                                                }
                                              >
                                                <option value="-1">
                                                  Select Category
                                                </option>
                                                {categoryList?.map(
                                                  (element, index) => {
                                                    return (
                                                      <option
                                                        value={
                                                          element.category_id
                                                        }
                                                      >
                                                        {element.category_name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </Form.Select> */}
                                              {errors.product_category_id &&
                                                touched.product_category_id ? (
                                                <div className="error">
                                                  {errors.product_category_id}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          {/* {subCategoryList?.length > 0 ?
                                                                                            <div className="row mb-6">
                                                                                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                                                    <span>Subcategory</span>
                                                                                                </label>
                                                                                                <div className="col-lg-8 fv-row">
                                                                                                    <Form.Select
                                                                                                        name="product_subcategory_id"
                                                                                                        onChange={handleChange}
                                                                                                        value={values.product_subcategory_id}>
                                                                                                        <option value="-1">Select</option>
                                                                                                        {subCategoryList?.map((element, index) => {
                                                                                                            return <option value={element.category_id}>{element.category_name}</option>
                                                                                                        })
                                                                                                        }

                                                                                                    </Form.Select>
                                                                                                    {errors.product_subcategory_id && touched.product_subcategory_id ? <div className="error">{errors.product_subcategory_id}</div> : null}
                                                                                                </div>
                                                                                            </div> : null
                                                                                        } */}
                                          <div class="text-center">
                                            <button
                                              type="reset"
                                              id="kt_modal_new_target_cancel"
                                              class="btn btn-light me-3"
                                              onClick={() =>
                                                setShowEditModal(false)
                                              }
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="submit"
                                              disabled={isSubmitting}
                                              id="kt_modal_new_target_submit" // onClick={handleClickSubmit}
                                              class="btn btn-primary"
                                            >
                                              <span class="indicator-label">
                                                Submit
                                              </span>
                                              <span class="indicator-progress">
                                                Please wait...
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                              </span>
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  )}
                                </Formik>
                              </ModalBox>
                            ) : null}

                            {showDeleteModal ? (
                              <ModalBox
                                showModal={showDeleteModal}
                                hideModal={() => setShowDeleteModal(false)}
                                title="Delete Product Confirmation"
                              >
                                <div
                                  className="d-flex flex-column flex-root"
                                  id="kt_app_root"
                                >
                                  <div className="w-md"></div>
                                  <div className="row mb-6">
                                    <label className="col-lg-12 col-form-label fw-semibold fs-6">
                                      <span>
                                        Are you sure to delete the product?{" "}
                                      </span>
                                    </label>
                                  </div>
                                  <div class="text-center">
                                    <button
                                      type="reset"
                                      id="kt_modal_new_target_cancel"
                                      class="btn btn-light me-3"
                                      onClick={() => setShowDeleteModal(false)}
                                    >
                                      No
                                    </button>
                                    <button
                                      type="submit"
                                      id="kt_modal_new_target_submit"
                                      onClick={handleDeleteProductSubmit}
                                      class="btn btn-danger"
                                    >
                                      <span class="indicator-label">Yes</span>
                                      <span class="indicator-progress">
                                        Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </ModalBox>
                            ) : null}

                            {showDeleteRequirementModal ? (
                              <ModalBox
                                showModal={showDeleteRequirementModal}
                                hideModal={() =>
                                  setShowDeleteRequirementModal(false)
                                }
                                title="Delete Requirement Confirmation"
                              >
                                <div
                                  className="d-flex flex-column flex-root"
                                  id="kt_app_root"
                                >
                                  <div className="w-md"></div>
                                  <div className="row mb-6">
                                    <label className="col-lg-12 col-form-label fw-semibold fs-6">
                                      <span>
                                        Are you sure to delete the requirement?{" "}
                                      </span>
                                    </label>
                                  </div>
                                  <div class="text-center">
                                    <button
                                      type="reset"
                                      id="kt_modal_new_target_cancel"
                                      class="btn btn-light me-3"
                                      onClick={() =>
                                        setShowDeleteRequirementModal(false)
                                      }
                                    >
                                      No
                                    </button>
                                    <button
                                      type="submit"
                                      id="kt_modal_new_target_submit"
                                      onClick={handleDeleteRequirementSubmit}
                                      class="btn btn-danger"
                                    >
                                      <span class="indicator-label">Yes</span>
                                      <span class="indicator-progress">
                                        Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </ModalBox>
                            ) : null}

                            {showEditServiceModal ? (
                              <ModalBox
                                className="add_service"
                                showModal={showEditServiceModal}
                                hideModal={() => handleHideEditServiceModal()}
                                title={dialogTitle}
                              >
                                <Formik
                                  initialValues={{
                                    title: currentService?.title,
                                    product_description:
                                      currentService?.product_description,
                                    product_price:
                                      currentService?.product_price,
                                    product_id: currentService?.product_id,
                                    product_category_id:
                                      currentService?.product_category_id,
                                    product_subcategory_id:
                                      currentService?.product_subcategory_id,
                                    is_admin_approved:
                                      currentService?.is_admin_approved
                                        ? currentService?.is_admin_approved
                                        : false,
                                    vendor_product_id:
                                      currentService?.vendor_product_id,
                                    record_type: RecordType.Service,
                                  }}
                                  validate={validateServiceInput}
                                  enableReinitialize
                                  onSubmit={(values, { resetForm }) => {
                                    setTimeout(() => {
                                      console.log("values : ", values);
                                      if (updateService(values) === true) {
                                        resetForm();
                                      }
                                    }, 500);
                                    return false;
                                  }}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    isSubmitting,
                                  }) => (
                                    <div
                                      className="d-flex flex-column flex-root"
                                      id="kt_app_root_update_product"
                                    >
                                      <div className="w-md">
                                        <form onSubmit={handleSubmit}>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Title</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="title"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.title && touched.title ? (
                                                <div className="error">
                                                  {errors.title}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Description</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="product_description"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                  values.product_description
                                                }
                                              ></Form.Control>
                                              {errors.product_description &&
                                                touched.product_description ? (
                                                <div className="error">
                                                  {errors.product_description}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                              <span>Price</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_price"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_price}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_price &&
                                                touched.product_price ? (
                                                <div className="error">
                                                  {errors.product_price}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Category</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <BootstrapSelect
                                                showSearch
                                                options={categoryList.map(
                                                  (e) => ({
                                                    labelKey: e.category_id,
                                                    value: e.category_name,
                                                  })
                                                )}
                                                onChange={(e) =>
                                                  setFieldValue(
                                                    "product_category_id",
                                                    e.selectedKey[0]
                                                  )
                                                }
                                              />
                                              {/* <Form.Select
                                                name="product_category_id"
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  // values.product_subcategory_id = "-1";
                                                  // let subCategories = allCategoryList.filter(f => f.parent_category_id === parseInt(e.target.value));
                                                  // setSubcategoryList(subCategories);
                                                }}
                                                value={
                                                  values.product_category_id
                                                }
                                              >
                                                <option value="-1">
                                                  Select Category
                                                </option>
                                                {categoryList?.map(
                                                  (element, index) => {
                                                    return (
                                                      <option
                                                        value={
                                                          element.category_id
                                                        }
                                                      >
                                                        {element.category_name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </Form.Select> */}
                                              {errors.product_category_id &&
                                                touched.product_category_id ? (
                                                <div className="error">
                                                  {errors.product_category_id}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          {/* {subCategoryList?.length > 0 ?
                                                                                            <div className="row mb-6">
                                                                                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                                                    <span>Subcategory</span>
                                                                                                </label>
                                                                                                <div className="col-lg-8 fv-row">
                                                                                                    <Form.Select
                                                                                                        name="product_subcategory_id"
                                                                                                        onChange={handleChange}
                                                                                                        value={values.product_subcategory_id}>
                                                                                                        <option value="-1">Select</option>
                                                                                                        {subCategoryList?.map((element, index) => {
                                                                                                            return <option value={element.category_id}>{element.category_name}</option>
                                                                                                        })
                                                                                                        }

                                                                                                    </Form.Select>
                                                                                                    {errors.product_subcategory_id && touched.product_subcategory_id ? <div className="error">{errors.product_subcategory_id}</div> : null}
                                                                                                </div>
                                                                                            </div> : null
                                                                                        } */}

                                          <div class="text-center">
                                            <button
                                              type="reset"
                                              id="kt_modal_new_target_cancel"
                                              class="btn btn-light me-3"
                                              onClick={() =>
                                                setShowEditServiceModal(false)
                                              }
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="submit"
                                              disabled={isSubmitting}
                                              id="kt_modal_new_target_submit" // onClick={handleClickSubmit}
                                              class="btn btn-primary"
                                            >
                                              <span class="indicator-label">
                                                Submit
                                              </span>
                                              <span class="indicator-progress">
                                                Please wait...
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                              </span>
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  )}
                                </Formik>
                              </ModalBox>
                            ) : null}

                            {showUploadServiceModal ? (
                              <ModalBox
                                showModal={showUploadServiceModal}
                                hideModal={() => handleHideUploadServiceModal()}
                                title="Upload Services CSV"
                              >
                                <div
                                  className="d-flex flex-column flex-root"
                                  id="kt_app_root"
                                >
                                  <div className="w-md">
                                    <div className="row mb-6">
                                      <CSVParse
                                        handleUploadedProducts={(
                                          data,
                                          hasHeader
                                        ) =>
                                          handleUploadedServices(
                                            data,
                                            hasHeader
                                          )
                                        }
                                        setRefreshFunction={(f) => {
                                          setChildRefreshFunction(f);
                                        }}
                                      ></CSVParse>
                                    </div>

                                    <div>
                                      {uploadedServices.length > 0 ? (
                                        <>
                                          <div className="row mb-6">
                                            <div
                                              className="table-responsive"
                                              style={{ maxHeight: "60vh" }}
                                            >
                                              <table
                                                id="tbl_tickets"
                                                className="table table-row-dashed table-row-gray-300 gy-7"
                                              >
                                                <thead>
                                                  <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                    <th className="p-0 pb-3 min-w-50px text-start">
                                                      #
                                                    </th>
                                                    <th className="p-0 pb-3 min-w-50px text-start">
                                                      Title
                                                    </th>
                                                    <th className="p-0 pb-3 min-w-50px text-start">
                                                      Description
                                                    </th>
                                                    <th className="p-0 pb-3 min-w-50px text-start">
                                                      Price
                                                    </th>
                                                    <th className="p-0 pb-3 min-w-50px text-start">
                                                      Category
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {uploadedServices?.map(
                                                    (product, i) => {
                                                      return (
                                                        <>
                                                          <tr
                                                            key={
                                                              "upload_product_" +
                                                              i
                                                            }
                                                          >
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {i + 1}
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product.title}
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {/* {product.remark} */}
                                                                <OverlayTrigger
                                                                  placement="bottom"
                                                                  delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                  }}
                                                                  overlay={renderTooltip(
                                                                    {
                                                                      description:
                                                                        product.remark,
                                                                    }
                                                                  )}
                                                                >
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {/* {product.remark
                                                                    ? product.remark.substring(
                                                                        0,
                                                                        25
                                                                      ) +
                                                                      (product
                                                                        .remark
                                                                        .length >
                                                                      25
                                                                        ? " ..."
                                                                        : "")
                                                                    : ""} */}
                                                                    <Info size={20} />
                                                                  </span>
                                                                </OverlayTrigger>
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product.rate}
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {/* {
                                                                    product.category
                                                                  } */}
                                                                <BootstrapSelect
                                                                  showSearch
                                                                  options={
                                                                    service_categories
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                  (product.category =
                                                                    e.selectedValue[0])
                                                                  }
                                                                />
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>

                                          <div class="text-center">
                                            <button
                                              type="button"
                                              id="kt_modal_new_target_submit"
                                              onClick={() => submitServices()}
                                              class="btn btn-success me-3"
                                            >
                                              <span class="indicator-label">
                                                Save
                                              </span>
                                            </button>

                                            <button
                                              type="reset"
                                              id="kt_modal_new_target_cancel"
                                              class="btn btn-secondary me-3"
                                              onClick={() =>
                                                setUploadedServices([])
                                              }
                                            >
                                              Reset
                                            </button>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </ModalBox>
                            ) : null}

                            {showEditRequirementModal ? (
                              <ModalBox
                                className="add_product add_requirement"
                                showModal={showEditRequirementModal}
                                hideModal={() =>
                                  handleHideEditRequirementModal()
                                }
                                title={dialogTitle}
                              >
                                <Formik
                                  initialValues={{
                                    product_name:
                                      currentRequirement?.product_name,
                                    product_brand:
                                      currentRequirement?.product_brand,
                                    product_model:
                                      currentRequirement?.product_model,
                                    new_requirement_id:
                                      currentRequirement?.new_requirement_id,
                                    description:
                                      currentRequirement?.description,
                                    product_category_id:
                                      currentRequirement?.product_category_id,
                                    part_number:
                                      currentRequirement?.part_number,
                                    is_admin_approved:
                                      currentRequirement?.is_admin_approved
                                        ? currentRequirement?.is_admin_approved
                                        : false,
                                  }}
                                  validate={validateRequirementInput}
                                  enableReinitialize
                                  onSubmit={(values, { resetForm }) => {
                                    setTimeout(() => {
                                      console.log("values : ", values);
                                      if (
                                        handleUpdateRequirement(values) === true
                                      ) {
                                        resetForm();
                                      }
                                    }, 500);
                                    return false;
                                  }}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                  }) => (
                                    <div
                                      className="d-flex flex-column flex-root"
                                      id="kt_app_root_update_requirement"
                                    >
                                      <div className="w-md">
                                        <form onSubmit={handleSubmit}>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Product</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_name}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_name &&
                                                touched.product_name ? (
                                                <div className="error">
                                                  {errors.product_name}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Brand</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_brand"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_brand}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_brand &&
                                                touched.product_brand ? (
                                                <div className="error">
                                                  {errors.product_brand}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Model</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_model"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_model}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_model &&
                                                touched.product_model ? (
                                                <div className="error">
                                                  {errors.product_model}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                              <span>Part Number</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="part_number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.part_number}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.part_number &&
                                                touched.part_number ? (
                                                <div className="error">
                                                  {errors.part_number}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>

                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Description</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="description"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                              ></Form.Control>
                                              {errors.description &&
                                                touched.description ? (
                                                <div className="error">
                                                  {errors.description}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Category</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <Form.Select
                                                name="product_category_id"
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  // values.product_subcategory_id = "-1";
                                                  // let subCategories = allCategoryList.filter(f => f.parent_category_id === parseInt(e.target.value));
                                                  // setSubcategoryList(subCategories);
                                                }}
                                                value={
                                                  values.product_category_id
                                                }
                                              >
                                                <option value="-1">
                                                  Select Categorys
                                                </option>
                                                {categoryList?.map(
                                                  (element, index) => {
                                                    return (
                                                      <option
                                                        value={
                                                          element.category_id
                                                        }
                                                      >
                                                        {element.category_name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </Form.Select>
                                              {errors.product_category_id &&
                                                touched.product_category_id ? (
                                                <div className="error">
                                                  {errors.product_category_id}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>

                                          <div class="text-center">
                                            <button
                                              type="reset"
                                              id="kt_modal_new_target_cancel"
                                              class="btn btn-light me-3"
                                              onClick={() =>
                                                setShowEditRequirementModal(
                                                  false
                                                )
                                              }
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="submit"
                                              disabled={isSubmitting}
                                              id="kt_modal_new_target_submit" // onClick={handleClickSubmit}
                                              class="btn btn-primary"
                                            >
                                              <span class="indicator-label">
                                                Submit
                                              </span>
                                              <span class="indicator-progress">
                                                Please wait...
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                              </span>
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  )}
                                </Formik>
                              </ModalBox>
                            ) : null}

                            {showQtyRequirementModal ? (
                              <ModalBox
                                className="add_product add_requirement"
                                showModal={showQtyRequirementModal}
                                hideModal={() =>
                                  setShowQtyEditRequirementModal(false)
                                }
                                title={dialogTitle}
                              >
                                <Formik
                                  initialValues={{
                                    product_name:
                                      currentRequirement?.product_name,
                                    product_brand:
                                      currentRequirement?.product_brand,
                                    product_model:
                                      currentRequirement?.product_model,
                                    new_requirement_id:
                                      currentRequirement?.new_requirement_id,
                                    description:
                                      currentRequirement?.description,
                                    product_category_id:
                                      currentRequirement?.product_category_id,
                                    part_number:
                                      currentRequirement?.part_number,
                                    is_admin_approved:
                                      currentRequirement?.is_admin_approved
                                        ? currentRequirement?.is_admin_approved
                                        : false,
                                    category_name:
                                      currentRequirement?.category_name,
                                  }}
                                  validate={validateQtyRequirementInput}
                                  enableReinitialize
                                  onSubmit={(values, { resetForm }) => {
                                    setTimeout(() => {
                                      console.log("values : ", values);
                                      if (
                                        updateProduct(values, true) === true
                                      ) {
                                        resetForm();
                                      }
                                    }, 500);
                                    return false;
                                  }}
                                >
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                  }) => (
                                    <div
                                      className="d-flex flex-column flex-root"
                                      id="kt_app_root_update_requirement"
                                    >
                                      <div className="w-md">
                                        <form onSubmit={handleSubmit}>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Product</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              {values.product_name}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Brand</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              {values.product_brand}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Model</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              {values.product_model}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                              <span>Part Number</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              {values.part_number}
                                            </div>
                                          </div>

                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Description</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              {values.description}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Category</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              {values.category_name}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>Quantity</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_qty"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_qty}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_qty &&
                                                touched.product_qty ? (
                                                <div className="error">
                                                  {errors.product_qty}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                              <span>Price</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="text"
                                                placeholder=""
                                                name="product_price"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.product_price}
                                                autoComplete="off"
                                                className="form-control bg-transparent"
                                              />
                                              {errors.product_price &&
                                                touched.product_price ? (
                                                <div className="error">
                                                  {errors.product_price}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                              <span>
                                                Is New/Used/Refurbished?
                                              </span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <Form.Select
                                                name="product_isnew"
                                                onChange={handleChange}
                                                value={values.product_isnew}
                                              >
                                                <option value="-1">
                                                  Select
                                                </option>
                                                <option value="1">New</option>
                                                <option value="2">Used</option>
                                                <option value="3">
                                                  Refurbished
                                                </option>
                                              </Form.Select>
                                              {errors.product_isnew &&
                                                touched.product_isnew ? (
                                                <div className="error">
                                                  {errors.product_isnew}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>

                                          <div class="text-center">
                                            <button
                                              type="reset"
                                              id="kt_modal_new_target_cancel"
                                              class="btn btn-light me-3"
                                              onClick={() =>
                                                setShowEditRequirementModal(
                                                  false
                                                )
                                              }
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="submit"
                                              disabled={isSubmitting}
                                              id="kt_modal_new_target_submit" // onClick={handleClickSubmit}
                                              class="btn btn-primary"
                                            >
                                              <span class="indicator-label">
                                                Submit
                                              </span>
                                              <span class="indicator-progress">
                                                Please wait...
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                              </span>
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  )}
                                </Formik>
                              </ModalBox>
                            ) : null}
                          </Tab>

                          <Tab eventKey="second" title="Not Listed Products">
                            <div className="fv-row">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="row mb-6">
                                    <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                      <div className="col-xl-12">
                                        <div className="card card-flush h-md-100">
                                          <div className="card-header h30">
                                            <span className="card-title align-items-start flex-column"></span>
                                            <span className="card-label fw-bold text-gray-800">
                                              Total: {noTotalProducts}
                                            </span>
                                          </div>
                                          <div className="card-body">
                                            <div className="table-responsive no_products_table">
                                              <table
                                                id="tbl_tickets"
                                                className="table table-row-bordered table-striped table-row-gray-300 gy-7"
                                              >
                                                <thead>
                                                  <tr className="fs-7 fw-bold  orders_table  text-gray-400 border-bottom-0">
                                                    {/* <th className="p-0 pb-3 min-w-20px text-start">
                                                                                                        #
                                                                                                    </th> */}
                                                    <th className="py-3 pb-3 text-nowrap text-start">
                                                      SERIAL #
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      IS SERVICE/PRODUCT?
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      TITLE
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      PRODUCT BRAND
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      MODEL
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      PART NUMBER
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      STAGE
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      QUANTITY
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      PRICE
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      DESCRIPTION
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      CATEGORY
                                                    </th>
                                                    {/* <th className="py-3 pb-3 text-nowrap text-end">
                                                                                                                PRODUCT SUBCATEGORY
                                                                                                            </th> */}
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      IS APPROVED?
                                                    </th>
                                                    <th className="py-3 pb-3 text-nowrap text-end">
                                                      ACTIONS
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {noProducts?.map(
                                                    (product, i) => {
                                                      return (
                                                        <>
                                                          <tr
                                                            key={"product_" + i}
                                                          >
                                                            {/* <td className="text-start p-0 ">
                                                                                                                    <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                        {i + 1}
                                                                                                                    </span>
                                                                                                                </td> */}
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product.vendor_product_id
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0 ">
                                                              <span
                                                                className={
                                                                  "badge py-3 px-4 fs-7 " +
                                                                  (product.record_type ===
                                                                    1
                                                                    ? "badge-light-primary"
                                                                    : "badge-light-success")
                                                                }
                                                              >
                                                                {product.record_type ===
                                                                  1
                                                                  ? "Product"
                                                                  : "Service"}
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product.record_type ===
                                                                  1
                                                                  ? product.product_type
                                                                  : product.title}
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0 ">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product.product_brand
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product.product_model
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product.part_number
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              <span
                                                                className={`badge py-3 px-4 fs-7 ${getProductStageClass(
                                                                  product.product_isnew
                                                                )}`}
                                                              >
                                                                {getProductStageName(
                                                                  product.product_isnew
                                                                )}{" "}
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {product.record_type ===
                                                                  RecordType.Product
                                                                  ? product.product_qty
                                                                  : ""}
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product.product_price
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{
                                                                  show: 250,
                                                                  hide: 400,
                                                                }}
                                                                overlay={renderTooltip(
                                                                  {
                                                                    description:
                                                                      product.product_description,
                                                                  }
                                                                )}
                                                              >
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {product.product_description
                                                                    ? product.product_description.substring(
                                                                      0,
                                                                      25
                                                                    ) +
                                                                    (product
                                                                      .product_description
                                                                      .length >
                                                                      25
                                                                      ? " ..."
                                                                      : "")
                                                                    : ""}
                                                                </span>
                                                              </OverlayTrigger>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              <span className="text-gray-600 fs-7 text-hover-primary">
                                                                {
                                                                  product.category_name
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="text-start p-0">
                                                              {currentUser.user_type <=
                                                                2 ? (
                                                                <Form>
                                                                  <Form.Check
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    label=""
                                                                    defaultChecked={
                                                                      product.is_admin_approved ===
                                                                        1
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleApproved(
                                                                        event,
                                                                        product
                                                                      )
                                                                    }
                                                                  />
                                                                </Form>
                                                              ) : (
                                                                <span
                                                                  className={`text-gray-600 fs-7 text-hover-primary badge ${product.is_admin_approved ===
                                                                    1
                                                                    ? "badge-light-success"
                                                                    : "badge-light-warning"
                                                                    }`}
                                                                >
                                                                  {product.is_admin_approved ===
                                                                    1
                                                                    ? "Yes"
                                                                    : "No"}
                                                                </span>
                                                              )}
                                                            </td>
                                                            {/* <td className="text-start p-0">
                                                                                                                            <span className="text-gray-600 fs-7 text-hover-primary">
                                                                                                                                {
                                                                                                                                    product.subcategory_name
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </td> */}
                                                            <td className="text-start p-0">
                                                              {/* <span className="text-gray-600 fs-7 text-hover-primary"> */}
                                                              <div
                                                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-3"
                                                                title="Edit"
                                                                onClick={() =>
                                                                  openEditView(
                                                                    product
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-pencil"
                                                                  title="Edit"
                                                                />
                                                              </div>
                                                              <div
                                                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                                                                title="Delete"
                                                                onClick={() =>
                                                                  handleDeleteProduct(
                                                                    product
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-times"
                                                                  title="Delete"
                                                                />
                                                              </div>
                                                              {/* </span> */}
                                                            </td>
                                                          </tr>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              {noProducts?.length === 0 && (
                                                <div className="no_data">
                                                  No Products Found
                                                </div>
                                              )}
                                            </div>
                                            {noTotalProducts > 0 ? (
                                              <Pagination
                                                activePage={noActivePage}
                                                itemsCountPerPage={PerPageSize}
                                                totalItemsCount={
                                                  noTotalProducts
                                                    ? noTotalProducts
                                                    : 0
                                                }
                                                pageRangeDisplayed={PerPageSize}
                                                onChange={handleNoPageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>

                          {currentUser.user_type >= UserType.Admin ? (
                            <Tab eventKey="third" title="Coming Soon">
                              <div className="fv-row">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="row mb-6">
                                      <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                        <div className="col-xl-12">
                                          <div className="card card-flush h-md-100">
                                            <div className="card-header h30">
                                              <span className="card-title align-items-start flex-column"></span>
                                              <span className="card-label fw-bold text-gray-800">
                                                Total: {totalRequirements}
                                              </span>
                                            </div>
                                            <div className="card-body">
                                              <div className="table-responsive no_products_table">
                                                <table
                                                  id="tbl_requirements"
                                                  className="table table-row-bordered table-striped table-row-gray-300 gy-7"
                                                >
                                                  <thead>
                                                    <tr className="fs-7 fw-bold text-gray-400  orders_table  border-bottom-0">
                                                      <th className="py-3 pb-3 text-nowrap  text-start">
                                                        SERIAL #
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        PRODUCT
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        BRAND
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        MODEL
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        PART NUMBER
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        DESCRIPTION
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        CATEGORY
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        IS APPROVED?
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        HAS PRODUCT?
                                                      </th>
                                                      <th className="py-3 pb-3 text-nowrap text-end">
                                                        ACTIONS
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {requirementList?.map(
                                                      (requirement, i) => {
                                                        return (
                                                          <>
                                                            <tr
                                                              key={
                                                                "requirement_" +
                                                                i
                                                              }
                                                            >
                                                              {/* <td className="text-start p-0 ">
                                                                                                                    <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                        {i + 1}
                                                                                                                    </span>
                                                                                                                </td> */}
                                                              <td className="text-start p-0 ">
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {i + 1}
                                                                </span>
                                                              </td>
                                                              <td className="text-start p-0 ">
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {
                                                                    requirement.product_name
                                                                  }
                                                                </span>
                                                              </td>
                                                              <td className="text-start p-0 ">
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {
                                                                    requirement.product_brand
                                                                  }
                                                                </span>
                                                              </td>
                                                              <td className="text-start p-0">
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {
                                                                    requirement.product_model
                                                                  }
                                                                </span>
                                                              </td>
                                                              <td className="text-start p-0">
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {
                                                                    requirement.part_number
                                                                  }
                                                                </span>
                                                              </td>
                                                              <td className="text-start p-0">
                                                                <OverlayTrigger
                                                                  placement="bottom"
                                                                  delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                  }}
                                                                  overlay={renderTooltip(
                                                                    {
                                                                      description:
                                                                        requirement.description,
                                                                    }
                                                                  )}
                                                                >
                                                                  <span className="text-gray-600 fs-7 text-hover-primary">
                                                                    {requirement.description
                                                                      ? requirement.description.substring(
                                                                        0,
                                                                        25
                                                                      ) +
                                                                      (requirement
                                                                        .description
                                                                        .length >
                                                                        25
                                                                        ? " ..."
                                                                        : "")
                                                                      : ""}
                                                                  </span>
                                                                </OverlayTrigger>
                                                              </td>
                                                              <td className="text-start p-0">
                                                                <span className="text-gray-600 fs-7 text-hover-primary">
                                                                  {
                                                                    requirement.category_name
                                                                  }
                                                                </span>
                                                              </td>
                                                              <td className="text-start p-0">
                                                                {currentUser.user_type <=
                                                                  UserType.Admin &&
                                                                  requirement.product_id ===
                                                                  null ? (
                                                                  <Form>
                                                                    <Form.Check
                                                                      type="switch"
                                                                      id="custom-switch"
                                                                      label=""
                                                                      value={
                                                                        requirement.is_admin_approved
                                                                      }
                                                                      defaultChecked={
                                                                        requirement.is_admin_approved ===
                                                                          true
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={(
                                                                        event
                                                                      ) =>
                                                                        handleRequirementApproved(
                                                                          event,
                                                                          requirement
                                                                        )
                                                                      }
                                                                    />
                                                                  </Form>
                                                                ) : (
                                                                  <span
                                                                    className={`text-gray-600 fs-7 text-hover-primary badge ${requirement.is_admin_approved ===
                                                                      true
                                                                      ? "badge-light-success"
                                                                      : "badge-light-warning"
                                                                      }`}
                                                                  >
                                                                    {requirement.is_admin_approved ===
                                                                      true
                                                                      ? "Yes"
                                                                      : "No"}
                                                                  </span>
                                                                )}
                                                              </td>
                                                              <td className="text-start p-0">
                                                                <span
                                                                  className={`text-gray-600 fs-7 text-hover-primary badge ${requirement.product_id >
                                                                    0
                                                                    ? "badge-light-success"
                                                                    : "badge-light-warning"
                                                                    }`}
                                                                >
                                                                  {parseInt(
                                                                    requirement.product_id
                                                                  ) > 0
                                                                    ? "Yes"
                                                                    : "No"}
                                                                </span>
                                                              </td>
                                                              <td className="text-start p-0">
                                                                {currentUser.user_type ===
                                                                  UserType.Admin ? (
                                                                  <>
                                                                    <div
                                                                      className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-3"
                                                                      title="Edit"
                                                                      onClick={() =>
                                                                        openEditRequirementView(
                                                                          requirement
                                                                        )
                                                                      }
                                                                    >
                                                                      <i
                                                                        className="fa fa-pencil"
                                                                        title="Edit"
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                                                                      title="Delete"
                                                                      onClick={() =>
                                                                        handleDeleteRequirement(
                                                                          requirement
                                                                        )
                                                                      }
                                                                    >
                                                                      <i
                                                                        className="fa fa-times"
                                                                        title="Delete"
                                                                      />
                                                                    </div>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    <div
                                                                      className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-3"
                                                                      title="Edit"
                                                                      onClick={() =>
                                                                        handleAddQtyRequirement(
                                                                          requirement
                                                                        )
                                                                      }
                                                                    >
                                                                      <i
                                                                        className="fa fa-pencil"
                                                                        title="Add Qty"
                                                                      />
                                                                      Add Qty
                                                                    </div>
                                                                  </>
                                                                )}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                                {requirementList?.length ===
                                                  0 && (
                                                    <div className="no_data">
                                                      No Requirements Found
                                                    </div>
                                                  )}
                                              </div>
                                              {totalRequirements > 0 ? (
                                                <Pagination
                                                  activePage={reqActivePage}
                                                  itemsCountPerPage={
                                                    PerPageSize
                                                  }
                                                  totalItemsCount={
                                                    totalRequirements
                                                      ? totalRequirements
                                                      : 0
                                                  }
                                                  pageRangeDisplayed={
                                                    PerPageSize
                                                  }
                                                  onChange={handleReqPageChange}
                                                  itemClass="page-item"
                                                  linkClass="page-link"
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          ) : null}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProduct;
