import axios from "axios";
import { getAllVendorsGroupURL } from "../constants/ApiEndPoints";

export const getAllVendorsGroup = async (payload) => {
    try {
      const response = await axios.post(`${getAllVendorsGroupURL}`, payload);
      if (response.status === 200) {
        const data = response.data;
        // if (data.status === 200 && data.token) {
        //   sessionStorage.setItem("token", data.token);
        // }
        return data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }


  