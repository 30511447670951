import React, { useEffect, useState } from "react";
import { createTicket } from "../../services/TicketServices";
import { getAllCustomers } from "../../services/CustomerServices";
import { addTask as insertTask } from "../../services/TaskServices";
import { getAllUsers } from "../../services/UserServices";
import ModalBox from "../../shared/Modal";
import { addTaskSchema } from "../../validations/validations";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

const CreateTicket = () => {

  let navigate = useNavigate();
  const [ticketId, setTicketId] = useState();
  const [tasks, setTask] = useState([]);
  const [customers, setCustomer] = useState([]);
  const [engineers, setEngineer] = useState([]);
  const [stepper, setStepper] = useState(0);
  const [counter, setCounter] = useState(0);
  const [modalVal, setModalVal] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedEngineer, setSelectedEngineer] = useState();
  const [completionStatus, setCompletionStatus] = useState(false);

  useEffect(() => {
    fetchAllCustomers();
    fetchAllEngineers();
  }, []);

  const createNewTicket = async () => {
    const customer_id = selectedCustomer.customer_id;
    const user_id = selectedEngineer.user_id;

    let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));

		// let params = {};
		// if(current_user_data.user.user_type === 2 || current_user_data.user.user_type === 3) {
		// 	params = {user_id: current_user_data.user.user_id}
		// }

    let resp = await createTicket({
      ticket_task_type: 0,
      customer_id: customer_id,
      user_id: user_id,
      ticket_created_by: current_user_data.user.user_id,
      ticket_status: 0,
      company_id: current_user_data.user.company_id
    });

    if (resp.status === 200) {
      console.log("Success!");
      // Now insert tasks related to this ticket
      setTicketId(resp.ticket_id);
      addTasks(resp.ticket_id);
    } else {
      console.log("Error");
    }
  };

  const showTaskModal = () => {
    setShowModal(true);
    setModalVal(1);
  };

  const showCustomerModal = () => {
    setShowModal(true);
    setModalVal(2);
  };

  const showEngineerModal = () => {
    setShowModal(true);
    setModalVal(3);
  };

  const addTasks = async (ticket_id) => {
    const taskArr = [];
    tasks.forEach((data) => {
      taskArr.push([
        ticket_id,
        data.task_particulars,
        data.task_qty,
        data.task_price,
      ]);
    });

    let loginResp = await insertTask({ tasks: JSON.stringify(taskArr) });
    console.log(loginResp);
    if (loginResp.status === 200) {
      setShowModal(false);
      setTimeout(() => {
        navigate('/dashboard'); 
      }, 5000);
    } else {
      console.log("500!");
      setShowModal(false);
    }
  };

  const addTask = (values) => {
    if (values) {
      values.id = counter + 1;
      setCounter(counter + 1);
      setTask((tasks) => [...tasks, values]);
    }
    setShowModal(false);
  };

  const removeTask = (tmp_task) => {
    console.log("Remove Task Obj : ", tmp_task);
    setTask(tasks.filter((item) => item.id !== tmp_task.id));
  };

  const fetchAllCustomers = async (v) => {
    setShowModal(false);

    let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));
    console.log(current_user_data);

    if (v) {
      let params = {};

      if (v.filter_by === "customer_id") {
        params = { customer_id: `${v.filter_value}`, company_id: current_user_data.user.company_id };
      }
      if (v.filter_by === "customer_name") {
        params = { customer_name: `${v.filter_value}`, company_id: current_user_data.user.company_id };
      }
      if (v.filter_by === "customer_contact_1") {
        params = { customer_contact_1: `${v.filter_value}`, company_id: current_user_data.user.company_id };
      }
      if (v.filter_by === "customer_email") {
        params = { customer_email: `${v.filter_value}`, company_id: current_user_data.user.company_id };
      }
      const customers = await getAllCustomers(params);
      setCustomer(customers.data);
    } else {
      const customers = await getAllCustomers({company_id: current_user_data.user.company_id});
      setCustomer(customers.data);
    }
  };

  const fetchAllEngineers = async (v) => {
    setShowModal(false);

    let current_user_data = JSON.parse(sessionStorage.getItem(("user_data")));
    console.log(current_user_data);

    if (v) {
      let params = {};

      if (v.filter_by === "user_id") {
        params = { user_id: `${v.filter_value}`, company_id: current_user_data.user.company_id };
      }

      if (v.filter_by === "user_full_name") {
        params = { user_full_name: `${v.filter_value}`, company_id: current_user_data.user.company_id };
      }

      if (v.filter_by === "user_contact_1") {
        params = { user_contact_1: `${v.filter_value}`, company_id: current_user_data.user.company_id };
      }

      const userResp = await getAllUsers(params);
      const filteredResp = userResp.data.filter(resp => ((resp.user_type === 2) || (resp.user_type === 3)) )
      setEngineer(filteredResp);
    } else {
      const userResp = await getAllUsers({ company_id: current_user_data.user.company_id });
      const filteredResp = userResp.data.filter(resp => ((resp.user_type === 2) || (resp.user_type === 3)) )
      setEngineer(filteredResp);
    }
  };

  const handleNext = async () => {
    if (stepper === 2) {

      if (selectedCustomer && selectedEngineer && (tasks.length > 0)) {
        setCompletionStatus(true);
        await createNewTicket();
      } else {
        console.log('Please fill in all data...');
      }
    }

    if (stepper < 3) {
      setStepper(stepper + 1);
    } else {
      setStepper(0);
    }
    // printSelected()
  };

  const handlePrev = () => {
    if (stepper > 0) {
      setStepper(stepper - 1);
    } else {
      setStepper(3);
    }
  };

  return (
    <>
      <div
        className="app-main flex-column flex-row-fluid mt-8"
        id="kt_app_main"
      >
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid ">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body">
                  <div
                    className="stepper stepper-links d-flex flex-column"
                    id="kt_create_account_stepper"
                  >
                    <div className="stepper-nav mb-5">
                      <div
                        className={`stepper-item ${stepper === 0 ? "current" : null
                          }`}
                        data-kt-stepper-element="nav"
                      >
                        <h3 className="stepper-title">1. Tasks</h3>
                      </div>
                      <div
                        className={`stepper-item ${stepper === 1 ? "current" : null
                          }`}
                        data-kt-stepper-element="nav"
                      >
                        <h3 className="stepper-title">2. Customer</h3>
                      </div>
                      <div
                        className={`stepper-item ${stepper === 2 ? "current" : null
                          }`}
                        data-kt-stepper-element="nav"
                      >
                        <h3 className="stepper-title">3. Engineer</h3>
                      </div>

                      {completionStatus ? (
                        <div
                          className={`stepper-item ${stepper === 3 ? "current" : null
                            }`}
                          data-kt-stepper-element="nav"
                        >
                          <h3 className="stepper-title">4. Completion</h3>
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="mx-auto w-100 pb-10"
                      noValidate="novalidate"
                      id="kt_create_account_form"
                    >
                      {/* Tasks */}
                      <div
                        className={`${stepper === 0 ? "current" : null}`}
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          <div className="card-header align-items-center mb-2">
                            <div
                              className="card-toolbar flex-row-fluid justify-content-end gap-5"
                              onClick={showTaskModal}
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal"
                            >
                              <div className="btn btn-primary">+ Add Task</div>
                            </div>
                          </div>

                          <div className="fv-row">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="row mb-6">
                                  <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                    <div className="col-xl-12">
                                      <div className="card card-flush h-md-100">
                                        <div className="card-body pt-6">
                                          <div className="table-responsive">
                                            <table
                                              id="tbl_tickets"
                                              className="table table-row-dashed align-middle gs-0 gy-3 my-0"
                                            >
                                              <thead>
                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                  <th className="p-0 pb-3 min-w-20px text-start">
                                                    #
                                                  </th>
                                                  <th className="p-0 pb-3 min-w-50px text-start">
                                                    TYPE
                                                  </th>
                                                  <th className="p-0 pb-3 min-w-100px text-start">
                                                    PARTICULARS
                                                  </th>
                                                  <th className="p-0 pb-3 min-w-50px text-start">
                                                    QTY
                                                  </th>
                                                  <th className="p-0 pb-3 min-w-60px text-start">
                                                    PRICE
                                                  </th>
                                                  <th className="p-0 pb-3 min-w-50px text-start"></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {tasks?.map((task, i) => {
                                                  return (
                                                    <>
                                                      <tr key={i}>
                                                        <td className="text-start p-0">
                                                          <span className="text-gray-600 fw-bold fs-7">
                                                            {i + 1}
                                                          </span>
                                                        </td>

                                                        <td className="text-start p-0 ">
                                                          <span
                                                            className={`badge py-3 px-4 fs-7 ${task.task_type ===
                                                              "0"
                                                              ? "badge-light-primary"
                                                              : null
                                                              } ${task.task_type ===
                                                                "1"
                                                                ? "badge-light-warning"
                                                                : null
                                                              } ${task.task_type ===
                                                                "2"
                                                                ? "badge-light-success"
                                                                : null
                                                              } `}
                                                          >
                                                            {task.task_type ===
                                                              "0"
                                                              ? "Repairing"
                                                              : null}{" "}
                                                            {task.task_type ===
                                                              "1"
                                                              ? "Goods Delivery"
                                                              : null}{" "}
                                                            {task.task_type ===
                                                              "2"
                                                              ? "Repairing & Goods Delivery"
                                                              : null}
                                                          </span>
                                                        </td>

                                                        <td className="text-start p-0 ">
                                                          <span className="text-gray-600 fs-7 text-hover-primary text-break">
                                                            {
                                                              task.task_particulars
                                                            }
                                                          </span>
                                                        </td>

                                                        <td className="text-start p-0">
                                                          <span className="text-gray-600 fs-7 text-hover-primary">
                                                            {task.task_qty}
                                                          </span>
                                                        </td>

                                                        <td className="text-start p-0">
                                                          <span className="text-gray-600 fs-7 text-hover-primary">
                                                            {task.task_price}
                                                          </span>
                                                        </td>

                                                        <td className="text-start p-0">
                                                          <div
                                                            onClick={() =>
                                                              removeTask(task)
                                                            }
                                                            class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                                          >
                                                            <span class="svg-icon svg-icon-3">
                                                              <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <path
                                                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                                  fill="currentColor"
                                                                ></path>
                                                                <path
                                                                  opacity="0.5"
                                                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                                  fill="currentColor"
                                                                ></path>
                                                                <path
                                                                  opacity="0.5"
                                                                  d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                                  fill="currentColor"
                                                                ></path>
                                                              </svg>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        
                                                      </tr>
                                                    </>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Customer */}
                      <div
                        className={`${stepper === 1 ? "current" : null}`}
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          <div className="card-header align-items-center mb-2">
                            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                              <div className="m-0">
                                <a
                                  href="#"
                                  className="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kt_modal"
                                  onClick={showCustomerModal}
                                >
                                  <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                  Filter
                                </a>
                              </div>
                              {/* <button className="btn btn-primary" onClick={() => setModalVal(3)} data-bs-toggle="modal" data-bs-target="#kt_modal">+ Add Customer</button> */}
                            </div>
                          </div>

                          <div className="fv-row">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="table-responsive">
                                  <table
                                    id="tbl_tickets"
                                    className="table table-row-dashed align-middle gs-0 gy-3 my-0"
                                  >
                                    <thead>
                                      <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-20px text-start">
                                          ID
                                        </th>
                                        <th className="p-0 pb-3 min-w-50px text-start">
                                          CUSTOMER NAME
                                        </th>
                                        <th className="p-0 pb-3 min-w-100px text-start">
                                          CONTACT NO
                                        </th>
                                        <th className="p-0 pb-3 min-w-50px text-start">
                                          ADDRESS
                                        </th>
                                        <th className="p-0 pb-3 min-w-60px text-start">
                                          EMAIL
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {customers?.map((customer, i) => {
                                        return (
                                          <>
                                            <tr key={i}>
                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {customer.customer_id}
                                                </span>
                                              </td>

                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {customer.customer_name}
                                                </span>
                                              </td>

                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {customer.customer_contact_1}
                                                </span>
                                              </td>

                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {customer.customer_address}
                                                </span>
                                              </td>

                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {customer.customer_email}
                                                </span>
                                              </td>

                                              <td className="text-start p-0">
                                                <input
                                                  class="form-check-input"
                                                  name="customer"
                                                  type="radio"
                                                  value="1"
                                                  onClick={() =>
                                                    setSelectedCustomer(
                                                      customer
                                                    )
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Engineer */}
                      <div
                        className={`${stepper === 2 ? "current" : null}`}
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          <div className="card-header align-items-center mb-2">
                            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                              <div className="m-0">
                                <a
                                  href="#"
                                  className="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kt_modal"
                                  onClick={showEngineerModal}
                                >
                                  <span className="svg-icon svg-icon-6 svg-icon-muted me-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                  Filter
                                </a>
                              </div>
                              {/* <button className="btn btn-primary" onClick={() => setModalVal(3)} data-bs-toggle="modal" data-bs-target="#kt_modal">+ Add Customer</button> */}
                            </div>
                          </div>

                          <div className="fv-row">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="table-responsive">
                                  <table
                                    id="tbl_tickets"
                                    className="table table-row-dashed align-middle gs-0 gy-3 my-0"
                                  >
                                    <thead>
                                      <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-20px text-start">
                                          USER ID
                                        </th>
                                        <th className="p-0 pb-3 min-w-60px text-start">
                                          USER NAME
                                        </th>
                                        <th className="p-0 pb-3 min-w-60px text-start">
                                          USER TYPE
                                        </th>
                                        <th className="p-0 pb-3 min-w-100px text-start">
                                          CONTACT NO
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {engineers?.map((engineer, i) => {
                                        return (
                                          <>
                                            <tr key={i}>
                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {engineer.user_id}
                                                </span>
                                              </td>

                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {engineer.user_full_name}
                                                </span>
                                              </td>

                                              <td className="text-start p-0">
                                                <span className={`badge py-3 px-4 fw-bold fs-7 ${engineer.user_type === 2 ? 'badge-light-primary' : null} ${engineer.user_type === 3 ? 'badge-light-warning' : null} `}>{engineer.user_type === 2 ? 'Engineer' : null} {engineer.user_type === 3 ? 'Delivery Agent' : null}</span>
                                              </td>

                                              <td className="text-start p-0">
                                                <span className="text-gray-600 fw-bold fs-7">
                                                  {engineer.user_contact_1}
                                                </span>
                                              </td>

                                              <td className="form-check input">
                                                <input
                                                  class="form-check-input"
                                                  name="engineer"
                                                  type="radio"
                                                  value="1"
                                                  onClick={() =>
                                                    setSelectedEngineer(
                                                      engineer
                                                    )
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Completed */}
                      {completionStatus ? (
                        <div
                          className={`mb-4 ${stepper === 3 ? "current" : null}`}
                          data-kt-stepper-element="content"
                        >
                          <div className="w-100">
                            <div className="mb-0">
                              <div className="notice d-flex bg-light-success rounded border-success border border-dashed p-6">
                                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4"></span>
                                <div className="d-flex flex-stack flex-grow-1">
                                  <div className="fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">
                                      Ticket has been created successfully -{" "}
                                      <a href="#" className="fw-bold">
                                        #Ticket-{ticketId ? ticketId : null}
                                      </a>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {!completionStatus ? (
                        <>
                          <button
                            type="button"
                            class="btn btn-lg btn-primary ms-4 me-4"
                            data-kt-stepper-action="next"
                            onClick={handlePrev}
                          >
                            Prev
                            <span class="svg-icon svg-icon-3 ms-1 me-0">
                              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
                                                        <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                                                    </svg> */}
                            </span>
                          </button>

                          <button
                            type="button"
                            class="btn btn-lg btn-primary"
                            data-kt-stepper-action="next"
                            onClick={handleNext}
                          >
                            {stepper === 2 ? "Finish" : "Next"}
                            <span class="svg-icon svg-icon-3 ms-1 me-0">
                              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
                                                        <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                                                    </svg> */}
                            </span>
                          </button>
                        </>
                      ) : null}
                    </div>

                    {showModal ? (
                      <ModalBox
                        showModal={showModal}
                        hideModal={() => setShowModal(false)}
                        title={`${modalVal === 1 ? "Add Task" : ""} ${modalVal === 2 ? "Filter Customer" : ""
                          } ${modalVal === 3 ? "Filter Engineer" : ""}`}
                      >
                        {modalVal === 1 ? (
                          <Formik
                            initialValues={{
                              task_type: "",
                              task_particulars: "",
                              task_qty: "",
                              task_price: "",
                            }}
                            validationSchema={addTaskSchema}
                            enableReinitialize
                            onSubmit={(values, { resetForm }) => {
                              setTimeout(() => {
                                addTask(values);
                                // setSubmitting(false);
                                resetForm();
                              }, 500);
                              return false;
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <div
                                className="d-flex flex-column flex-root"
                                id="kt_app_root"
                              >
                                <div className="w-md">
                                  {/* <form className="form w-100" id="kt_sign_in_form" data-kt-redirect-url="../../demo1/dist/index.html" action="#"> */}
                                  <form onSubmit={handleSubmit}>
                                    {/* Type of Task */}
                                    <div className="row mb-6">
                                      <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                        <span className="required">
                                          Type of Task
                                        </span>
                                      </label>
                                      <div className="col-lg-8 fv-row">
                                        <select
                                          name="task_type"
                                          aria-label="Select a Task"
                                          onChange={handleChange}
                                          value={values.task_type}
                                          data-placeholder="Select Type of Task..."
                                          className="form-select form-select-solid form-select-lg fw-semibold"
                                        >
                                          <option>-- Select --</option>
                                          <option value="0">Repairing</option>
                                          <option value="1">
                                            Goods Delivery
                                          </option>
                                          <option value="2">Both</option>
                                        </select>
                                      </div>
                                    </div>

                                    {errors.task_type &&
                                      touched.task_type &&
                                      errors.task_type}

                                    {/* Particulars */}
                                    <div className="row mb-6">
                                      <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                        <span className="required">
                                          Particulars
                                        </span>
                                      </label>
                                      <div className="col-lg-8 fv-row">
                                        <textarea
                                          onChange={handleChange}
                                          value={values.task_particulars}
                                          className="form-control form-control-solid"
                                          rows="2"
                                          name="task_particulars"
                                          placeholder=""
                                        ></textarea>
                                      </div>
                                    </div>

                                    {errors.task_particulars &&
                                      touched.task_particulars &&
                                      errors.task_particulars}

                                    {/* Quantity */}
                                    <div className="row mb-6">
                                      <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                        <span>Quantity</span>
                                      </label>
                                      <div className="col-lg-8 fv-row">
                                        <input
                                          type="text"
                                          placeholder=""
                                          name="task_qty"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.task_qty}
                                          autoComplete="off"
                                          className="form-control bg-transparent"
                                        />
                                      </div>
                                    </div>

                                    {errors.task_qty &&
                                      touched.task_qty &&
                                      errors.task_qty}

                                    {/* Price */}
                                    <div className="row mb-6">
                                      <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                        <span>Price</span>
                                      </label>
                                      <div className="col-lg-8 fv-row">
                                        <input
                                          type="text"
                                          placeholder=""
                                          name="task_price"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.task_price}
                                          autoComplete="off"
                                          className="form-control bg-transparent"
                                        />
                                      </div>
                                    </div>

                                    {errors.task_price &&
                                      touched.task_price &&
                                      errors.task_price}

                                    <div class="text-center">
                                      <button
                                        type="reset"
                                        id="kt_modal_new_target_cancel"
                                        class="btn btn-light me-3"
                                        onClick={() => setShowModal(false)}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        id="kt_modal_new_target_submit"
                                        // onClick={handleClickSubmit}
                                        class="btn btn-primary"
                                      >
                                        <span class="indicator-label">
                                          Submit
                                        </span>
                                        <span class="indicator-progress">
                                          Please wait...
                                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </Formik>
                        ) : null}
                        {modalVal === 2 ? (
                          <Formik
                            initialValues={{ filter_by: "", filter_value: "" }}
                            // validationSchema={addTaskSchema}
                            enableReinitialize
                            onSubmit={(values, { resetForm }) => {
                              setTimeout(() => {
                                fetchAllCustomers(values);
                                resetForm();
                              }, 500);
                              return false;
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <div
                                className="d-flex flex-column flex-root"
                                id="kt_app_root"
                              >
                                <div className="w-md">
                                  {/* <form className="form w-100" id="kt_sign_in_form" data-kt-redirect-url="../../demo1/dist/index.html" action="#"> */}
                                  <form onSubmit={handleSubmit}>
                                    <div
                                      className="d-flex flex-column flex-root"
                                      id="kt_app_root"
                                    >
                                      <div className="w-md">
                                        <div className="row mb-6">
                                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                            <span>Filter By</span>
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <select
                                              name="filter_by"
                                              onChange={handleChange}
                                              value={values.filter_by}
                                              aria-label="Select a Task"
                                              data-placeholder="Select Filter By..."
                                              className="form-select form-select-solid form-select-lg fw-semibold"
                                            >
                                              <option>-- Select --</option>
                                              <option value="customer_id">
                                                Id
                                              </option>
                                              <option value="customer_name">
                                                Name
                                              </option>
                                              <option value="customer_contact_1">
                                                Phone No
                                              </option>
                                              <option value="customer_email">
                                                Email
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="row mb-6">
                                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                            <span>Value</span>
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="text"
                                              placeholder=""
                                              name="filter_value"
                                              autoComplete="off"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.filter_value}
                                              className="form-control bg-transparent"
                                            />
                                          </div>
                                        </div>

                                        <div class="text-center">
                                          <button
                                            type="reset"
                                            id="kt_modal_new_target_cancel"
                                            class="btn btn-light me-3"
                                            onClick={() => setShowModal(false)}
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="submit"
                                            id="kt_modal_new_target_submit"
                                            // onClick={handleClickSubmit}
                                            class="btn btn-primary"
                                          >
                                            <span class="indicator-label">
                                              Submit
                                            </span>
                                            <span class="indicator-progress">
                                              Please wait...
                                              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </Formik>
                        ) : null}
                        {modalVal === 3 ? (
                          <Formik
                            initialValues={{ filter_by: "", filter_value: "" }}
                            // validationSchema={addTaskSchema}
                            enableReinitialize
                            onSubmit={(values, { resetForm }) => {
                              setTimeout(() => {
                                fetchAllEngineers(values);
                                resetForm();
                              }, 500);
                              return false;
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <div
                                className="d-flex flex-column flex-root"
                                id="kt_app_root"
                              >
                                <div className="w-md">
                                  {/* <form className="form w-100" id="kt_sign_in_form" data-kt-redirect-url="../../demo1/dist/index.html" action="#"> */}
                                  <form onSubmit={handleSubmit}>
                                    <div
                                      className="d-flex flex-column flex-root"
                                      id="kt_app_root"
                                    >
                                      <div className="w-md">
                                        <div className="row mb-6">
                                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                            <span>Filter By</span>
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <select
                                              name="filter_by"
                                              onChange={handleChange}
                                              value={values.filter_by}
                                              aria-label="Select a Task"
                                              data-placeholder="Select Filter By..."
                                              className="form-select form-select-solid form-select-lg fw-semibold"
                                            >
                                              <option>-- Select --</option>
                                              <option value="user_id">
                                                Id
                                              </option>
                                              <option value="user_full_name">
                                                Name
                                              </option>
                                              <option value="user_contact_1">
                                                Phone No
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="row mb-6">
                                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                            <span>Value</span>
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="text"
                                              placeholder=""
                                              name="filter_value"
                                              autoComplete="off"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.filter_value}
                                              className="form-control bg-transparent"
                                            />
                                          </div>
                                        </div>

                                        <div class="text-center">
                                          <button
                                            type="reset"
                                            id="kt_modal_new_target_cancel"
                                            class="btn btn-light me-3"
                                            onClick={() => setShowModal(false)}
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="submit"
                                            id="kt_modal_new_target_submit"
                                            // onClick={handleClickSubmit}
                                            class="btn btn-primary"
                                          >
                                            <span class="indicator-label">
                                              Submit
                                            </span>
                                            <span class="indicator-progress">
                                              Please wait...
                                              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </Formik>
                        ) : null}
                      </ModalBox>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;