import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { updateCompany } from "../../services/CompanyService";

const ChangeCompany = (props) => {
  useEffect(() => {}, []);
  const validateInput = (values) => {
    const errors = {};
    if (!values.user_company) {
      errors.user_company = "Enter Company Name";
    }
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{
          user_company: props.company_name,
        }}
        validate={validateInput}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          let userResp;
          setTimeout(async () => {
            userResp = await updateCompany({
              company_id: props.company_id,
              company_name: values.user_company,
            });
            if (userResp.status === 200) {
              props.handleSuccess({ company_name: values.user_company });
            } else {
              props.handleFailure("Something went wrong");
            }
          }, 500);
          return false;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div className="d-flex flex-column flex-root" id="kt_app_root">
            <div className="w-md">
              <form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="row mb-1">
                    <div className="col-lg-12 mt-2">
                      <div className="fv-row mb-0">
                      <label htmlFor="user_company" className="form-label fs-6 mb-3">Company Name</label>

                        <input
                          type="text"
                          placeholder="Enter company name"
                          name="user_company"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.user_company}
                          autoComplete="off"
                          className="form-control bg-transparent"
                        />
                        {errors.user_company && touched.user_company ? (
                          <div className="error">{errors.user_company}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-5">
                    <button type="submit" className="btn btn-primary me-3">
                      <span className="indicator-label">Submit</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ChangeCompany;
