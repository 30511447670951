import axios from "axios";
import { allNotificaitonsURL, latestNotificationsURL, updateNotificationsURL } from "../constants/ApiEndPoints";

export const getAllNotifications = async (payload) => {
  // console.log('service: ', payload);
  try {
    const response = await axios.post(`${allNotificaitonsURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getLatestNotifications = async (payload) => {
    // console.log('service: ', payload);
    try {
      const response = await axios.post(`${latestNotificationsURL}`, payload);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }

  export const updateNotifications = async (payload) => {
    // console.log('service: ', payload);
    try {
      const response = await axios.post(`${updateNotificationsURL}`, payload);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }
  
  