import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { incrementItemQty, decrementItemQty, resetCart } from '../../store/actions/cartSlice';
import { generateOrderId, placeOrder } from "../../services/OrderServices";
import { toastSuccess, toastError } from '../../shared/Toast';
import { getUser } from '../../shared/UserUtil';
import { OrderReviewStatus } from "../../shared/Constants";

const Cart = () => {
    let navigate = useNavigate();
    const cart_data = useSelector((state) => state.cart.cart_data);
    const dispatch = useDispatch();

    const handlePlaceOrder = async () => {
        var current_user = getUser();

        let payload = { user_id: current_user.user_id };
        const resp = await generateOrderId(payload);
        let cart_items = [];

        if (resp.status === 200) {
            console.log(cart_data);
            await cart_data.map(v => {
                console.log(v);
                cart_items.push([
                    resp.order_id,
                    current_user.user_id,
                    v.user_id,
                    v.vendor_product_id,
                    v.product_cart_qty,
                    v.product_price
                ])
            })
        }

        let place_order_resp = await placeFinalOrder(cart_items);

        if (place_order_resp.status === 200) {
            toastSuccess("Order has been placed successfully!");

            setTimeout(() => {
                dispatch(resetCart());
                navigate('/dashboard/view-products');
            }, 2000);
        } else {
            // Empty cart data
        }

    }

    const placeFinalOrder = async (cart_items) => {
        return await placeOrder({ orders: JSON.stringify(cart_items) });
    }

    const decrement = (v, indx) => {
        dispatch(decrementItemQty(indx));
    }

    const increment = (v, indx) => {
        dispatch(incrementItemQty(indx));
    }

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">

                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">My Cart</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard/view-products" className="text-muted text-hover-primary">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">My Cart</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-xxl"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div className="table-responsive">
                                            <table
                                                id="tbl_tickets"
                                                className="table table-row-dashed table-row-gray-300 gy-7"
                                            >
                                                <thead>
                                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                            TITLE/TYPE
                                                        </th>
                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                            BRAND
                                                        </th>
                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                            MODEL
                                                        </th>
                                                        <th className="p-0 pb-3 min-w-50px text-start">
                                                            PRICE
                                                        </th>
                                                        <th className="p-0 pb-3 min-w-100px text-start">
                                                            QTY
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cart_data?.length == 0 ? <div className="text-start">No Items Selected</div> : ""}
                                                    {cart_data?.map((v, i) => {
                                                        return (
                                                            <>
                                                                <tr key={i}>
                                                                    <td className="text-start p-0 ">
                                                                        <span className="text-gray-600 fs-7 text-hover-primary">
                                                                            {
                                                                                v.product_type
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-start p-0 ">
                                                                        <span className="text-gray-600 fs-7 text-hover-primary">
                                                                            {
                                                                                v.product_brand
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-start p-0">
                                                                        <span className="text-gray-600 fs-7 text-hover-primary">
                                                                            {
                                                                                v.product_model
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-start p-0">
                                                                        <span className="text-gray-600 fs-7 text-hover-primary">
                                                                            {
                                                                                v.product_price
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="col-lg-6">
                                                                            <div className="input-group">
                                                                                <span className="input-group-btn"><button onClick={() => decrement(v, i)} className="btn btn-default value-control" data-action="minus" data-target="font-size"><i class="bi bi-dash-lg"></i></button></span>
                                                                                <span className="input-group-btn"><button onClick={() => increment(v, i)} className="btn btn-default value-control" data-action="plus" data-target="font-size"><i class="bi bi-plus-lg"></i></button></span>
                                                                                <input type="text" value={v.product_cart_qty} className="form-control" id="font-size" />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <button className={`btn btn-primary ${cart_data.length ? "" : "disabled"}`} onClick={() => handlePlaceOrder()} >Place Order</button>

                                    </div></div></div></div></div></div>
                <ToastContainer />
            </div>
        </>
    )
}

export default Cart;