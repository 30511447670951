import axios from "axios";
import { saveAs } from 'file-saver'
import {
  getAllVendorProductsURL, getAllStockProductsURL, uploadStockProductsURL,
  uploadVendorProductsURL, updateVendorProductURL, deleteVendorProductURL,
  downloadVendorProductURL, updateProductQtyURL, addVendorProductURL,
  approveProductsURL, addVendorServiceURL, updateVendorServiceURL,
  uploadStockServicesURL, getAllCategoriesURL, downloadTemplateURL,
  getAllStockProductsByKeywordURL,productDeliveryMethodtURL
} from "../constants/ApiEndPoints";
import { RecordType } from "../shared/Constants";
import { formatDate } from "../shared/DateUtil";


export const updateQtyVendorProducts = async (payload) => {
  try {
    const response = await axios.post(`${updateProductQtyURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getAllVendorProducts = async (payload) => {
  try {
    const response = await axios.post(`${getAllVendorProductsURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const deleteVendorProduct = async (payload) => {
  try {
    const response = await axios.post(`${deleteVendorProductURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}


export const updateVendorProduct = async (payload) => {
  try {
    const response = await axios.put(`${updateVendorProductURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const updateVendorService = async (payload) => {
  try {
    const response = await axios.put(`${updateVendorServiceURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const addVendorService = async (payload) => {
  try {
    const response = await axios.put(`${addVendorServiceURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const approveProduct = async (payload) => {
  try {
    const response = await axios.post(`${approveProductsURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const addVendorProduct = async (payload) => {
  try {
    const response = await axios.put(`${addVendorProductURL}`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}


export const getAllStockProducts = async (payload) => {
  try {
    const response = await axios.post(`${getAllStockProductsURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}


export const getAllStockProductsByKeyword = async (payload) => {
  try {
    const response = await axios.post(`${getAllStockProductsByKeywordURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const productsDeliveryMethod = async (payload) => {
  try {
    const response = await axios.get(`${productDeliveryMethodtURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}
export const uploadStockProducts = async (payload) => {
  try {
    const response = await axios.post(`${uploadStockProductsURL}`, payload);
    console.log(payload)
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const uploadStockServices = async (payload) => {
  try {
    const response = await axios.post(`${uploadStockServicesURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const uploadVendorProducts = async (payload) => {
  try {
    const response = await axios.post(`${uploadVendorProductsURL}`, payload);
    if (response.status === 200) {
      const data = response.data;
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const downloadFile = async (payload) => {
  try {
    const response = await axios.post(`${downloadVendorProductURL}`, payload, { responseType: 'blob' });
    if (response.status === 200) {
      let downloadURL = window.URL.createObjectURL(response.data);
      saveAs(downloadURL, (payload.record_type === RecordType.Product ? "Products_" : "Services_") + formatDate(new Date(), 'default') + ".xlsx");
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const downloadTemplate = async (is_service) => {
  try {
    const response = await axios.get(`${downloadTemplateURL}?is_service=${is_service}`, { responseType: 'blob' });
    if (response.status === 200) {
      let downloadURL = window.URL.createObjectURL(response.data);
      saveAs(downloadURL, ( !is_service ? "Products_Template_" : "Services_Template_") + formatDate(new Date(), 'default') + ".xlsx");
    }
  } catch (error) {
    return error?.response?.data;
  }
}

export const getAllCategories = async (order_by) => {
  try {
    const response = await axios.get(`${getAllCategoriesURL}?order_by=`+order_by);
    if (response.status === 200) {
      const data = response.data;
      console.log(data);
      // if (data.status === 200 && data.token) {
      //   sessionStorage.setItem("token", data.token);
      // }
      return data;
    }
  } catch (error) {
    return error?.response?.data;
  }
}
