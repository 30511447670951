import React, { useState, useEffect, useRef } from "react";
import Papa from "papaparse";
import { read, utils, writeFile } from 'xlsx';

import { toastSuccess, toastError } from '../shared/Toast';

const CSVParse = (props) => {
    const allowedExtensions = ["csv", "xlsx"];
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [file, setFile] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        console.log(props)
        props.setRefreshFunction(() => fileUploadReset);
    }, []);

    const handleFileChange = (e) => {
        setError("");
        let inputFile = '';
        let extension = '';
        if (e.target.files.length) {
            inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];
            extension = inputFile.name.substring(inputFile.name.lastIndexOf(".")+1);
            if (!allowedExtensions.includes(extension)) {
                toastError(`Please input a ${allowedExtensions.toString()} file`);
                return;
            }
            setFile(inputFile);
        }

        if (!inputFile) return toastError("Enter a valid file");
        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();
        // Event listener on reader when the file
        // loads, we parse it and set the data.
        if (extension === "csv") {
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: true });
                const parsedData = csv?.data;
                setData(parsedData);
                // console.log(parsedData)
                props.handleUploadedProducts(parsedData, true);
            };
            reader.readAsText(inputFile);
        } else if (extension === "xlsx") {
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setData(rows)
                    props.handleUploadedProducts(rows, false);
                }
            }
            reader.readAsArrayBuffer(inputFile);
        }
    }

    // Reset File Upload
    const fileUploadReset = () => {
        // if (document.getElementById('csvInput').value) {
        //     document.getElementById('csvInput').value = null;
        // }
        inputRef.current.value = null;
    }

    return (
        <div>
            <div class="mb-3">
                <label htmlFor="formFileSm" class="form-label"></label>
                <input ref={inputRef}  class="form-control form-control-sm" onChange={handleFileChange} id="csvInput" name="file" type="file" />
            </div>
        </div>
    )
}

export default CSVParse;