import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { updatePassword } from "../../services/UserServices";

const ChangePassword = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [showCurrentPassword, setShowCurrentPassword] = useState(props.showCurrentPassword);
    useEffect(() => {

    }, []);

    const handleChangePassword = async (values) => {
        let payload = { user_id: props.user_id, user_password: values.new_password, current_password: values.current_password, is_admin: !showCurrentPassword }
        let userResp = await updatePassword(payload);

        if (userResp.status === 200) {
            // notify(1, "Password Changed Successfully!");
            props.handleSuccess();
        } else {
            console.log(userResp)
            props.handleFailure(userResp.msg);
        }
    }
    const validateInput = (values) => {
        const errors = {}
        var minNumberofChars = 6;
        var maxNumberofChars = 16;
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;


        if (showCurrentPassword === true && !values.current_password) {
            errors.current_password = 'Current Password cannot be empty';
        }
        if (!values.new_password) {
            errors.new_password = 'New Password cannot be empty';
        }
        else if (values.new_password.length < minNumberofChars) {
            errors.new_password = `New Password must have minimum ${minNumberofChars} characters`;
        }
        else if (!regularExpression.test(values.new_password)) {
            errors.new_password = ("New Password should contain atleast one number and one special character");
        }
        if (!values.confirm_password) {
            errors.confirm_password = 'Confirm Password cannot be empty';
        }
        if (showCurrentPassword === true) {
            if (values.new_password !== values.confirm_password) {
                errors.confirm_password = 'New and Confirm Passwords are mismatched';
            }
            else if (values.current_password && values.current_password === values.new_password && values.current_password === values.confirm_password) {
                errors.confirm_password = 'Current and New password cannot be same';
            }
        }
        return errors;
    }

    return (
        <>

            <Formik
                initialValues={{
                    current_password: "",
                    new_password: "",
                    confirm_password: ""
                }}
                validate={validateInput}
                enableReinitialize
                onSubmit={(values, { resetForm }) => {
                    setTimeout(() => {
                        if (handleChangePassword(values) === true) {
                            resetForm();
                        }
                    }, 500);
                    return false;
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <div
                        className="d-flex flex-column flex-root"
                        id="kt_app_root"
                    >
                        <div className="w-md">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <div className="row mb-1">
                                        {showCurrentPassword ?
                                            <div className="col-lg-12">
                                                <div className="fv-row mb-0">
                                                    <label htmlFor="currentpassword" className="form-label fs-6 mb-3">Current Password</label>
                                                    <input type="password" onChange={handleChange} onBlur={handleBlur} value={values.current_password} className="form-control bg-transparent" name="current_password" id="current_password" />
                                                    {errors.current_password && touched.current_password ? <div className="error">{errors.current_password}</div> : null}
                                                </div>
                                            </div> : null
                                        }
                                        <div className="col-lg-12 mt-2">
                                            <div className="fv-row mb-0">
                                                <label htmlFor="newpassword" className="form-label fs-6 mb-3">New Password</label>
                                                <input type="password" onChange={handleChange} onBlur={handleBlur} value={values.new_password} className="form-control bg-transparent" name="new_password" id="new_password" />
                                                {errors.new_password && touched.new_password ? <div className="error">{errors.new_password}</div> : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="fv-row mb-0 mt-5">
                                                <label htmlFor="confirmpassword" className="form-label fs-6 mb-3">Confirm New Password</label>
                                                <input type="password" onChange={handleChange} onBlur={handleBlur} value={values.confirm_password} className="form-control bg-transparent" name="confirm_password" id="confirm_password" />
                                                {errors.confirm_password && touched.confirm_password ? <div className="error">{errors.confirm_password}</div> : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center mt-5">
                                        <button type="submit" className="btn btn-primary me-3">
                                            <span className="indicator-label">
                                                Save
                                            </span>
                                        </button>

                                        {/* <button
                                            type="reset"
                                            id="kt_modal_new_target_cancel"
                                            className="btn btn-light me-3"
                                            onClick={() => props.hideModal()}
                                        >
                                            Cancel
                                        </button> */}
                                    </div>
                                </div>

                                {/* <div class="text-center">
                                                                <button
                                                                    type="reset"
                                                                    id="kt_modal_new_target_cancel"
                                                                    class="btn btn-light me-3"
                                                                    onClick={() => setShowModal(false)}
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                    id="kt_modal_new_target_submit"
                                                                    // onClick={handleClickSubmit}
                                                                    class="btn btn-primary"
                                                                >
                                                                    <span class="indicator-label">
                                                                        Submit
                                                                    </span>
                                                                    <span class="indicator-progress">
                                                                        Please wait...
                                                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                    </span>
                                                                </button>
                                                            </div> */}


                            </form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    )
}

export default ChangePassword;