import React, { useEffect } from "react";
import { Outlet } from "react-router";
import LeftMenu from "../../components/Layout/LeftMenu";
import TopMenu from "../../components/Layout/TopMenu";

const DashboardHome = () => {

    useEffect(() => {       
    }, [])

    return (
        <>
            <div data-kt-name="metronic" id="kt_app_body" data-kt-app-layout="dark-sidebar" data-kt-app-header-fixed="true" data-kt-app-sidebar-enabled="true" data-kt-app-sidebar-fixed="true" data-kt-app-sidebar-hoverable="true" data-kt-app-sidebar-push-header="true" data-kt-app-sidebar-push-toolbar="true" data-kt-app-sidebar-push-footer="true" data-kt-app-toolbar-enabled="true" className="app-default">
                <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                        <TopMenu />
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                            <LeftMenu />
                           
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardHome;