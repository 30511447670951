import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Form from 'react-bootstrap/Form';

import { getCompany, updateCompany } from '../../services/CompanyService';
import Loader from '../../shared/Loader';
import { Link } from "react-router-dom";
import { toastError, toastSuccess } from "../../shared/Toast";
import { getUser } from "../../shared/UserUtil";

const CompanyDetail = () => {
    const [companyProfile, setCompanyProfile] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const currentUser = getUser();

    useEffect(() => {
        fetchCompanyProfile();
    }, []);

    const fetchCompanyProfile = async () => {
        setIsLoading(true);
        const resp = await getCompany({ company_id: currentUser.company_id });
        console.log(resp);
        if (resp && resp.data && resp.data.length > 0)
            setCompanyProfile(resp.data[0]);
        setIsLoading(false);
        //setCompanyId(currentUser.company_id);
    }

    const validateInput = (values) => {
        console.log(values);
        let errors = {};

        if (values.company_name) {
            errors.company_name = 'Company Name cannot be empty!'
        }

        return errors;
    }

    const updateCompanyProfile = async (values) => {
        setIsLoading(true);
        console.log(values);
        let companyResponse = await updateCompany({ company_id: currentUser.company_id, has_auto_approval: values.has_auto_approval, company_name: values.company_name })
        if (companyResponse.status === 200) {
            let company = companyProfile;
            company.has_auto_approval = values.has_auto_approval;
            setCompanyProfile(company);
            toastSuccess(`Company Updated Successfully!`);
            //return true;
        } else {
            toastError(companyResponse.msg || companyResponse.message);
            //return false;
        }
        setIsLoading(false);
    }
    return (
        <>
            {
                isLoading ? <Loader /> :
                    <div
                        className="app-main flex-column flex-row-fluid mt-8"
                        id="kt_app_main"
                    >
                        <div className="d-flex flex-column flex-column-fluid">

                            {/* Breadcrumb Start*/}
                            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Settings</h1>
                                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                            <li className="breadcrumb-item text-muted">
                                                <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                            </li>
                                            <li className="breadcrumb-item text-muted">Settings</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Breadcrumb End */}

                            <div id="kt_app_content" className="app-content flex-column-fluid ">
                                <div id="kt_app_content_container" className="app-container container-xxl">
                                    <div className="card">
                                        <div className="card-body">
                                            <Formik
                                                initialValues={{
                                                    company_name: companyProfile?.company_name,
                                                    has_auto_approval: companyProfile?.has_auto_approval,
                                                    admin_email: companyProfile?.admin_email
                                                }}
                                                validate={validateInput}
                                                enableReinitialize
                                                onSubmit={(values, { resetForm }) => {
                                                    setTimeout(() => {
                                                        updateCompanyProfile(values);
                                                    }, 500);
                                                    return false;
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    isSubmitting,
                                                }) => (
                                                    <div
                                                        className="d-flex flex-column flex-root"
                                                        id="kt_app_root"
                                                    >
                                                        <div className="w-md">
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="row mb-6">
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span>Company Name</span>
                                                                    </label>
                                                                    <div className="col-lg-5 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            name="company_name"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.company_name}
                                                                            autoComplete="off"
                                                                            className="form-control bg-transparent text-capitalize"
                                                                            disabled
                                                                        />
                                                                        {errors.company_name && touched.company_name ? <div className="error">{errors.company_name}</div> : null}
                                                                    </div>
                                                                </div>
                                                                {/* User First Name */}
                                                                <div className="row mb-6">
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span>Auto Approval?</span>
                                                                    </label>
                                                                    <div className="col-lg-5 fv-row">
                                                                        <Form.Check name="has_auto_approval"
                                                                            type="switch"
                                                                            label=""
                                                                            defaultChecked={values.has_auto_approval} onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-6">
                                                                    <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                                                        <span>Email</span>
                                                                    </label>
                                                                    <div className="col-lg-5 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            name="admin_email"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.admin_email}
                                                                            autoComplete="off"
                                                                            className="form-control bg-transparent"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="text-center">
                                                                    <button
                                                                        type="submit"
                                                                        disabled={isSubmitting}
                                                                        id="kt_modal_new_target_submit"
                                                                        class="btn btn-primary" onClick={() => updateCompanyProfile(values)}>
                                                                        <span class="indicator-label">
                                                                            Submit
                                                                        </span>
                                                                        <span class="indicator-progress">
                                                                            Please wait...
                                                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span>
                                                                    </button>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default CompanyDetail;