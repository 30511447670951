import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./LeftMenu.css";
import { getUserData, getUser } from '../../shared/UserUtil';
import double_arrow_left from "../../assets/images/double-arrow-left.svg";
import ModalBox from "../../shared/Modal";
import { updateTerms } from '../../services/UserServices';
import { toastSuccess, toastError } from '../../shared/Toast';
import { UserMode, UserType, ComponentPages } from '../../shared/Constants'
import logo from "../../assets/media/auth/cmda-logo-3.png";

const LeftMenu = () => {
  const [userType, setUserType] = useState();
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  let navigate = useNavigate();
  let currentUser = getUser();

  useEffect(() => {
    const mobileSidebarToggler = document.getElementById(
      "kt_app_sidebar_mobile_toggle"
    );

    mobileSidebarToggler.onclick = (ev) => {
      document
        .querySelector(".app-sidebar")
        .classList.toggle("drawer", "drawer-start", "drawer-on");
    };
    // console.log(mobileSidebarToggler);
    // Only on mobile screen, hide the sidebar when the user clicks away from the sidebar itself
    if (window.screen.height < 992) {
      document.body.onclick = (ev) => {
        if (ev.target.closest("#kt_app_sidebar_mobile_toggle")) return;
        if (ev.target.closest(".app-sidebar")) return;
        document
          .querySelector(".app-sidebar")
          .classList.remove("drawer", "drawer-start", "drawer-on");
      };
    }

    const user_data = getUser();
    //console.log("user_data", user_data);
    setUserType(user_data.user_type);
    setShowModal(!user_data.is_accepted_terms);
  }, []);

  const handleClickSidebarToggler = (ev) => {
    const WIDTH_WHEN_OPEN = "265px"; // Upon inspection, The theme uses this width for the sidebar when expanded
    const WIDTH_WHEN_COLLAPSED = "75px"; // Upon inspection, The theme uses this width for the collapsed sidebar

    const root = document.documentElement;
    const sidebarWidth = getComputedStyle(root).getPropertyValue(
      "--kt-app-sidebar-width"
    );
    const sidebarOpened = parseInt(sidebarWidth) === 265;

    ev.currentTarget.classList.toggle("active");
    root.style.setProperty(
      "--kt-app-sidebar-width",
      sidebarOpened ? WIDTH_WHEN_COLLAPSED : WIDTH_WHEN_OPEN
    );
    document.querySelector(".app-sidebar").classList.toggle("collapsed");
    const [trigger, subMenu] =
      document.querySelector(".menu-accordion").children;
    subMenu.classList[sidebarOpened ? "add" : "remove"]("d-none");
    trigger
      .querySelector(".menu-title")
      .classList[sidebarOpened ? "add" : "remove"]("d-none");
    trigger
      .querySelector(".menu-arrow")
      .classList[sidebarOpened ? "add" : "remove"]("d-none");
  };

  const handleClickMenuTrigger = (ev) => {
    const trigger = ev.currentTarget.parentElement;
    trigger.classList.toggle("show");
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_data');
    navigate('/');
    window.location.reload();
  }

  // const sideBarStyles = {
  //   greenStyle: {
  //     backgroundColor: 'green'
  //   },
  //   orangeStyle: {
  //     backgroundColor: 'orange'
  //   }
  // }

  const updateTermsConditions = async () => {
    setShowModal(false);
    const currentUser = getUserData();
    if (checked) {
      let userResp = await updateTerms({
        user_id: currentUser.user.user_id
      });

      if (userResp.status === 200) {
        currentUser.user.is_accepted_terms = true;
        sessionStorage.setItem("user_data", JSON.stringify(currentUser));

        toastSuccess("Updated terms and conditions successfully!");
        window.location.reload();
      } else {
        toastError(userResp.msg || userResp.message);
      }
    }
  }

  // const hideModal = () =>{
  //   setShowModal(false); 
  //   window.location.reload();
  // }


  const getActiveLink = () => {
    switch (window.location.pathname.toLocaleLowerCase().replace("/dashboard", "")) {
      case "/orders":
        return ComponentPages.DashboardOrder;
      case "/add-product":
        return ComponentPages.ManageProduct;
      case "/product-details":
      case "/view-products":
        return ComponentPages.ViewProduct;
      case "/add-company":
        return ComponentPages.Company;
      case "/add-user":
        return ComponentPages.User;
      case "/enquiry":
        return ComponentPages.Enquiries;
      case "/home":
        return ComponentPages.Home;
      default:
        return "";
    }
  }

  return (
    <>
      <div
        id="kt_app_sidebar"
        className={getUser().user_type > UserType.Admin ? (getUser().mode === UserMode.Buyer ? 'app-sidebar flex-column buyer' : 'app-sidebar flex-column seller') : 'app-sidebar flex-column'}
        data-kt-drawer="true"
        data-kt-drawer-name="app-sidebar"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="225px"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
      /*style={getUser().mode === 1 ? sideBarStyles.greenStyle: sideBarStyles.orangeStyle}*/
      >
        <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
          {/* <p className="logo-style">CMDA</p>
          <p className="h-20px app-sidebar-logo-minimize logo-style">CMDA</p> */}
          <img src={logo} className="theme-light-show mx-auto mw-100 w-100px" alt="CMDA MAHA TRADE"></img>
          <div
            id="kt_app_sidebar_toggle"
            className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="app-sidebar-minimize"
            onClick={handleClickSidebarToggler}
          >
            <span className="svg-icon svg-icon-2 rotate-180">
              <img src={double_arrow_left} alt="Shrink left" />
            </span>
          </div>
        </div>
        <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
          <div
            id="kt_app_sidebar_menu_wrapper"
            className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
            data-kt-scroll-wrappers="#kt_app_sidebar_menu"
            data-kt-scroll-offset="5px"
            data-kt-scroll-save-state="true"
          >
            <div
              className="menu menu-column menu-rounded menu-sub-indention px-3"
              id="#kt_app_sidebar_menu"
              data-kt-menu="true"
              data-kt-menu-expand="false"
            >
              <div
                data-kt-menu-trigger="click"
                className="menu-item here show menu-accordion"
              >
                <span className="menu-link" onClick={handleClickMenuTrigger}>
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="2"
                          y="2"
                          width="9"
                          height="9"
                          rx="2"
                          fill="currentColor"
                        />
                        <rect
                          opacity="0.3"
                          x="13"
                          y="2"
                          width="9"
                          height="9"
                          rx="2"
                          fill="currentColor"
                        />
                        <rect
                          opacity="0.3"
                          x="13"
                          y="13"
                          width="9"
                          height="9"
                          rx="2"
                          fill="currentColor"
                        />
                        <rect
                          opacity="0.3"
                          x="2"
                          y="13"
                          width="9"
                          height="9"
                          rx="2"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </span>
                  <span className="menu-title">Dashboard</span>
                  <span className="menu-arrow"></span>
                </span>
                <div className="menu-sub menu-sub-accordion">
                  {userType === UserType.User && currentUser.user_mode === UserMode.Buyer ?
                    <Link to="/dashboard/home" className="menu-item">
                      {/* <a className="menu-link active"> */}
                      <span className={getActiveLink() === ComponentPages.Home ? "menu-link  active" : "menu-link"}>
                        {/* <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span> */}
                        <i className="fa fa-home me-2" aria-hidden="true" title="All Home"></i>
                        <span className="menu-title">Home</span>
                      </span>
                    </Link> : null
                  }
                  <Link to="/dashboard/orders" className="menu-item">
                    {/* <a className="menu-link active"> */}
                    <span className={getActiveLink() === ComponentPages.DashboardOrder ? "menu-link  active" : "menu-link"}>
                      {/* <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span> */}
                      <i className="fa fa-cart-shopping me-2" aria-hidden="true" title="All Orders"></i>
                      <span className="menu-title">Orders</span>
                    </span>
                  </Link>
                  {
                    (userType === 1 || userType === 0) ? // Only for Seller                    
                      <Link to="/dashboard/add-company" className="menu-item">
                        <span className={getActiveLink() === ComponentPages.Company ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-building me-2" aria-hidden="true" title="Manage Companies"></i>
                          <span className="menu-title">Companies</span>
                        </span>
                      </Link>
                      :
                      null
                  }
                  {
                    // (userType === 2 && (getUser().mode === 3 || getUser().mode === 2)) ? // Only for Seller                    
                    (userType === 2) ?               
                      <Link to="/dashboard/add-user" className="menu-item">
                        <span className={getActiveLink() === ComponentPages.User ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-users me-2" aria-hidden="true" title="Manage Users"></i>
                          <span className="menu-title">Users</span>
                        </span>
                      </Link>
                      :
                      null
                  }
                  {

                    (getUser().user_type <= UserType.Admin || getUser().mode === 3 || getUser().mode === 2 ) ? // Only for Seller                    
                      <Link to="/dashboard/add-product" className="menu-item">
                        <span className={getActiveLink() === ComponentPages.ManageProduct ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-bars me-2" aria-hidden="true" title="Manage Products"></i>
                          <span className="menu-title">Products</span>
                        </span>
                      </Link>
                      :
                      null
                  }

                  {
                    (getUser().user_type > UserType.Admin && getUser().mode === 1) ? // Only for Buyer 
                      <Link to="/dashboard/view-products" className="menu-item">
                        <span
                          className={getActiveLink() === ComponentPages.ViewProduct ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-bars me-2" aria-hidden="true" title="View Products"></i>
                          <span className="menu-title">Products</span>
                        </span>
                      </Link>
                      :
                      null
                  }

                  {
                    (getUser().user_type <= UserType.Admin || getUser().mode === UserMode.Buyer) ?
                      <Link to="/dashboard/enquiry" className="menu-item">
                        <span className={getActiveLink() === ComponentPages.Enquiries ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-comments me-2" aria-hidden="true" title="Manage Enquiries"></i>
                          <span className="menu-title">Enquiries</span>
                        </span>
                      </Link>
                      :
                      null
                  }
                  {
                    (getUser().user_type <= UserType.Admin) ?
                      <Link to="/dashboard/category" className="menu-item">
                        <span className={getActiveLink() === ComponentPages.Category ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-suitcase me-2" aria-hidden="true" title="Manage Category"></i>
                          <span className="menu-title">Category</span>
                        </span>
                      </Link>
                      :
                      null
                  }
                  {
                    (getUser().user_type < UserType.Admin) ?
                      <Link to="/dashboard/ads" className="menu-item">
                        <span className={getActiveLink() === ComponentPages.Ads ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-paperclip me-2" aria-hidden="true" title="Manage Ads"></i>
                          <span className="menu-title">Ads</span>
                        </span>
                      </Link>
                      :
                      null
                  }
                  {
                    (getUser().user_type <= UserType.Admin) ?
                      <Link to="/dashboard/settings" className="menu-item">
                        <span className={getActiveLink() === ComponentPages.Settings ? "menu-link  active" : "menu-link"}>
                          {/* <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span> */}
                          <i className="fa fa-gears me-2" aria-hidden="true" title="Manage Enquiries"></i>
                          <span className="menu-title">Settings</span>
                        </span>
                      </Link>
                      :
                      null
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
          id="kt_app_sidebar_footer"
        >

          <span onClick={handleLogout}
            className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-dismiss-="click"
            title="Logout"
          >
            <i className="fa fa-sign-out" ></i>
            <span className="btn-label">Logout</span>
          </span>
        </div>
      </div>
      {showModal ? (
        <ModalBox
          className="terms_dialog"
          closeButton={false}
          showModal={showModal}
          title="Terms and Conditions"
        >
          <div
            className="d-flex flex-column flex-root"
            id="kt_app_root"
          >
            <div className="w-md">
              <div className="row mb-6 terms_box">
                <label className="col-lg-12 col-form-label fw-semibold fs-6">
                  There are many types of disclaimers that address liabilities for different industries, activities, and content. The most common types of disclaimers are:
                  <ol>
                    <li><strong>Fair Use Disclaimer</strong>: A fair use disclaimer discloses that you are using copyrighted materials on your website lawfully and with the appropriate permissions.</li>
                    <li><strong>Copyright Disclaimer</strong>: A copyright disclaimer lets site visitors know that your site contains copyrighted material.</li>
                    <li><strong>No Responsibility Disclaimer</strong>: No responsibility disclaimers inform users that you are not responsible for the actions they take based on content found on your website.</li>
                    <li><strong>Confidentiality Disclaimer</strong>: Confidentiality disclaimers assure users that some content, such as contact information, is only intended to be seen by certain parties.</li>
                    <li><strong>Affiliate Disclaimer</strong>: Affiliate disclaimers disclose your participation in an affiliate program, such as Amazon Associates.</li>
                    <li><strong>YouTube Disclaimer</strong>: YouTube disclaimers are any disclaimer found in the video description of a YouTube video. Commonly, copyright disclaimers and no responsibility disclaimers double as YouTube disclaimers.</li>
                    <li><strong>Views Expressed Disclaimer</strong>: Views expressed disclaimers specify that the opinions found on your website are not the views or opinions of your business.</li>
                    <li><strong>Investment Disclaimer</strong>: An investment disclaimer informs readers that your investments commentary is information, and should not be taken as official investment advice.</li>
                    <li><strong>No Guarantee Disclaimer</strong>: No guarantee disclaimers announce that your website or business makes no promises about the results of a product or service.</li>
                    <li><strong>Use at Your Own Risk Disclaimer</strong>: Use at your own risk disclaimers instruct your siteâ€™s visitors to act on your content or recommendations at their own risk.</li>
                    <li><strong>Email Disclaimer</strong>: An email disclaimer is any disclaimer added to the footer of an email. Most often, confidentiality disclaimers act as email disclaimers.</li>
                    <li><strong>Past Performance Disclaimer</strong>: Past performance disclaimers state that previous results do not equal future results.</li>
                    <li><strong>Medical Disclaimer</strong>: A medical disclaimer informs users that the content on the site is provided for informational purposes only, and does not substitute professional medical advice.</li>
                  </ol>
                </label>
              </div>
              <div className="row mb-6">
                <label className="col-lg-12 col-form-label fw-semibold fs-6">
                  <input type="checkbox" name="terms" id="terms" value={checked} onChange={(e) => setChecked(e.target.checked)} /> <span> I accept the terms and conditions</span>
                </label>
              </div>
              <div className="text-center">
                {/* <button
                  type="reset"
                  id="kt_modal_new_target_cancel"
                  className="btn btn-light me-3"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button> */}
                <button
                  type="submit"
                  id="kt_modal_new_target_submit"
                  disabled={!checked}
                  className="btn btn-primary"
                  onClick={() => updateTermsConditions()}
                >
                  <span className="indicator-label">
                    Submit
                  </span>

                </button>
              </div>
            </div>
          </div>
        </ModalBox>) : null}
    </>
  );
};

export default LeftMenu;
