import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import { saveCategory, updateCategory, getAllCategories, deleteCategory } from "../../services/CategoryService";
import ModalBox from "../../shared/Modal";
import { formatDateString } from "../../shared/DateUtil";
import { toastSuccess, toastError } from '../../shared/Toast';
import { getUser } from "../../shared/UserUtil";

const CategoryPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [parentCategoryList, setParentCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Add Category');


    useEffect(() => {
        fetAllCategories();
    }, []);

    const fetAllCategories = async () => {
        let current_user_data = getUser()
        let payload = { user_id: current_user_data.user_id };
        const categoryList = await getAllCategories(payload);
        setParentCategoryList(categoryList.data.filter(f => f.parent_category_id === null));
        setCategoryList(categoryList.data);
    }

    const addNewCategory = async (values) => {
        console.log(values);
        let currentUser = getUser();
        let response = {};
        if (values.category_id) {
            response = await updateCategory({
                category_name: values.category_name,
                parent_category_id: values.parent_category_id,
                category_id: values.category_id,
                user_id: currentUser.user_id,
                category_type: values.category_type
            });
        } else {
            response = await saveCategory({
                category_name: values.category_name,
                parent_category_id: values.parent_category_id,
                user_id: currentUser.user_id,
                category_type: values.category_type
            });
        }

        if (response.status === 200) {
            setShowModal(false);
            toastSuccess("Category " + (values.category_id ? "updated" : "created") + " successfully!");
            fetAllCategories();
            return true;
        } else {
            toastError(response.msg);
            return false;
        }
    }

    const validateInput = (values) => {
        const errors = {}

        if (!values.category_name) {
            errors.category_name = 'Category Name cannot be empty!'
        }

        if (!values.category_type) {
            errors.category_type = "Category Type cannot be empty!";
        }

        return errors;
    }

    const handleEditModal = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
        setDialogTitle('Edit Category');
    }

    const handleDeleteModal = (category) => {
        setSelectedCategory(category)
        setShowDeleteModal(true);
    }

    const handleDeleteCategory = async () => {
        console.log(selectedCategory);

        let response = await deleteCategory({ category_id: selectedCategory.category_id, is_deleted: true, category_type: selectedCategory.category_type });
        if (response.status === 200) {
            toastSuccess('Category Deleted Successfully!');
            setShowDeleteModal(false);
            setSelectedCategory(null)
            fetAllCategories()
            return true;
        } else {
            toastError(response.message);
            return false;
        }
    }

    const showAddCategoryModal = () => {
        setShowModal(true);
        selectedCategory(null);
    }

    return (
        <>
            <div
                className="app-main flex-column flex-row-fluid mt-8"
                id="kt_app_main"
            >
                <div className="d-flex flex-column flex-column-fluid">
                    {/* Breadcrumb Start*/}
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 text-start">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Categories</h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard" className="text-muted text-hover-primary">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Categories</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Breadcrumb End */}

                    <div id="kt_app_content" className="app-content flex-column-fluid ">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-fluid"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="stepper stepper-links d-flex flex-column"
                                        id="kt_create_account_stepper"
                                    >
                                        <div
                                            className="mx-auto w-100 pb-10"
                                            noValidate="novalidate"
                                            id="kt_create_account_form"
                                        >
                                            {/* All Products */}
                                            <div className="w-100">
                                                <div className="card-header align-items-center mb-2">
                                                    <div
                                                        className="card-toolbar flex-row-fluid justify-content-end gap-5"
                                                        onClick={() => showAddCategoryModal()}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal"
                                                    >
                                                        <div className="btn btn-primary">+ Add Category</div>
                                                    </div>
                                                </div>

                                                <div className="fv-row">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row mb-6">
                                                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10 mt-2">
                                                                    <div className="col-xl-12">
                                                                        <div className="card card-flush h-md-100">
                                                                            {/* <div className="card-header">
                                                                                <h3 className="card-title align-items-start flex-column">
                                                                                    <span className="card-label fw-bold text-gray-800">Manage Categories</span>
                                                                                </h3>
                                                                            </div> */}
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table
                                                                                        id="tbl_tickets"
                                                                                        className="table table-row-dashed table-row-gray-300 gy-7"
                                                                                    >
                                                                                        <thead>
                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                {/* <th className="p-0 pb-3 min-w-20px text-start">
                                                                                                    PRODUCT #
                                                                                                </th> */}
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Category Name
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Parent Category Name
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Category Type
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Created At
                                                                                                </th>
                                                                                                <th className="p-0 pb-3 min-w-50px text-start">
                                                                                                    Actions
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {categoryList?.map((category, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={i}>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        category.category_name
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        category.parent_category_name
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0 ">
                                                                                                                <span className={"fw-bold fs-7 text-hover-primary badge " + (category.category_type === 1 ? 'badge-light-success' : 'badge-light-info')}>
                                                                                                                    {
                                                                                                                        category.category_type === 1 ? 'Product' : 'Service'
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <span className="text-gray-600 fw-bold fs-7 text-hover-primary">
                                                                                                                    {
                                                                                                                        formatDateString(category.created_at)
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td className="text-start p-0">
                                                                                                                <div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-3" title="Edit" onClick={() => handleEditModal(category)}><i className="fa fa-pencil" title="Edit" /></div>
                                                                                                                <div className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" title="Delete" onClick={() => handleDeleteModal(category)}><i className="fa fa-trash" title="Delete" /></div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {showModal ? (
                                            <ModalBox
                                                showModal={showModal}
                                                hideModal={() => setShowModal(false)}
                                                title={dialogTitle} className="add_category"
                                            >
                                                <Formik
                                                    initialValues={{
                                                        category_name: selectedCategory?.category_name,
                                                        parent_category_id: selectedCategory?.parent_category_id,
                                                        category_id: selectedCategory?.category_id,
                                                        category_type: (!selectedCategory) ? "1" : selectedCategory?.category_type
                                                    }}
                                                    validate={validateInput}
                                                    // validationSchema={addTaskSchema}
                                                    enableReinitialize
                                                    onSubmit={(values, { resetForm }) => {
                                                        setTimeout(() => {
                                                            if (!addNewCategory(values)) {
                                                                resetForm();
                                                            }
                                                        }, 500);
                                                        return false;
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                    }) => (
                                                        <div
                                                            className="d-flex flex-column flex-root"
                                                            id="kt_app_root"
                                                        >
                                                            <div className="w-md">
                                                                <form onSubmit={handleSubmit}>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Category Name</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row">
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                name="category_name"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.category_name}
                                                                                autoComplete="off"
                                                                                className="form-control bg-transparent"
                                                                            />
                                                                            {errors.category_name && touched.category_name ? <div className="error">{errors.category_name}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                            <span>Parent Category</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            <Form.Select
                                                                                name="parent_category_id"
                                                                                onChange={e => {
                                                                                    handleChange(e);
                                                                                }
                                                                                }
                                                                                value={values.parent_category_id}>
                                                                                <option value="-1">Select</option>
                                                                                {parentCategoryList?.map((element, index) => {
                                                                                    return <option value={element.category_id}>{element.category_name}</option>
                                                                                })
                                                                                }
                                                                            </Form.Select>
                                                                            {errors.parent_category_id && touched.parent_category_id ? <div className="error">{errors.parent_category_id}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-6">
                                                                        <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                                                                            <span>Category Type</span>
                                                                        </label>
                                                                        <div className="col-lg-8 fv-row row">
                                                                            <Form.Select
                                                                                name="category_type"
                                                                                onChange={e => {
                                                                                    handleChange(e);
                                                                                }
                                                                                }
                                                                                value={values.category_type}>
                                                                                <option value="-1">Select</option>
                                                                                <option value="1">Product</option>
                                                                                <option value="2">Service</option>
                                                                            </Form.Select>
                                                                            {errors.category_type && touched.category_type ? <div className="error">{errors.category_type}</div> : null}
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-center">
                                                                        <button
                                                                            type="reset"
                                                                            id="kt_modal_new_target_cancel"
                                                                            class="btn btn-light me-3"
                                                                            onClick={() => setShowModal(false)}
                                                                        >
                                                                            Close
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            id="kt_modal_new_target_submit"
                                                                            class="btn btn-primary">
                                                                            <span class="indicator-label">
                                                                                Submit
                                                                            </span>
                                                                            <span class="indicator-progress">
                                                                                Please wait...
                                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Formik>

                                            </ModalBox>
                                        ) : null}


                                        {showDeleteModal ?
                                            <ModalBox showModal={showDeleteModal}
                                                hideModal={() => setShowDeleteModal(false)}
                                                title="Delete Category Confirmation">
                                                <div
                                                    className="d-flex flex-column flex-root"
                                                    id="kt_app_root"
                                                >
                                                    <div className="w-md"></div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-12 col-form-label fw-semibold fs-6">
                                                            <span>Are you sure to delete the category? </span>
                                                        </label>
                                                    </div>
                                                    <div class="text-center">
                                                        <button
                                                            type="reset"
                                                            id="kt_modal_new_target_cancel"
                                                            class="btn btn-light me-3"
                                                            onClick={() => setShowDeleteModal(false)}>
                                                            No
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            id="kt_modal_new_target_submit"
                                                            onClick={handleDeleteCategory}
                                                            class="btn btn-danger">
                                                            <span class="indicator-label">
                                                                Yes
                                                            </span>
                                                            <span class="indicator-progress">
                                                                Please wait...
                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ModalBox> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryPage;